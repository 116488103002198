import { WalletAddress } from '@contracts/address';

export enum KYCStatuses {
  not_sent = 'not_sent', // User got the ref_id but didn't go through KYC
  incomplete = 'incomplete', // Blockpass verifications pending
  waiting = 'waiting', // Merchant's review pending
  inreview = 'inreview', // in review by Merchant
  approved = 'approved', // profile has been approved by Merchant
  resubmit = 'resubmit', // Merchant has rejected one or more attributes
}

export type KYCStatus = keyof typeof KYCStatuses

export interface KYCSuccessResponse {
  wallet_address: WalletAddress
  reference_id: string
  kyc_status: KYCStatus
  created_at: number
  updated_at: number
}
