export function isDefined(prop: any): boolean {
  return prop !== undefined
}

export function isEmptyObject(value: Object) {
  if (value === null) {
    return true
  }

  for (let key in value) {
    if (value.hasOwnProperty(key)) {
      return false
    }
  }
  return true
}
