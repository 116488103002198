import React, { useCallback, useState } from 'react';
import { defaultEnvironmentNetworkId, NetworkId } from '@contracts/networks';
import { RoundButton, RoundButtonProps } from '../RoundButton';
import { Spinner } from '../Spinner';
import { useNetwork } from '@hooks/useNetwork';

type Props = Pick<RoundButtonProps, 'color' | 'size' | 'wide' | 'className'> & {
  text?: React.ReactNode
  networkId: NetworkId
}

const AddNetworkButton = ({
  color,
  size,
  wide,
  className,
  text,
  networkId,
}: Props) => {
  const [loading, setLoading] = useState(false)
  const {
    switchNetwork
  } = useNetwork()

  const addNetwork = useCallback(async () => {
    if (!loading) {
      setLoading(true)
      await switchNetwork(networkId)
      setLoading(false)
    }
  }, [switchNetwork, loading])

  return (
    <RoundButton
      className={`add-network-button ${className}`}
      type="button"
      color={color}
      size={size}
      wide={wide}
      disabled={loading}
      loading={loading}
      onClick={addNetwork}
    >
      <span>{text ?? 'Switch Network'}</span>
    </RoundButton>
  )
}

AddNetworkButton.defaultProps = {
  color: 'LIGHT',
  wide: false,
  networkId: defaultEnvironmentNetworkId
}

export {
  AddNetworkButton
}
