import axios from 'axios';
import { sendEnvExceptionReport } from '@utils/errors';
import { GasPriceOptions } from './types';
import { mapGasPrice } from './mappers';
import { NetworksIDs, defaultEnvironmentNetworkId } from '@contracts/networks';

const oracleEndpoint = new URL(process.env.REACT_APP_POLYGON_GASSTATION || 'https://gasstation-mainnet.matic.network')
if (!oracleEndpoint && defaultEnvironmentNetworkId === NetworksIDs.matic) {
  sendEnvExceptionReport(new Error('REACT_APP_POLYGON_GASSTATION variable is not specified'))
}
const baseURL = oracleEndpoint.origin
const path = oracleEndpoint.pathname
const params = Object.fromEntries(oracleEndpoint.searchParams.entries())

const instance = axios.create({
  baseURL: baseURL
})

const fallbackInstance = axios.create({
  baseURL: 'https://gasstation-mainnet.matic.network'
})

export const getPolygonGasPrice = async (): Promise<GasPriceOptions> => {
  try {
    const { data } = await instance.get(path, {
      params
    })
    return mapGasPrice(data)
  } catch (err) {
    const { data } = await fallbackInstance.get('/')
    return mapGasPrice(data)
  }

}
