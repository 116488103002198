import { WalletAddress } from '@contracts/address';
import { KYCSuccessResponse } from './types';
import { instanceWithSignature as API } from '../config';
import { sendExceptionReport } from '@utils/errors';

export const geyKYCStatus = async (wallet_address: WalletAddress): Promise<KYCSuccessResponse | null> => {
  try {
    const { data } = await API.get('/get-kyc-status', {
      params: {
        wallet_address
      }
    })

    return (data as KYCSuccessResponse)
  } catch (err) {
    sendExceptionReport(err)
    return null
  }
}
