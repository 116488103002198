import { RoutesPaths } from '@router/constants'
import { infoEmail } from '@constants'

export interface FooterLink {
  title: string,
  href?: string,
  to?: string
}

export interface FooterSocial {
  icon: string,
  href: string,
}

export const footerLinks: Record<string, Array<FooterLink>> = {
  company: [
    //{
      //title: 'About Us',
      //to: RoutesPaths.ABOUT_US,
    //},
    {
      title: 'Join Our Network',
      to: RoutesPaths.JOIN_NETWORK,
    },
    //TODO: temporary hidden until Bug Bounty is implemented
    /*{
      title: 'Bug Bounty',
      href: '#',
    },*/
  ],
  developers: [
    {
      title: 'Documentation',
      href: '#'
    },
  ],
  governance: [
    {
      title: 'User Agreement',
      to: RoutesPaths.USER_AGREEMENT
    },
    {
      title: 'Privacy Policy',
      to: RoutesPaths.PRIVACY
    },
  ],
  help: [
    {
      title: 'Support',
      href: `mailto:${infoEmail}`
    },
    {
      title: 'FAQ',
      to: RoutesPaths.FAQ
    },
    {
      title: 'KYC Process',
      to: RoutesPaths.KYC_PROCESS
    }
  ]
}

export const footerSocials: FooterSocial[] = [
  {
    icon: 'twitter',
    href: 'https://twitter.com/Microwave_Dog'
  },
  {
    icon: 'telegram',
    href: 'https://t.me/MicrowaveDoge'
  }
]
