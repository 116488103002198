import React, { useCallback, useEffect, useState } from 'react';
import { RoutesPaths } from '@router/constants';
import { Link, useRouteMatch } from 'react-router-dom';
import logo from '@assets/logo.svg';
import { Container, Dropdown, Nav, Navbar } from 'react-bootstrap';
import { Copiable, CustomLink, IconButton, NetworkSwitcher, RoundButton } from '@components/common';
import { useWeb3React } from '@web3-react/core';
import { shorterETHAddress } from '@utils/string';
import { MetaMask } from '@contracts/connectors';
import { WalletAddress } from '@contracts/address';
import './Header.scss';
import { useNetwork } from '@hooks/useNetwork';
import { useSearchParams } from '@hooks/useSearchParams';
import { ReactComponent as Refresh } from '@assets/dex.svg';
import { ReactComponent as Dots } from '@assets/products.svg';
import { ReactComponent as Cards } from '@assets/nft-launchpad.svg';
import { ReactComponent as LinkArrow } from '@assets/link-arrow.svg';
import { ReactComponent as Launch } from '@assets/launch.svg';
import { buyMWAVELink, DEXAppUrl, NFTAppUrl } from '@constants'
import { LaunchModal } from '@components/formfills/LaunchModal'

interface Props {
}

const switchNetworkQueryParameter = 'switch_network_to'

export const Header = (props: Props) => {
  const { account, activate, active } = useWeb3React();
  const newLockingPath = useRouteMatch(RoutesPaths.ACCOUNT.LOCKING_MULTIPERIOD)
  const stakingPath = useRouteMatch(RoutesPaths.ACCOUNT.STAKING)
  const portfolioPath = useRouteMatch(RoutesPaths.ACCOUNT.MAIN)
  const projectsPath = useRouteMatch(RoutesPaths.PROJECTS);
  const [showLaunchModal, setShowLaunchModal] = useState(false)

  const onClickWallet = () => {
    if (!active) {
      activate(MetaMask);
    }
  };

  const { switchNetwork, checkIfSelected, currentNetworkId } = useNetwork()
  const { query, removeQuery } = useSearchParams()

  const switchNetworkAfterRedirect = useCallback(async () => {
    if (!(switchNetworkQueryParameter in query)) {
      return
    }
    const networkToSwitch = query[switchNetworkQueryParameter]
    if (!checkIfSelected(networkToSwitch)) {
      await switchNetwork(networkToSwitch)
    }
    removeQuery(switchNetworkQueryParameter)
  }, [query, switchNetwork, checkIfSelected, currentNetworkId])

  useEffect(() => {
    if (switchNetworkQueryParameter in query && currentNetworkId) {
      switchNetworkAfterRedirect()
    }
  }, [query, currentNetworkId])

  return (
    <Container as="header" className="header" fluid>
      <Navbar>
        <Navbar.Brand>
          <Link to={RoutesPaths.MAIN}>
            <img
              src={logo}
              alt="Logo"
              className="d-inline-block align-top"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Collapse className="center-links">
          <Nav.Link
            as={Link}
            active={projectsPath?.isExact}
            to={RoutesPaths.PROJECTS}
          >
            Projects
          </Nav.Link>
          {/*TODO: temporary hidden until MWAVE deployed*/}
          {/*<Nav.Link
            href={buyMWAVELink}
          >
            Buy MWAVE
            <LinkArrow />
          </Nav.Link>*/}
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end account">
          <IconButton
            rounded
            className="header__nav-button dark small"
            onClick={() => setShowLaunchModal(true)}>
            <Launch />
          </IconButton>
          <LaunchModal
            show={showLaunchModal}
            setShow={setShowLaunchModal}
          />
          {(!DEXAppUrl && !NFTAppUrl)
            ? null
            : (
              <Dropdown className="nav-dropdown products">
                <Dropdown.Toggle
                  as={IconButton}
                  rounded
                  className="header__nav-button dark small"
                >
                  <Dots/>
                </Dropdown.Toggle>
                <Dropdown.Menu className="nav-dropdown__menu">
                  <Dropdown.ItemText>
                    Our Apps
                  </Dropdown.ItemText>
                  {NFTAppUrl && (
                    <Dropdown.Item
                      as={CustomLink}
                      href={NFTAppUrl}
                      className="nav-dropdown__item"
                    >
                      <Cards/>
                      <div className="product-description">
                        <div className="text-info">
                          <span className='title'>NFT Launchpad</span>
                          <span className='description'>Own NFTs from the world's best artists</span>
                        </div>
                        <div className="arrow-container">
                          <LinkArrow className='link-arrow'/>
                        </div>
                      </div>
                    </Dropdown.Item>
                  )}
                  {DEXAppUrl && (
                    <Dropdown.Item
                      as={CustomLink}
                      href={DEXAppUrl}
                      className="nav-dropdown__item"
                    >
                      <Refresh/>
                      <div className="product-description">
                        <div className="text-info">
                          <span className='title'>DEX</span>
                          <span className='description'> Swap and Earn</span>
                        </div>
                        <div className="arrow-container">
                          <LinkArrow className='link-arrow'/>
                        </div>
                      </div>
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            )}
          <Dropdown className="nav-dropdown">
            <Dropdown.Toggle
              as={RoundButton}
              className="header__nav-button dark small"
            >
              Account
            </Dropdown.Toggle>
            <Dropdown.Menu className="nav-dropdown__menu">
              {/*TODO: temporary hidden until MWAVE deployed*/}
              {/*<Dropdown.Item
                as={Link}
                active={newLockingPath?.isExact}
                to={RoutesPaths.ACCOUNT.LOCKING_MULTIPERIOD}
                className="nav-dropdown__item"
              >
                LOCKUP
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                active={stakingPath?.isExact}
                to={RoutesPaths.ACCOUNT.STAKING}
                className="nav-dropdown__item"
              >
                STAKING
              </Dropdown.Item>*/}
              <Dropdown.Item
                as={Link}
                active={portfolioPath?.isExact}
                to={RoutesPaths.ACCOUNT.MAIN}
                className="nav-dropdown__item"
              >
                MY PORTFOLIO
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <NetworkSwitcher>
            <RoundButton className="header__connect-wallet" size='small' color="DARK" onClick={onClickWallet}>
              {active
                ? <Copiable text={account!}>{shorterETHAddress(account as WalletAddress)}</Copiable>
                : <span>CONNECT</span>
              }
            </RoundButton>
          </NetworkSwitcher>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
};
