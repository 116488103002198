import pdfMake from 'pdfmake/build/pdfmake'
// @ts-ignore
import * as SaftFonts from '../components/Project/SAFTModal/contents/saft_fonts';
import { CustomTableLayout, TDocumentDefinitions, TFontDictionary } from 'pdfmake/interfaces';

pdfMake.vfs = SaftFonts.pdfMake.vfs

interface generatePdfArguments {
  docDefinition: TDocumentDefinitions,
  tableLayout?: { [name: string]: CustomTableLayout }
  fonts?: TFontDictionary
}

export const generatePdfAndOpen = async ({
  docDefinition,
  tableLayout,
  fonts
}: generatePdfArguments) => {
  await pdfMake.createPdf(docDefinition, tableLayout, fonts).open()
}
