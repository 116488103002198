import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

export const useSearchParams = () => {
  const { search, pathname } = useLocation()
  const history = useHistory()

  const query = useMemo(() => new URLSearchParams(search), [search])

  const replaceQuery = useCallback((params: Record<string, string>) => {
    const queryString = new URLSearchParams(params).toString()
    history.push({
      pathname,
      search: queryString
    })
  }, [query, pathname, history])

  const appendQuery = useCallback((params: Record<string, string>) => {
    const queryParams = new URLSearchParams(query)
    for (let [key, value] of Object.entries(params)) {
      queryParams.append(key, value)
    }
    history.push({
      pathname,
      search: queryParams.toString()
    })
  }, [query, pathname, history])

  const removeQuery = useCallback((...args: string[]) => {
    const queryParams = new URLSearchParams(query)
    for (let key of args) {
      queryParams.delete(key)
    }
    history.replace({
      pathname,
      search: queryParams.toString()
    })
  }, [query, pathname, history])

  return {
    query: useMemo(() => Object.fromEntries(query.entries()), [query]),
    appendQuery,
    replaceQuery,
    removeQuery
  }
}
