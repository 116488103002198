import { Container } from 'react-bootstrap'
import { TiersTable } from '@components'
import React, { memo } from 'react'
import './TiersSection.scss'

export const TiersSection = memo(() =>
  <section className="tiers-section">
    <Container>
      <h2 className="title text-center">Microwave Tiers & Access to IDOs</h2>
      <TiersTable />
    </Container>
  </section>
);
