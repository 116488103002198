import { useWeb3React } from '@web3-react/core';
import { useCallback, useEffect, useState } from 'react';
import { useTokenContract } from './useContracts';
import { ContractAddress, tokenAddresses } from '../address';
import { maxUint256String } from '@utils/web3';
import { NotifyTxCallbacks } from '../notify';
import BigNumber from 'bignumber.js';
import { useTransactions } from './useTransactions';
import { useIsMounted } from '@hooks/useIsMounted';
import { BlockNumber, TransactionReceipt } from 'web3-core';
import { useNetwork } from '@hooks/useNetwork';

export const useApproval = (tokenAddress?: ContractAddress, lockingContractAddress?: ContractAddress) => {
  const isMountedRef = useIsMounted()
  const { account } = useWeb3React();
  const tokenContract = useTokenContract(tokenAddress);
  const [allowance, setAllowance] = useState<BigNumber>(new BigNumber(0));
  const {
    callTransaction,
    sendTransaction
  } = useTransactions()

  const fetchAllowance = useCallback(async (blockNumber?: BlockNumber) => {
    if (!account || !tokenContract || !lockingContractAddress) {
      setAllowance(new BigNumber(0))
      return
    }

    const res = await callTransaction(
      tokenContract.methods.allowance(account, lockingContractAddress),
      blockNumber
    )

    isMountedRef.current && setAllowance(new BigNumber(res))
  }, [account, tokenContract, lockingContractAddress, isMountedRef])

  useEffect(() => {
    fetchAllowance()
  }, [account, tokenContract, lockingContractAddress])

  const handleApprove = useCallback(async (
    amount: string = maxUint256String,
    callbacks: NotifyTxCallbacks = {}
  ) => {
    const receipt = await sendTransaction(
      tokenContract?.methods.approve(
        lockingContractAddress,
        amount
      ),
      callbacks
    ) as TransactionReceipt

    await fetchAllowance(receipt.blockNumber)
  }, [tokenContract, lockingContractAddress, sendTransaction])

  return {
    allowance,
    onApprove: handleApprove,
  }
}

export const useApprovalForMWAVE = () => {
  const { isDefaultNetworkSelected } = useNetwork()

  return useApproval(
    ...(isDefaultNetworkSelected ? [
      tokenAddresses.MWAVEToken,
      tokenAddresses.MWAVELocking
    ] : [])
  );
}
