import { useWeb3React } from '@web3-react/core';
import { useCallback, useMemo } from 'react';
import {
  defaultEnvironmentNetworkData,
  defaultEnvironmentNetworkId,
  NetworkId,
  NetworkInfo, networks,
  // NetworksIDs,
  supportedNetworks
} from '@contracts/networks';
import { useWeb3 } from './useWeb3';
import { sendExceptionReport } from '@utils/errors';
import { NoEthereumProviderError } from '@web3-react/injected-connector';
// import { getSolanaNetworkDomainUrl } from '@utils/urls';

export interface IUseNetwork {
  currentNetworkId?: NetworkId
  defaultNetwork: NetworkInfo
  currentNetwork?: NetworkInfo
  isDefaultNetworkSelected: boolean // returns true if current network matches to defaultEnvironmentNetwork
  checkIfSelected: (id: NetworkId) => boolean // returns true if the asking network matches to the current one
  getNetwork: (id: NetworkId) => NetworkInfo
  switchNetwork: (id: NetworkId) => Promise<void>
}

export class CustomNetworkError extends Error {
  constructor(message: string) {
    super(message);
  }
}

// const isSolanaNetwork = (id: NetworkId): boolean =>
//   ([NetworksIDs.solana, NetworksIDs.solanadev] as NetworkId[]).includes(id)

export const useNetwork = (): IUseNetwork => {
  const web3 = useWeb3()
  const { chainId, setError } = useWeb3React()
  const currentNetworkId = useMemo(
    () => chainId ? chainId.toString() : undefined,
    [chainId]
  )

  const currentNetwork = useMemo(() => currentNetworkId ? supportedNetworks[currentNetworkId] : undefined, [currentNetworkId])
  const defaultNetwork = useMemo(() => defaultEnvironmentNetworkData, [])
  const isDefaultNetworkSelected = useMemo(() => currentNetworkId === defaultEnvironmentNetworkId, [currentNetworkId])
  const isSelected: Record<NetworkId, boolean> = useMemo(() => Object.keys(supportedNetworks).reduce(
    (acc, id) => {
      acc[id] = id === currentNetworkId
      return acc
    },
    {} as Record<NetworkId, boolean>
  ), [currentNetworkId])

  const getNetwork = (id: NetworkId): NetworkInfo => networks[id]
  const checkIfSelected = useCallback((id: NetworkId) => isSelected[id], [isSelected])

  const switchNetwork = useCallback(async (id: NetworkId ): Promise<void> => {
    // if (isSolanaNetwork(id)) {
    //   const path = window.location.pathname
    //   const query = window.location.search
    //   window.location.href = getSolanaNetworkDomainUrl(path + '?' + query)
    //   return
    // }

    if (!web3.givenProvider) {
      return setError(new NoEthereumProviderError())
    }

    if (web3.givenProvider.networkVersion === id) {
      return setError(new CustomNetworkError(`You're already using this network`))
    }

    const {
      name: chainName,
      networkId,
      nativeCurrency,
      rpcUrl,
      explorerUrl
    } = getNetwork(id)

    const chainIdHex = web3.utils.numberToHex(networkId)

    try {
      await web3.givenProvider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainIdHex }]
      })
    } catch (err) {
      switch (err.code) {
        case 4902:
          await web3.givenProvider.request({
            method: 'wallet_addEthereumChain',
            params: [{
              chainId: chainIdHex,
              chainName,
              nativeCurrency,
              rpcUrls: [rpcUrl],
              blockExplorerUrls: [explorerUrl]
            }]
          });
          break;
        case 4001:
          return;
        default:
          sendExceptionReport(err)
          return;
      }
    }
  }, [web3])

  return {
    currentNetworkId,
    defaultNetwork,
    currentNetwork,
    isDefaultNetworkSelected,
    checkIfSelected,
    getNetwork,
    switchNetwork
  }
}
