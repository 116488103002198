import { Col, Row } from 'react-bootstrap'
import { GetReadyBadge } from '@/components'
import React, { memo } from 'react'
import './GetReadySection.scss'

export const GetReadySection = memo(() =>
  <section className="get-ready-section">
    <Row className="mt-auto">
      <Col>
        <GetReadyBadge />
      </Col>
    </Row>
  </section>
)
