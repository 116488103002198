import React from 'react';
import { SEOTags, StaticPageWrapper } from '@components';
import { Container } from 'react-bootstrap';
import { seoTags } from '@/seo-content';

export const Privacy = () =>
  <StaticPageWrapper title="Privacy Policy">
    <SEOTags {...seoTags.privacy} />
    <section className="marketing-page__text">
      <Container>
        <br></br>
        <p>This Privacy Policy describes how <strong>Microwave</strong> will manage end
          users personal information which we collect as a result of your use of Microwave, your use of our websites
          located at https://Microwave.dog and any pages or websites under the "Microwave" brand including any tablet
          or mobile phone applications that are owned and/or operated by us or whenever we otherwise deal with you.
          Microwave is committed to ensuring that the personal information we collect about you is protected and is
          used, stored and disclosed in accordance with the Privacy Act and this Privacy Policy.</p>
        <section>
          <h2 className="title">Website Use</h2>
          <p>In using the website, you agree to be bound by the terms of this Privacy
            Policy. Microwave.dog may review the Privacy Policy periodically and reserves the right to change the
            Privacy Policy at any time at its discretion by posting such changes on the website. Changes will be
            effective immediately when posted on the website. The end users continued use of the website following
            posting will constitute your acceptance of those changes.</p>
          <p>This Privacy Policy will not apply to websites which are linked to the website
            and over which Microwave.dog has no control.</p>
        </section>
        <section>
          <h2 className="title">Personal Information</h2>
          <p>The kinds of Personal Information that Microwave.dog may collect from you
            is:</p>
          <ul>
            <li>Name</li>
            <li>Email address</li>
            <li>ETH wallet address</li>
            <li>SOL wallet address</li>
            <li>Personally submitted preferences</li>
            <li>Location data</li>
            <li>IP address</li>
          </ul>
        </section>
        <section>
          <h2 className="title">Collection Purposes</h2>
          <p>Microwave.dog will collect your Personal Information only by lawful and fair
            means and not in an intrusive way to operate its Service, and to provide you with the following
            information:</p>
          <ul>
            <li>If you wish to subscribe to Microwave.dog&acute;s weekly newsletter or other marketing communications.
            </li>
            <li>To update you on the technical progress of Microwave.</li>
            <li>To provide services to you as a User.</li>
            <li>To identify you as a User for security purposes and to comply with our legal obligations.</li>
            <li>To maintain your User account.</li>
            <li>To upgrade and enhance your experience within the website or over the telephone, or to tailor or develop
              information, services or products to suit your needs which may include market research and conducting
              promotions.
            </li>
            <li>To tell you about our products or services that we think may be of interest to you by communicating with
              you via email.
            </li>
            <li>To create aggregate data about Users through demographic profiling, statistical analysis of the database
              to provide to potential and existing Microwave holders, and to allow for more efficient operation of
              Microwave.dog&rsquo;s business.
            </li>
            <li>To comply with Microwave.dog&rsquo;s legal and statutory obligations.</li>
            <li>For taking appropriate action if Microwave.dog has reason to suspect that unlawful activity or
              misconduct of a serious nature has been, is being or may be engaged in that relates to our functions and
              activities.
            </li>
            <li>To establish, exercise or defend any legal claims.</li>
            <li>Manage job applications.</li>
          </ul>
          <p>You may choose to deal with us on an anonymous basis or to use a pseudonym
            unless it is not practicable for us to deal with individuals who we cannot identify or we are required by
            law to only deal with identified individuals. Also, if you do not provide Microwave.dog with the Personal
            Information we request, we may be unable to process your request to become a User, provide you with our
            services or respond to your enquiry or complaint. Examples of Personal Information that may be collected by
            Microwave.dog include your name, email address, telephone numbers, your date of birth, other verification
            information such as your Service usage activities. By becoming a User or otherwise choosing to provide
            Microwave.dog with Personal Information you consent to Microwave.dog collecting, using and disclosing your
            Personal Information for the above purposes.</p>
          <p>We use Google Analytics in order to better understand our users &acute;needs
            and to optimize this service and experience. Google Analytics is a technology service that helps us better
            understand our users&acute; experience (eg how much time they spend on which pages, which links they choose
            to click, what users do and dont like, etc.) and this enables us to build and maintain our service with user
            feedback. Google Analytics uses cookies and other technologies to collect data on our users behavior and
            their devices. This includes a devices IP address (processed during your session and stored in a
            de-identified form), device screen size, device type (unique device identifiers), browser information,
            geographic location (country only), and the preferred language used to display our website.</p>
        </section>
        <section>
          <h2 className="title">KYC/AML verification</h2>
          <p>We use third-party KYC/AML verification service Blockpass that collects your
            personal information for the purpose of verification of your identity. Please, read the Blockpass Privacy
            Policy following the link</p>
          <p><a href="https://www.blockpass.org/privacy" target="_blank"
                rel="noopener">https://www.blockpass.org/privacy</a></p>
        </section>
        <section>
          <h2 className="title">Direct Marketing and Opting Out</h2>
          <p>Occasionally we may use your Personal Information to inform you about
            Microwave.dog&acute;s products or services or about promotional activities which Microwave.dog believes may
            be of interest or of benefit to you. We may do this via email, SMS, telephone or mail. If you no longer wish
            to receive marketing or promotional material from Microwave.dog at all or in any particular form, you may
            contact us at any time by email to info@microwave.dog with your request with which we will comply as soon as
            is practical.</p>
          <p>From time to time we may contact you in relation to the management and
            administration of your Microwave.dog account. These communications can be via any of the modes of contact
            recorded when registering as a User. Such communication does not affect your opt-in or opt-out status for
            direct marketing communications.</p>
        </section>
        <section>
          <h2 className="title">Management of your Personal Information</h2>
          <p>Microwave.dog will take all reasonable steps to ensure that the Personal
            Information which it collects, uses or discloses is correct and is stored in a secure environment which is
            accessed only by authorised persons.</p>
          <p>Microwave.dog will destroy or permanently de-identify the Personal Information
            we hold when it is no longer required for any purpose permitted under the APPs including our legal or
            operational obligations.</p>
        </section>
        <section>
          <h2 className="title">Security of Personal Information</h2>
          <p>You acknowledge that no data transmission over the Internet is totally secure.
            Accordingly, Microwave.dog does not warrant the security of any information which you transmit to it. Any
            information which you transmit to Microwave.dog is transmitted at your own risk. However, once Microwave.dog
            receives your transmission, Microwave.dog will take reasonable steps to protect your Personal Information
            from misuse, loss and unauthorised access, modification and disclosure including by using password protected
            systems and databases and Secure Socket Layer (SSL) technology.</p>
          <p>Microwave.dog&rsquo;s employees, agents and contractors are required to
            maintain the confidentiality of Users&rsquo; Personal Information and trading behaviour.</p>
          <p>Information posted on bulletin boards or communicated within a social media
            environment (for example, Facebook, Twitter, Chat Rooms) becomes public information. Microwave.dog cannot
            guarantee the security of this type of disclosed information.</p>
          <p>We take seriously the responsibility to exclude children from access to our
            services. We will not accept their information for the purposes of accessing or using the Service. It is
            however, ultimately the responsibility of parents or guardians to monitor their children&rsquo;s Internet
            activities including where appropriate by using Internet screening software.</p>
          <p>Remember to always log out of your account when you have completed your time
            on the website. This is particularly important if you share a computer with another person. You are
            responsible for the security of and access to your own computer, mobile device or any other handset used to
            access the website.</p>
          <p>Ultimately, you are solely responsible for maintaining the secrecy of your
            username, password and any account information. Please be careful whenever using the Internet and our
            website.</p>
        </section>
        <section>
          <h2 className="title">Access to Personal Information</h2>
          <p>You may access the Personal Information collected by Microwave.dog by
            contacting us at info@microwave.dog. We will give you access to your Personal Information in the manner
            requested if that is possible and within a reasonable period. If we refuse your request or cannot give you
            access in the manner you have requested, we will do what we can to meet your requirements by other means. We
            may not be required to give you access to your Personal Information in certain circumstances which are set
            out in the APPs including where it may have an unreasonable impact on other individual&acute;s privacy. If
            we refuse access for such reasons, we will advise you in writing of the refusal and our reasons and the
            complaint mechanisms available to you.</p>
        </section>
        <section>
          <h2 className="title">Use of Website</h2>
          <p>By using this website, you agree to Microwave.dog collecting and processing
            your Personal Information on the basis explained in this Privacy Policy.</p>
        </section>
        <section>
          <h2 className="title">Contact Details</h2>
          <p>If you have any queries, requests for access or correction or complaints
            relating to the handling of your personal information, please contact us by email.</p>
          <p>info@microwave.dog</p>
        </section>
        <section>
          <h2 className="title">Updates to this Privacy Policy</h2>
          <p>Microwave.dog may review, change and update this Privacy Policy to reflect our
            current practices and obligations. We will publish our current Privacy Policy on our website at
            www.Microwave.dog and the changes will take effect at the time of publishing. You should review this privacy
            policy regularly and remain familiar with its terms.</p>
          <p>Last updated: October 1, 2022</p>
        </section>
      </Container>
    </section>
  </StaticPageWrapper>

