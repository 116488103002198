import { NormalizedProjectInfo } from '@components/Project/types';
import { KYCStatus } from '@api/kyc/types';
import { GasPriceOptions } from '@api/gas-stations/types';
import { IgnitionStatuses, NFTMetadata } from '@contracts/hooks/useNFTs';
import { PlatformSettings } from '@hooks/usePlatformSettings';

export enum ActionType {
  SET_LOADING,
  SET_PLATFORM_SETTINGS,
  SET_PROJECTS,
  SET_WHITElLISTED_PROJECTS,
  SET_PROJECT,
  SET_KYC_DATA,
  SET_GAS_PRICE,
  SET_FETCHING_NFT_COLLECTION,
  SET_FETCHING_NFT_TOKEN,
  SET_FLARE_DATA,
  SET_HIRO_DATA,
  SET_USER_NFTS,
  SET_CURRENT_NFT,
  SET_IGNITION_STATUS,
}

export interface SetLoading {
  type: ActionType.SET_LOADING
  payload?: boolean
}

export interface SetPlatformSettings {
  type: ActionType.SET_PLATFORM_SETTINGS,
  payload: PlatformSettings
}

export interface SetProjects {
  type: ActionType.SET_PROJECTS
  payload: NormalizedProjectInfo[]
}

export interface SetWhitelistedProjects {
  type: ActionType.SET_WHITElLISTED_PROJECTS,
  payload: NormalizedProjectInfo[]
}

export interface SetProject {
  type: ActionType.SET_PROJECT
  payload: NormalizedProjectInfo | null
}

export interface SetKYCData {
  type: ActionType.SET_KYC_DATA
  payload: {
    status: KYCStatus | null
    referenceId: string | null
  }
}

export interface SetGasPrice {
  type: ActionType.SET_GAS_PRICE
  payload: GasPriceOptions
}

export interface SetFetchingNFTCollection {
  type: ActionType.SET_FETCHING_NFT_COLLECTION
  payload: boolean
}

export interface SetFetchingNFTToken {
  type: ActionType.SET_FETCHING_NFT_TOKEN
  payload: boolean
}

export interface SetFlareData {
  type: ActionType.SET_FLARE_DATA
  payload: {
    metadata: NFTMetadata | null,
    total: number
  } | null
}

export interface SetHiroData {
  type: ActionType.SET_HIRO_DATA
  payload: {
    metadata: NFTMetadata | null,
    total: number
  } | null
}

export interface SetUserNFTs {
  type: ActionType.SET_USER_NFTS,
  payload: NFTMetadata[] | null
}

export interface SetCurrentNFT {
  type: ActionType.SET_CURRENT_NFT,
  payload: {
    metadata: NFTMetadata | null,
    total: number
    ignitionStatus?: IgnitionStatuses
  } | null
}

export interface SetIgnitionStatus {
  type: ActionType.SET_IGNITION_STATUS
  payload: IgnitionStatuses
}

export type StateAction =
  SetLoading |
  SetPlatformSettings |
  SetProjects |
  SetWhitelistedProjects |
  SetProject |
  SetKYCData |
  SetGasPrice |
  SetFetchingNFTCollection |
  SetFetchingNFTToken |
  SetFlareData |
  SetHiroData |
  SetUserNFTs |
  SetCurrentNFT |
  SetIgnitionStatus

export interface KYCData {
  status: KYCStatus | null | undefined
  referenceId: string | null
}

export interface StateGasPrice extends GasPriceOptions {}

export interface StateNFTContract {
  metadata: NFTMetadata | null
  total: number
  ignitionStatus?: IgnitionStatuses
}

export interface StateNFT {
  fetchingCollection: boolean
  fetchingCurrentToken: boolean
  flare: StateNFTContract | null
  hiro: StateNFTContract | null
  userNFTTokens: NFTMetadata[] | null
  currentNFTToken: StateNFTContract | null
}

export type StateLoading = boolean
export type StateProjects = NormalizedProjectInfo[] | null
export type StateCurrentProject = NormalizedProjectInfo | null
export type StatePlatformSettings = PlatformSettings | null

export interface AppState {
  loading: StateLoading
  settings: StatePlatformSettings
  projects: StateProjects
  whitelistedProjects: StateProjects
  currentProject: StateCurrentProject
  KYC: KYCData
  gasPrices: StateGasPrice
  NFT: StateNFT
}
