import { instanceWithSignature as API } from '../config';
import {
  NormalizedProjectInfo,
  ProjectResponseInfo
} from '@components/Project/types';
import { ContractAddress, WalletAddress } from '@contracts/address';
import { sendExceptionReport } from '@utils/errors';
import { normalizeProject } from './mapping';
import { NetworkId } from '@contracts/networks';
import axios, { CancelToken } from 'axios';

export interface ProjectFilters {
  chainId?: NetworkId
}

export const _getProjects = async (
  cancelToken?: CancelToken,
  {
    chainId
  }: ProjectFilters = {}): Promise<NormalizedProjectInfo[]> => {
  try {
    const { data } = await API.get('/get-projects', {
      params: {
        chainId
      },
      cancelToken
    })
    return data.map((project: ProjectResponseInfo) => normalizeProject(project))
  } catch (err) {
    if (err instanceof axios.Cancel) {
      throw err
    }
    sendExceptionReport(err)
    return []
  }
}

export const _getProjectById = async (id: string): Promise<NormalizedProjectInfo | null> => {
  try {
    const { data } = await API.get('/get-project-by-id', {
      params: {
        project_id: id
      }
    })

    return normalizeProject(data)
  } catch (err) {
    sendExceptionReport(err)
    return null
  }
}

export const getMultipleVestingForWallet = async (wallet_address: WalletAddress): Promise<ContractAddress[] | null> => {
  try {
    const { data: contracts } = await API.get('/get-multiple-vestings', {
      params: {
        wallet_address
      }
    })

    return contracts
  } catch (err) {
    sendExceptionReport(err)
    return null
  }
}

export const _getWhitelistedProjects = async (
  wallet_address: WalletAddress,
  cancelToken?: CancelToken,
  {
    chainId
  }: ProjectFilters = {}
): Promise<NormalizedProjectInfo[]> => {
  try {
    const { data } = await API.get('/get-whitelisted-projects', {
      params: {
        wallet_address,
        chainId
      },
      cancelToken
    })

    return data.map((project: ProjectResponseInfo) => normalizeProject(project))
  } catch (err) {
    if (err instanceof axios.Cancel) {
      throw err
    }
    sendExceptionReport(err)
    return []
  }
}
