import { PlatformSettingsResponse } from './types';
import { instance as API } from '../config';
import { sendExceptionReport } from '@utils/errors';
import { MWaveProjectId } from '@constants';

export const getPlatformSettings = async (): Promise<PlatformSettingsResponse> => {
  try {
    const { data } = await API.get<PlatformSettingsResponse>('/get-platform-settings')

    return data
  } catch (err) {
    sendExceptionReport(err)
    return {
      featured_project_id: MWaveProjectId as string,
      flame_tiers_factor: 1
    }
  }
}
