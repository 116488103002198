import { JoinFormValues } from '@components/formfills';
import { FormFillResponse, SubscriptionResponse } from './types';
import { sendExceptionReport } from '@utils/errors';
import { sharedApiInstance } from '@api/config'


export const submitToJoinCommunity = async (values: JoinFormValues): Promise<FormFillResponse> => {
  try {
    const response = await sharedApiInstance.post<SubscriptionResponse>(
      '/subscribe',
      values
    )

    const data = response.data

    if (data.error) {
      sendExceptionReport(data.error)
      return {
        result: 'error',
        error: data.error
      }
    }

    if (!data.status) {
      return {
        result: 'error',
        error: 'Something went wrong'
      }
    }
    return {
      result: 'success'
    }
  } catch (err) {
    sendExceptionReport(err)
    return {
      result: 'error',
      error: err
    }
  }
}

