import React from 'react'
import classNames from 'classnames'
import './NoProjectsTile.scss'

export const NoProjectsTile: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
  const maskId = `${ Math.trunc(Math.random() * 100) }-dots-mask`
  return (
    <div {...props} className={classNames('tile no-projects-tile', props.className)}>
      <div className="dots" style={{ clipPath: `url(#${maskId})` }}>
        <svg viewBox="0 0 72 16" version="1.1" width="16"
             xmlns="http://www.w3.org/2000/svg">
          <clipPath id={maskId}>
            <circle cx="8" cy="8" r="8" fill="#FFFFFF"/>
            <circle cx="36" cy="8" r="8" fill="#FFFFFF"/>
            <circle cx="64" cy="8" r="8" fill="#FFFFFF"/>
          </clipPath>
        </svg>
      </div>
      <p className="tile__description">
        {children}
      </p>
    </div>
  )
}
