import React from 'react';
import { ProjectStatusProp, ProjectType, SocialLink, TokenName } from '../types';
import './ProjectDescription.scss';
import { Image } from 'react-bootstrap';
import { ProjectTag } from '../ProjectTag';
import { NetworkName } from '@contracts/networks';
import { ReactComponent as DiscordIcon } from '@assets/socials/discord.svg'
import { ReactComponent as InstagramIcon } from '@assets/socials/instagram.svg'
import { ReactComponent as MediumIcon } from '@assets/socials/medium.svg'
import { ReactComponent as TelegramIcon } from '@assets/socials/telegram.svg'
import { ReactComponent as TwitterIcon } from '@assets/socials/twitter.svg'
import { ReactComponent as WebIcon } from '@assets/socials/web.svg'
import { IconButton } from '@/components'

DiscordIcon.displayName = 'DiscordIcon'
InstagramIcon.displayName = 'InstagramIcon'
MediumIcon.displayName = 'MediumIcon'
TelegramIcon.displayName = 'TelegramIcon'
TwitterIcon.displayName = 'TwitterIcon'
WebIcon.displayName = 'WebIcon'

const socialIcons = {
  discord: DiscordIcon,
  instagram: InstagramIcon,
  medium: MediumIcon,
  telegram: TelegramIcon,
  twitter: TwitterIcon,
  web: WebIcon,
}

export interface ProjectDescriptionProps {
  logo: string
  name: string
  subtitle?: string
  status: ProjectStatusProp
  network: NetworkName
  fundTokenName: TokenName
  description: string
  socialLinks: SocialLink[]
  projectType: ProjectType
}

interface Props extends ProjectDescriptionProps {
  onDownloadTerms?: () => void
}

export const ProjectDescription: React.FC<Props> = React.memo(({
  logo,
  name,
  subtitle,
  status,
  network,
  description,
  fundTokenName,
  onDownloadTerms,
  socialLinks,
  projectType,
}) => {
  return (
    <div className='project-info'>
      <header className='project-info__head'>
        <Image src={logo} roundedCircle className='project-info__logo' />
        <div>
          <div className='project-info__tags'>
            <ProjectTag type="status" value={status} />
            <ProjectTag type="network" value={network} />
            <ProjectTag type="token" projectType={projectType} value={fundTokenName} />
          </div>
          <h3 className='project-info__name'>{name}</h3>
          {!!subtitle && (
            <p className='project-info__subtitle'>
              {subtitle}
            </p>
          )}
        </div>
      </header>
      <div className='project-info__description' dangerouslySetInnerHTML={{ __html: description }} />
      {
        !!onDownloadTerms && (
          <a
            href='javascript:'
            className='project-info__download-terms'
            onClick={onDownloadTerms}
          >
            Download terms & conditions
          </a>
        )
      }
      {
        !!socialLinks.length && (
          <div className='project-info__links'>
            <h6 className="project-info__links-title main-grey-text">
              Project's Social
            </h6>
            <div className="project-info__links-list">
              {socialLinks.map(link =>
                <IconButton
                  key={link.name}
                  className="project-info__link"
                  href={link.url}
                  color="DARK"
                >
                  {
                    React.createElement(socialIcons[link.name], {})
                  }
                </IconButton>
              )}
            </div>
          </div>
        )
      }
    </div>
  );
});
