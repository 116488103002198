import React from 'react';
import { SEOTags, StaticPageWrapper } from '@components';
import { Container } from 'react-bootstrap';
import { seoTags } from '@/seo-content';

export const UserAgreement = () =>
  <StaticPageWrapper title='User Agreement'>
    <SEOTags {...seoTags.userAgreement} />
    <section className="marketing-page__text">
      <Container>
        <br></br>
        <p>THIS MICROWAVE USER AGREEMENT DEFINES TERMS UNDER WHICH MICROWAVE PROVIDES ITS SERVICES TO USERS, AND
          MICROWAVE KYC/AML STATEMENT (“THIS AGREEMENT” OR "TERMS"), EFFECTIVE UPON THE DATE OF ITS PUBLICATION ON THE
          MICROWAVE WEBSITE. THIS AGREEMENT SETS OUT THE TERMS AND CONDITIONS OF YOUR USE OF THE MICROWAVE PLATFORM
          (THE “PLATFORM” OR “MICROWAVE PLATFORM”) AS WELL AS YOUR ACCESS AND USE OF ANY OTHER MICROWAVE SERVICES
          PROVIDED THROUGH THE MICROWAVE PLATFORM (THE “SERVICES'' OR “MICROWAVE SERVICES” ), WHICH PROVIDE
          DISTRIBUTED LEDGER TECHNOLOGY PROJECTS (“PROJECT” OR "CLIENT") AND WITH OTHER SERVICES FOR THEIR RESPECTIVE
          COMMUNITIES, WHEREBY YOU AS A USER (“YOU”, “USER” AND COLLECTIVELY “USERS”) MAY CHOOSE (AT YOUR OWN RISK) TO
          ACTIVELY CONTRIBUTE TOWARDS A SPECIFIC PROJECT OR USE OTHER SERVICES.</p>
        <p>THESE TERMS CONSTITUTE A LEGAL AGREEMENT AND CREATE A BINDING CONTRACT BETWEEN YOU AND MICROWAVE. YOU
          SHOULD TAKE TIME TO READ THESE TERMS CAREFULLY AND THOROUGHLY.</p>
        <p>TO BE ELIGIBLE TO USE THE MICROWAVE PLATFORM AND SERVICES, YOU MUST BE AT LEAST 18 YEARS OLD (OR THE
          APPLICABLE AGE OF MAJORITY AND CONTRACTUAL CAPACITY IN EACH QUALIFYING JURISDICTION). BY ACCESSING OR USING
          THE MICROWAVE PLATFORM AND SERVICES YOU REPRESENT AND WARRANT THAT YOU ARE 18 OR OLDER.</p>
        <p>SHOULD YOU HAVE ANY QUESTIONS OR COMMENTS REGARDING THE MICROWAVE PLATFORM OR MICROWAVE SERVICES, PLEASE
          FEEL FREE TO CONTACT US AT: info@microwave.dog</p>
        <section>
          <h2 className="title">1. GENERAL</h2>
          <p>1.1</p>
          <p>By creating an Account (as defined below) through Microwave Platform and websites made available through
            the Microwave Platform, you agree to comply with and be legally bound by this Agreement. If you do not agree
            to any of the terms set forth in this Agreement, or any subsequent modification to the Agreement, you may
            not access or use any of the Microwave Services and must cancel your Microwave Account immediately.</p>
          <p>1.2</p>
          <p>Microwave Privacy Policy, KYC Policy and any other mandatory policies of Microwave or other agreements
            entered into separately between you and Microwave are considered an integral part of this Agreement and have
            the same legal effect. Your use of the Microwave Platform and Services is conditional on the acceptance of
            all supplementary terms as published on Microwave website</p>
          <p>1.3</p>
          <p>We may amend or modify this Agreement at our own discretion at any time by posting such amended or modified
            Agreement on the Microwave website. Any and all modifications or changes will become effective upon
            publication. By continuing to access Microwave Platform or use the Microwave Services once the revised
            Agreement is effective, you agree to be bound by its terms.</p>
        </section>
        <section>
          <h2 className="title">2. INTERPRETATION</h2>
          <p>2.1</p>
          <p>The following terms shall have, for the purposes of these Terms, the following meaning:</p>
          <p>“Account” shall have the meaning as set out in Clause 4 of these Terms;</p>
          <p>“Agreement” shall mean agreement between you, as a User, and Microwave for the use of the Platform and
            Services, in accordance with these Terms;</p>
          <p>“AML” means Anti Money Laundering;</p>
          <p>“Company” or “We” or “Microwave” means the owner and operator of Microwave Platform and the provider of
            Microwave Services;</p>
          <p>“Confidential Information” means information, technical data or know-how, including, but not limited to,
            information relating to business and product or service plans, financial projections, customer lists,
            business forecasts, sales and merchandising, human resources, patents, patent applications, computer object
            or source code, research, inventions, processes, designs, drawings, engineering, marketing or finance to be
            confidential or proprietary or which information would, under the circumstances, appear to a reasonable
            person to be confidential or proprietary;</p>
          <p>“Created Content” shall mean source code, designs, creative ideas, suggestions, proposal, plans, data or
            any other material or content or service submitted to Microwave Platform, or otherwise submitted to
            Microwave or Microwave Clients in any other way, whether online, by email, by postal mail or otherwise;</p>
          <p>“Intellectual Property” means all and any software innovations, computer programs, code, designs, concepts,
            ideas, artwork, notes, documents, information, materials, discoveries, inventions or other original work,
            including without limitation, any additions, modifications or enhancements.</p>
          <p>“KYC” means Know Your Customer;</p>
          <p>“Platform” or “Microwave Platform” means an online platform accessible through website
            https://Microwave.finance/ and application fully owned and operated by Microwave;</p>
          <p>“Project” or "Client” means a third party, who, as a client of Microwave, offers any tokens, products and
            services through the Microwave Platform or website to Microwave users;</p>
          <p>“Services” or “Microwave Services” shall have the meaning as specified in Clause 7 of these Terms.</p>
          <p>“Public Sales”, “Staking”, “Lockup” and “Staked Assets” shall have the meanings as set out in Clause 7 of
            these Terms;</p>
          <p>“TGE” shall mean Token Generation Event;</p>
          <p>“You” shall mean you, as the User;</p>
          <p>“Website” shall mean the Microwave website, accessible on the https://Microwave.finance/.</p>
        </section>
        <section>
          <h2 className="title">3. RISKS</h2>
          <p>3.1</p>
          <p>Please note the following risks of using Microwave Services: The risk of loss in holding any cryptographic
            tokens can be substantial. You should therefore carefully consider whether holding any cryptographic tokens
            is suitable for you in light of your financial condition. In considering whether to hold any cryptographic
            tokens, you should be aware that the price or value of cryptographic tokens can change rapidly, decrease,
            and potentially even fall to zero. When using a Microwave Platform and Services you acknowledge that
            Microwave is not responsible for any losses resulting from market changes or account misappropriation.</p>
          <p>3.2</p>
          <p>You understand and agree that any cryptographic tokens, blockchain technology or distributed ledger
            technology related projects are new and relatively untested and outside of both our and our Clients’
            exclusive control. Any adverse changes in market forces, the technology and regulatory environment impacting
            our performance under this Agreement shall absolve us from responsibility in this regard, including but not
            limited to hacking attacks, possible theft, unfavourable regulatory action, or unclear legal/tax status of
            cryptographic tokens.</p>
          <p>3.3</p>
          <p>You agree and acknowledge that we do not represent or warrant that any Microwave Services or Microwave
            Platform are secure from a hacker or other malicious attack, which may result in the stealing or the loss of
            the User confidential information or any other data.</p>
        </section>
        <section>
          <h2 className="title">4. ACCOUNT CREATION</h2>
          <p>4.1</p>
          <p>Account Creation. In order to use Microwave Platform and Services, you must first create an account by
            connecting your wallet and providing your identification information via KYC verification process.</p>
          <p>4.2</p>
          <p>Upon successful completion of the account creation process, Microwave will establish your Account (also
            “Account”). Microwave may, at its sole discretion, refuse to allow you to establish a Microwave Account, or
            limit the number of Microwave Accounts that a single User may establish and maintain at any time.</p>
        </section>
        <section>
          <h2 className="title">5. KYC AND AML COMPLIANCE</h2>
          <p>5.1</p>
          <p>Microwave is obliged to exert control over the participants in Microwave Platform in order to comply with
            “Know Your Customer” (“KYC”) and “Anti Money Laundering” (“AML”) laws and regulations (together, “KYC/AML
            regulations''). You will not be able to receive any payments from Clients unless you comply successfully
            with established KYC procedures in accordance with our AML Policy.</p>
          <p>5.2</p>
          <p>Documents submitted must be verified by a third party identity verification process in order to verify the
            account and have a right to participate in the presales on Microwave platform. Verification of identity will
            require multi-factor authentication, layered security and other controls to ensure a meaningful User
            identity confirmation process based on accumulated reward size, among other factors. The following are
            examples of verification methods the Company may use:</p>
          <p>a)</p>
          <p>Obtaining proof of address, such as a copy of a utility bill or bank statement from the Account holder;</p>
          <p>b)</p>
          <p>Comparing the identifying information with information available from a trusted third-party source, such as
            international databases;</p>
          <p>c)</p>
          <p>Analysing whether there is logical consistency between the identifying information provided, such as the
            User’s name, street address, ZIP code, and date of birth (logical verification);</p>
          <p>5.3</p>
          <p>If the type of Microwave Account increases the risk that the Company will not be able to verify the true
            identity of the User through documents is confirmed, the Microwave Account won’t be verified.</p>
          <p>5.4</p>
          <p>Sanctions Compliance. Pursuant to the economic sanctions’ programs administered in the jurisdictions where
            Microwave conducts business, Microwave is prohibited from providing services or entering into relationships
            with certain individuals and entities. In the event that Microwave is required to block assets associated
            with your Microwave Account in accordance with a sanctions program, or other similar government sanctions
            programs, Microwave may (i) deactivate or block User activity, (ii) and withhold any outstanding rewards.
            Microwave is not responsible for any losses, whether direct or indirect, that you may incur as a result of
            our complying with applicable law, the guidance or direction of any regulatory authority or government
            agency, or any writ of attachment, lien, levy, subpoena, warrant or other legal order. Accessing Microwave
            Services and Microwave Platform (including any engagement with Clients through the Microwave Platform) is
            subject to compliance with economic sanctions imposed by applicable jurisdictions. Using Microwave Services
            and accessing Microwave Platform, you represent and warrant that your use of any of our services complies
            with those requirements. Without limiting the foregoing, you may not acquire any cryptographic tokens or use
            any of the Microwave Services through the Microwave Platform if: (1) you are in, under the control of, or a
            national or resident of Cuba, Iran, North Korea, Sudan, or Syria or any other country subject to United
            States embargo, UN sanctions, HM Treasury’s financial sanctions regime, or if you are on the U.S. Treasury
            Department’s Specially Designated Nationals List or the U.S. Commerce Department’s Denied Persons List,
            Unverified List, Entity List HM Treasury’s financial sanctions regime; or (2) you intend to supply the
            acquired cryptocurrency(ies) or Microwave Services to Cuba, Iran, North Korea, Sudan or Syria or any other
            country subject to United States embargo or HM Treasury’s financial sanctions regime (or a national or
            resident of one of these countries), or to a person on the Specially Designated Nationals List, Denied
            Persons List, Unverified List, Entity List, or HM Treasury’s financial sanctions regime.</p>
        </section>
        <section>
          <h2 className="title">6. DATA PROTECTION AND PRIVACY POLICY</h2>
          <p>6.1</p>
          <p>Microwave will not share any KYC/AML data with any third parties except for the relevant Client, unless
            complying with relevant authorities and regulations.</p>
          <p>6.2</p>
          <p>Your Privacy. Protecting your privacy is very important to Microwave. Before accessing and using any of our
            services, you shall be required to review and consent to our Privacy Policy.</p>
        </section>
        <section>
          <h2 className="title">7. Microwave SERVICES</h2>
          <p>7.1</p>
          <p>Your Microwave Account enables the access to Microwave Services, which include:</p>
          <p>a)</p>
          <p>Public Sales, Staking and Lockup (as all defined below);</p>
          <p>b)</p>
          <p>Other services available on the Microwave Platform.</p>
          <p>(all together: “Microwave Services”).</p>
          <p>7.2</p>
          <p>We reserve the right to modify or discontinue any portion of the Microwave Services and to suspend or
            terminate your access to the Microwave Services at any time, without notice to you, in certain, limited
            circumstances described herein.</p>
          <p>7.3</p>
          <p>You agree that we shall not be liable to you or any third party for any modification or termination of the
            Microwave Services, or suspension or termination of your access to the Microwave Services, except to the
            extent otherwise expressly set forth herein.</p>
          <p>7.4</p>
          <p>You acknowledge and agree that Microwave merely operates the Microwave Platform and provides Microwave
            Services of intermediary nature which are necessary to enable Users to join and access a Client's respective
            community Microwave, however, shall not actively introduce Users to Clients, find projects for Users, or
            find Users for Clients.</p>
          <p>7.5</p>
          <p>Privacy. When offering Microwave Services, Microwave accesses personal data, such as, but not limited to,
            the name and the composition of the User’s wallet. In order to learn more about our compliance with personal
            data protection, please familiarize yourself with Clause 5 and Clause 6 of this Agreement (KYC) and review
            our Privacy Policy. </p>
          <p>7.6</p>
          <p>Public sales (“Public Sales”)</p>
          <p>Microwave organizes Public Sales enabling Users to get access to Clients’ tokens.</p>
          <p>7.7</p>
          <p>Client - User Token Purchase Agreement. Should a User decide to purchase tokens in a Public Sale, a legal
            relationship in the form of applicable agreement shall be established directly between the User and the
            Client (“Token Purchase Agreement”). Microwave is not a party to any such Client – User Token Purchase
            Agreement or any other direct transactions or dealings between Users and Clients and shall not assume any
            responsibilities or liabilities whatsoever in this regard. You acknowledge, agree, and understand that
            Microwave acts only as an intermediary (providing intermediary services between Users and Clients through
            the Microwave Platform) and is not a party to the relationship or any dealings between the Client(s) and the
            User(s).</p>
          <p>7.8</p>
          <p>Users are responsible for evaluating and determining the suitability of concluding Token Purchase Agreement
            with the Client on their own. You acknowledge and agree that in any Token Purchase Agreement, your
            individual engagement with a particular Client might be subject to specific terms and conditions as set out
            by that Client. You acknowledge, agree, and understand that Microwave is not responsible for any Client
            project. Microwave shall be absolved from any responsibility regarding the Client’s Project’s outcome
            (especially its subsequent value). You as the User take full responsibility regarding any token purchases
            you make on the Microwave Platform.</p>
          <p>7.9</p>
          <p>Lockup (“Lockup”)</p>
          <p>By accepting this Agreement and accessing Microwave Services, you, as a User, consent to Microwave’s right
            to lock Your tokens. When using “Lockup” service, Microwave locks User’s tokens for a specified period of
            time, rendering them invisible in the User’s wallet and unable to be traded, withdrawn or otherwise disposed
            of, unless the User unstakes the tokens, which may result in additional costs and fees.</p>
          <p>7.10</p>
          <p>Staking (“Staking”)</p>
          <p>Staking enables Users to stake their LP tokens ( “Staked Assets” ) in a Staking Pool to get staking rewards
            and opportunity to purchase a limited amount of tokens of a particular token issuer offering a token
            crowdsale on the Microwave platform (“Allocation Priority”). The purchase amount is limited and determined
            in relation to the amount of Staked Assets. By staking more Staked Assets, User obtains an Allocation
            Priority to purchase proportionally more tokens.</p>
        </section>
        <section>
          <h2 className="title">8. GENERAL USE, PROHIBITED USE, AND TERMINATION</h2>
          <p>8.1</p>
          <p>Limited License. We hereby grant you a personal, non-exclusive, non-transferable, non-sublicensable and
            limited license, subject to the terms of this Agreement, to access and use the Microwave Platform solely for
            informational, transactional, or other approved purposes as permitted by Microwave from time to time. Any
            other use of the Microwave Platform is expressly prohibited. All other rights in the Microwave Platform are
            reserved by us. We reserve all rights in the Microwave Platform and you agree that this Agreement does not
            grant you any rights in or licenses to the Microwave Platform, except for this express, limited license. You
            will not otherwise copy, transmit, distribute, sell, resell, license, de-compile, reverse engineer,
            disassemble, modify, publish, create derivative works from, perform, display, incorporate into another
            website, or in any other way exploit any of the content available on the Microwave Platform or any other
            part of the Microwave Platform or any derivative works thereof, in whole or in part for commercial or
            non-commercial purposes. Without limiting the foregoing, you will not frame or display the Microwave
            Platform (or any portion thereof) as part of any other website or any other work of authorship without prior
            written permission. If you violate any portion of this Agreement, your permission to access and use the
            Microwave Services may be terminated pursuant to this Agreement. In addition, we reserve the right to all
            remedies available at law and in equity for any such violation.</p>
          <p>8.2</p>
          <p>Website Accuracy. Although we intend to provide accurate and timely information on the Microwave Website
            and Platform, the Microwave Website and Platform (including, without limitation, the Content) may not always
            be entirely accurate, complete or current and may also include technical inaccuracies or typographical
            errors. In an effort to continue to provide you with as complete and accurate information as possible,
            information may be changed or updated from time to time without notice, including without limitation
            information regarding our policies, products and services. Accordingly, you should verify all information
            before relying on it, and all decisions based on information contained on the Microwave Platform are your
            sole responsibility and we shall have no liability for such decisions. You especially acknowledge,
            understand and agree that Microwave does not assume any liability and shall not be liable for any loss or
            damage arising out of or in connection with any trading decision, made based on any information available on
            the Microwave Website.</p>
          <p>8.3</p>
          <p>Third-Party Materials. From time to time, the Microwave Platform may contain references or links to
            third-party materials (including, without limitation, websites) and third-party applications which are not
            controlled by us. Such information, links, and third-party applications are provided as a convenience to
            you. Such links should not be considered endorsements and such reference does not imply our recommendation,
            approval, affiliation, or sponsorship of that respective property, product, service, or process. You
            acknowledge and agree that we are not responsible for any aspect of the information, content, or services
            contained in any third-party materials or on any third-party sites accessible or linked to the Microwave
            Platform, including without limitation content, property, goods or services available on the linked sites or
            services.</p>
          <p>8.4</p>
          <p>Third-Party Applications. If, to the extent permitted by Microwave from time to time, you grant express
            permission to a third party to access or connect to your Microwave Account, either through the third party’s
            product or service or through the Microwave Platform, you acknowledge that granting permission to a third
            party to take specific actions on your behalf does not relieve you of any of your responsibilities under
            this Agreement. You are fully responsible for all acts or omissions of any third party using your Microwave
            Account credentials. Further, you acknowledge and agree that you will not hold Microwave responsible for,
            and will indemnify Microwave from, any liability arising out of or related to any act or omission of any
            third party using your Microwave Account credentials. You may change or remove permissions granted by you to
            third parties with respect to your Microwave Account at any time through the Account Settings (Integrations)
            page on the Microwave Platform. either through the third party’s product or service or through the Microwave
            Platform, you acknowledge that granting permission to a third party to take specific actions on your behalf
            does not relieve you of any of your responsibilities under this Agreement. You are fully responsible for all
            acts or omissions of any third party using your Microwave Account credentials. Further, you acknowledge and
            agree that you will not hold Microwave responsible for, and will indemnify Microwave from, any liability
            arising out of or related to any act or omission of any third party using your Microwave Account
            credentials. You may change or remove permissions granted by you to third parties with respect to your
            Microwave Account at any time through the Account Settings (Integrations) page on the Microwave
            Platform.</p>
          <p>8.5</p>
          <p>Fiduciary Accounts/Custodial Assets. You hereby certify to us that any funds used by you in connection with
            the Microwave Services are either owned by you or that you are validly authorized to carry out transactions
            using such funds. In particular, you acknowledge that Microwave is not a qualified custodian under
            applicable law.</p>
          <p>8.6</p>
          <p>Prohibited Use. In connection with your use of the Microwave Services, and your interactions with other
            Users, and third parties you agree and represent you will not engage in any illegal, unauthorized, or
            improper activity. We reserve the right at all times to monitor, review, retain and/or disclose any
            information as necessary to satisfy any applicable law, regulation, legal process or governmental request.
            We reserve the right to cancel and/or suspend your Microwave Account immediately and without notice if we
            determine, in our sole discretion, that your Account is associated with prohibited use and/or a prohibited
            business.</p>
          <p>8.7</p>
          <p>Suspension, Termination and Cancellation. Microwave may, at its sole discretion and without any prior
            notification, (a) suspend, restrict, or terminate your access to any or all of the Microwave Services and/or
            Microwave Platform, and/or (b) deactivate or cancel your Microwave Account if:</p>
          <ol type="a">
            <li>We are so required by an enforceable subpoena, court order, or binding order of the court or government
              authority; or
            </li>
            <li>We reasonably suspect you of using your Microwave Account in connection with illegal, unauthorized, or
              improper activity; or
            </li>
            <li>Use of your Microwave Account is subject to any pending litigation, investigation, or government
              proceeding and/or we perceive a heightened risk of legal or regulatory non-compliance associated with your
              Account activity; or
            </li>
            <li>Our Service partners are unable to support your use; or</li>
            <li>We are required to do so in order to comply with KYC/AML laws and regulations; or</li>
            <li>You violate the terms of this Agreement (in particular, but not limited to, providing correct and
              accurate identification information for KYC/AML purposes, or violating any user warranties given under
              this Agreement);
            </li>
            <li>You take any action that Microwave deems as circumventing Microwave’s controls, including, but not
              limited to, opening multiple Microwave Accounts or abusing promotions which Microwave may offer from time
              to time.
            </li>
          </ol>
          <p>8.8</p>
          <p>In the event that a technical problem causes system outage or Account errors, Microwave may temporarily
            suspend access to your Account until the problem is resolved.</p>
          <p>8.9</p>
          <p>You acknowledge and agree that the Microwave assumes no obligations to help Users and Clients enforce any
            claims they might have towards each other in the future (or which might in the future exist between Users
            themselves), which would result from any of their engagements entered into with the intermediation of
            Microwave through Microwave Platform; but may nevertheless choose to do so (at its sole discretion).</p>
        </section>
        <section>
          <h2 className="title">9. OUR RELATIONSHIP WITH YOU</h2>
          <p>9.1</p>
          <p>As per Clause 5 of this Agreement, Microwave performs KYC/AML checks on Users eligible for participation
            (including receiving payments from Clients) within the Platform. You acknowledge, agree, and understand that
            Microwave will transfer any collected KYC/AML data to the Client, and that the Client has the independent
            right to terminate your participation in community of that Client, including termination of the possibility
            to provide Created Content and/or any other services to Clients, and possibility to receive payments from
            the Clients, all based on the results of the KYC/AML checks.</p>
          <p>9.2</p>
          <p>You further acknowledge, agree, and understand that:</p>
          <p>(a)</p>
          <p>you are not an employee of Microwave or any of the Clients, and you are not eligible for any of the rights
            or benefits of employment relationship (including, but not limited to any unemployment and/or workers
            compensation insurance);</p>
          <p>(b)</p>
          <p>Microwave assumes no liability or obligations under or related to User services provided to Clients, any
            Created Content or any acts or omissions by you or by any, any other User, or any of the Clients;</p>
          <p>(c)</p>
          <p>Microwave does not, in any way, supervise, direct, or control any User or services Users may provide to the
            Clients (including, but not limited, to any Created Content, such as defined below); does not impose any
            quality standards or deadlines for completion of any such services and/or provision of any Created Content;
            and does not dictate the performance, methods or process Users take to perform services and/or provide
            Created Content;</p>
          <p>(d)</p>
          <p>Users are free to determine when and if to create and Created Content and to perform any services for the
            benefit of the Clients, including the working hours, and Microwave does not set or have any control over the
            User’s payment, work hours, work schedules, or work location, nor is Microwave involved in any other way in
            determining the nature and amount of any compensation that may be paid to the User for Created Content
            and/or any services provided to Clients;</p>
          <p>(e)</p>
          <p>Users may be paid at such times and amounts at the Clients’ full discretion, and Microwave does not, in any
            way, warrant, represent or guarantee that a particular User will receive any payment or other remuneration
            for Created Content and/or other services provided to any of the Clients (any payments will be determined at
            Client’s sole discretion taking into the account results of the community voting);</p>
          <p>(f)</p>
          <p>Microwave does not provide Users with training or any equipment, labor, tools, or materials related to any
            services; and</p>
          <p>(g)</p>
          <p>Microwave does not provide the premises at which Users may provide Clients with Created Content or any
            other services.</p>
          <p>9.3</p>
          <p>You acknowledge and agree that the Microwave assumes no obligations to help Users and Clients enforce any
            claims they might have towards each other in the future (or which might in the future exist between Users
            themselves), which would result from any of their engagements entered into with the intermediation of
            Microwave through Microwave Platform; but may nevertheless choose to do so (at its sole discretion).</p>
        </section>
        <section>
          <h2 className="title">10. USER REPRESENTATIONS AND WARRANTIES</h2>
          <p>10.1</p>
          <p>By registering to the Platform and creating the Account, you represent and warrant to Microwave as well the
            Clients as follows:</p>
          <p>a)</p>
          <p>you have read carefully in full the contents of this Agreement and of the Microwave Whitepaper to which you
            consent; additionally, you also confirm that you have performed reasonable due diligence with regard to
            Microwave, and that you have, if and to extent you deem necessary, sought independent financial and/or legal
            advice;</p>
          <p>b)</p>
          <p>you agree and acknowledge that any cryptographic tokens that you might receive as remuneration for provided
            Created Content and any other services to the Clients do not constitute and are not intended to constitute
            any securities of any form in any of the jurisdictions;</p>
          <p>c)</p>
          <p>you agree and acknowledge that no regulatory authority has examined or approved of the information set out
            in the Microwave Whitepaper, no action has been or will be taken under the laws, regulatory requirements or
            rules of any jurisdiction and the publication, distribution or dissemination of the Microwave Whitepaper to
            you did not not imply that the applicable laws, regulatory requirements or rules have been complied with,
            nor that Microwave has obtained the requisite licenses, if any;</p>
          <p>d)</p>
          <p>the distribution or dissemination of the Microwave Whitepaper, any part thereof or any copy thereof, or
            acceptance of the same by you, is not prohibited or restricted by the applicable laws, regulations or rules
            in your jurisdiction, and where any restrictions in relation to possession are applicable, you have observed
            and complied with all such restrictions at your own expense and without liability to Microwave;</p>
          <p>e)</p>
          <p>you are not a citizen, resident (tax or otherwise) or green card holder of the United States of America or
            a citizen or resident (tax or otherwise) of the People’s Republic of China;</p>
          <p>f)</p>
          <p>you have a basic degree of understanding of the operation, functionality, usage, storage, transmission
            mechanisms and other material characteristics of cryptocurrencies, blockchain-based software systems,
            cryptocurrency wallets or other related token storage mechanisms, blockchain technology and Smart Contract
            technology;</p>
          <p>g)</p>
          <p>you are fully aware and understand that there are risks associated with Microwave and its business and
            operations;</p>
          <p>h)</p>
          <p>you agree and acknowledge that Microwave is not liable for any indirect, special, incidental, consequential
            or other losses of any kind, in tort, contract or otherwise (including but not limited to loss of revenue,
            income or profits, and loss of use or data), arising out of or in connection to any acceptance of or
            reliance on the Microwave Whitepaper and this Agreement, or with the use of the Microwave Platform and/or
            the Microwave Services;</p>
          <p>i)</p>
          <p>you are at least 18 years old and of the applicable age of majority and contractual capacity in your
            qualifying jurisdiction;</p>
          <p>j)</p>
          <p>all of the above representations and warranties will be true, complete, accurate and non-misleading at the
            time of your registration to the Platform.</p>
        </section>
        <section>
          <h2 className="title">11. RETENTION OF INTELLECTUAL PROPERTY RIGHTS</h2>
          <p>11.1</p>
          <p>Microwave Platform and any Microwave Services, including their design elements or concepts and any and all
            underlying Intellectual Property, including, but not limited to any registered trademarks, are the property
            of Microwave and/or Microwave Clients (as applicable), and are protected by copyright, patent, trade secret
            and other Intellectual Property laws. Microwave and Microwave Clients retain any and all rights, title and
            interest in and to Microwave Platform and Microwave Services (including, without limitation, all
            Intellectual Property rights), including all copies, modifications, extensions and derivative works thereof.
            Your right to use the Microwave Platform and Microwave Services is limited to the rights expressly granted
            in these Terms. No licences to use any of trademarks or any other Microwave brands are to be inferred or
            assumed pursuant to the use of any Microwave Services. All rights not expressly granted to you are reserved
            and retained by Microwave, Microwave Clients and their licensors.</p>
          <p>11.2</p>
          <p>You expressly agree not to duplicate, copy, transmit, distribute, license, reverse engineer, modify,
            publish, reuse or participate in the transfer or sale of, create derivative works from, or in any other way
            exploit any of the Intellectual Property of Microwave or any Microwave Client without the express prior
            written consent of Microwave and, if applicable, its Clients. You shall not infringe the Intellectual
            Property rights of Microwave, Microwave Clients, other Users, or any third party.</p>
        </section>
        <section>
          <h2 className="title">12. CONFIDENTIAL INFORMATION</h2>
          <p>12.1</p>
          <p>You agree that Confidential Information or private data, which you might receive from Microwave or
            Microwave Clients and which is not readily available in the public domain belong to Microwave and/or
            Microwave Clients and you undertake not to disclose to any third person at any time any such Confidential
            Information or private data without the express prior written consent of Microwave and/or Microwave Clients.
            You warrant that you will treat the Confidential Information or private data of the Microwave and Microwave
            Clients with the highest standard of care, that you will comply with all applicable data privacy laws in
            your jurisdiction and that you will not sell or disclose Confidential Information or private data of either
            Microwave or Microwave Clients. When referring to Microwave, this Clause 12 shall also apply to its
            affiliates and service providers, or any of their respective officers, directors, agents, joint venturers,
            employees or representatives.</p>
        </section>
        <section>
          <h2 className="title">13. NO WARRANTY, LIMITATION OF LIABILITY</h2>
          <p>13.1</p>
          <p>Disclaimer of liability. (A) IN NO EVENT SHALL Microwave, ITS AFFILIATES AND SERVICE PROVIDERS, OR ANY OF
            THEIR RESPECTIVE OFFICERS, DIRECTORS, AGENTS, JOINT VENTURERS, EMPLOYEES OR REPRESENTATIVES, BE LIABLE FOR
            INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR OTHER LOSSES OF ANY KIND, IN TORT, CONTRACT OR OTHERWISE
            (INCLUDING BUT NOT LIMITED TO LOSS OF REVENUE, INCOME OR PROFITS, AND LOSS OF USE OR DATA), ARISING OUT OF
            OR IN CONNECTION WITH ANY ACCEPTANCE OF OR RELIANCE ON THE Microwave WHITEPAPER AND THIS AGREEMENT, OR WITH
            THE USE OF THE Microwave PLATFORM AND/OR THE Microwave SERVICES.</p>
          <p>13.2</p>
          <p>Computer Malware. We shall not bear any liability, whatsoever, for any damage or interruptions caused by
            any computer malware, spyware, or scareware that may affect your computer or other equipment, or any
            phishing, spoofing or other attack. We advise the regular use of a reputable and readily available virus
            screening and prevention software. You should also be aware that SMS and email services are vulnerable to
            spoofing and phishing attacks and should use care in reviewing messages purporting to originate from
            Microwave. Always log into your Microwave Account through the Microwave Platform to review any transactions
            or required actions if you have any uncertainty regarding the authenticity of any communication or
            notice.</p>
          <p>13.3</p>
          <p>Release of Microwave. If you have a dispute with one or more Users of the Microwave Services, to the extent
            permitted by law, you release Microwave, its affiliates and Service Providers, and each of their respective
            officers, directors, agents, joint venturers, employees and representatives from any and all claims, demands
            and damages (actual and consequential) of every kind and nature arising out of or in any way connected with
            such disputes. In addition, to the extent permitted by law, in entering into this release you expressly
            waive any protections that would otherwise limit the coverage of this release to include only those claims
            which you may know or suspect to exist in your favor at the time of agreeing to this release.</p>
          <p>13.4</p>
          <p>No Warranty. (A) THE Microwave SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY
            REPRESENTATION OR WARRANTY, WHETHER EXPRESS, IMPLIED, OR STATUTORY. Microwave SPECIFICALLY DISCLAIMS ANY
            IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
            Microwave DOES NOT MAKE ANY REPRESENTATIONS OR WARRANTIES THAT ACCESS TO ANY PART OF THE Microwave PLATFORM
            AND SERVICES, OR ANY OF THE MATERIALS CONTAINED THEREIN, WILL BE CONTINUOUS, UNINTERRUPTED, TIMELY,
            ERROR-FREE OR SECURE. OPERATION OF THE Microwave PLATFORM MAY BE INTERFERED WITH BY NUMEROUS FACTORS OUTSIDE
            OF OUR CONTROL. Microwave ALSO SPECIFICALLY DISCLAIMS ANY REPRESENTATION, WARRANTY OR UNDERTAKING IN ANY
            FORM WHATSOEVER TO ANY ENTITY OR PERSON, INCLUDING ANY REPRESENTATION, WARRANTY OR UNDERTAKING IN RELATION
            TO THE TRUTH, ACCURACY AND COMPLETENESS OF ANY OF THE INFORMATION SET OUT IN THIS AGREEMENT AND THE
            WHITEPAPER.</p>
          <p>13.5</p>
          <p>WE ARE NOT RESPONSIBLE FOR THE CRYPTOCURRENCY MARKET, AND WE MAKE NO REPRESENTATIONS OR WARRANTIES
            CONCERNING THE REAL OR PERCEIVED VALUE OF CRYPTOCURRENCY AS DENOMINATED IN ANY QUOTED CURRENCY. ALTHOUGH WE
            MAY PROVIDE HISTORICAL AND/OR REAL-TIME DATA REGARDING THE PRICE OF CRYPTOCURRENCY, WE MAKE NO
            REPRESENTATIONS REGARDING THE QUALITY, SUITABILITY, TRUTH, USEFULNESS, ACCURACY, OR COMPLETENESS OF SUCH
            DATA, AND YOU SHOULD NOT RELY ON SUCH DATA FOR ANY REASON WHATSOEVER. YOU UNDERSTAND AND AGREE THAT THE
            VALUE OF CRYPTOCURRENCY CAN BE VOLATILE, AND WE ARE NOT IN ANY WAY RESPONSIBLE OR LIABLE FOR ANY LOSSES YOU
            MAY INCUR BY HOLDING OR TRADING CRYPTOCURRENCY, EVEN IF THE Microwave SERVICES ARE DELAYED, SUSPENDED, OR
            INTERRUPTED FOR ANY REASON. FURTHER, Microwave MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE QUALITY,
            SUITABILITY, TRUTH, USEFULNESS, ACCURACY, OR COMPLETENESS OF THE Microwave SERVICES OR ANY MATERIALS
            CONTAINED THEREIN. (B) FOR CONSUMERS ONLY: WHERE APPLICABLE CONSUMER PROTECTION LAW LIMITS THE EFFECTIVENESS
            OF SECTION 8.5(A) WITH RESPECT TO CONSUMERS, SECTION 8.5(A) ONLY APPLIES TO THE MAXIMUM EXTENT AS PERMITTED
            BY LAW.</p>
          <p>13.6</p>
          <p>Microwave, ITS AFFILIATES AND SERVICE PROVIDERS, OR ANY OF THEIR RESPECTIVE OFFICERS, DIRECTORS, AGENTS,
            JOINT VENTURERS, EMPLOYEES OR REPRESENTATIVES DO NOT VERIFY, CONFIRM OR IN ANY WAY WHATSOEVER TAKE
            RESPONSIBILITY FOR CLIENTS’ PROJECTS, PROJECTS’ OUTCOMES AND ESPECIALLY THEIR SUBSEQUENT VALUE, AND ARE IN
            NO WAY RESPONSIBLE FOR THE CLIENTS’ PROJECTS AND THE FUNCTIONALITIES OR USES OF TOKENS OFFERED ON OR THROUGH
            Microwave PLATFORM.</p>
        </section>
        <section>
          <h2 className="title">14. TAXES AND PUBLIC CHARGES</h2>
          <p>14.1</p>
          <p>By accepting this Agreement and accessing our Services you, as a User, acknowledge and agree that you are
            solely responsible:</p>
          <p>a)</p>
          <p>for any and all tax liabilities associated with payments received from Clients, and that Microwave will not
            withhold any taxes from payments to User;</p>
          <p>b)</p>
          <p>to obtain any disability, health, workers’ compensation, unemployment, or other insurance needed, desired,
            or required by any applicable law, and that User is not covered by or eligible for any insurance from
            Microwave or the Clients;</p>
          <p>c)</p>
          <p>for determining whether User is required by applicable law to issue any particular invoices for the Created
            Content or other services provided to the Clients and for issuing any invoices so required;</p>
          <p>d)</p>
          <p>for determining whether User is required by applicable law to remit to the appropriate authorities any
            value added tax, sales tax or any other taxes or similar charges applicable to the Created Content or other
            services provided to the Clients, and remitting any such taxes or charges to the appropriate tax
            authorities, as applicable.</p>
        </section>
        <section>
          <h2 className="title">15. FORCE MAJEURE</h2>
          <p>15.1</p>
          <p>We shall not be liable for delays, failure in performance or interruption of service which result directly
            or indirectly from any cause or condition beyond our reasonable control, including but not limited to, any
            delay or failure due to any act of God, act of civil or military authorities, act of terrorists, civil
            disturbance, war, strike or other labor dispute, fire, interruption in telecommunications or Internet
            services or network provider services, failure of equipment and/or software, other catastrophe or any other
            occurrence which is beyond our reasonable control and shall not affect the validity and enforceability of
            any remaining provisions.</p>
        </section>
        <section>
          <h2 className="title">16. OTHER GENERAL PROVISIONS</h2>
          <p>16.1</p>
          <p>Relationship of the Parties. Microwave is an independent contractor for all purposes. Nothing in this
            Agreement shall be deemed or is intended to be deemed, nor shall it cause you and Microwave to be treated as
            partners, joint ventures, or otherwise as joint associates for profit, or either you or Microwave to be
            treated as the agent of the other.</p>
          <p>16.2</p>
          <p>Service Providers. From time to time, Microwave may engage third parties to assist Microwave in providing
            certain aspects of the Microwave Services (each, a “Service Provider”). Service Providers may include, but
            are not limited to, Microwave’s technology or engineering service providers.</p>
          <p>16.3</p>
          <p>Indemnification. You agree to indemnify and hold Microwave, its affiliates and Service Providers, and each
            of their respective officers, directors, agents, joint venturers, employees and representatives, harmless
            from any claim or demand (including attorneys’ fees and any fines, fees or penalties imposed by any
            regulatory authority) arising out of or related to (i) your breach of this Agreement, (ii) your use of
            Microwave Services or (iii) your violation of any law, rule or regulation, or the rights of any third
            party.</p>
          <p>16.4</p>
          <p>Entire Agreement. This Agreement sets forth the entire understanding and agreement between you and
            Microwave as to the subject matter hereof, and supersedes any and all prior discussions, agreements and
            understandings of any kind (including without limitation any prior versions of this Agreement), and every
            nature between and among you and Microwave.</p>
          <p>16.5</p>
          <p>Assignment. This Agreement, and any rights and licenses granted hereunder, may not be transferred or
            assigned by you, but may be assigned by Microwave without restriction, including without limitation to any
            of its affiliates or subsidiaries, or to any successor in interest of any business associated with the
            Microwave Services. Any attempted transfer or assignment in violation hereof shall be null and void. Subject
            to the foregoing, this Agreement will bind and insure to the benefit of the parties, their successors and
            permitted assigns.</p>
          <p>16.6</p>
          <p>Severability. If any provision of this Agreement shall be determined to be invalid or unenforceable under
            any rule, law or regulation or any governmental agency, local, state, or federal, such provision will be
            changed and interpreted to accomplish the objectives of the provision to the greatest extent possible under
            any applicable law and the validity or enforceability of any other provision of this Agreement shall not be
            affected.</p>
          <p>16.7</p>
          <p>Survival. All provisions of this Agreement which by their nature extend beyond the expiration or
            termination of this Agreement, including, without limitation, sections pertaining to suspension or
            termination, Microwave Account cancellation, debts owed to Microwave, general use of the Microwave Platform,
            disputes with Microwave, and general provisions, shall survive the termination or expiration of this
            Agreement.</p>
          <p>16.8</p>
          <p>Section Headings. Section headings in this Agreement are for convenience only, and shall not govern the
            meaning or interpretation of any provision of this Agreement.</p>
          <p>16.9</p>
          <p>Third Party Right. Unless expressly provided to the contrary in this Agreement, a person who is not a party
            to this Agreement has no right to enforce or to enjoy the benefit of any term of this Agreement.
            Notwithstanding any term of this Agreement, no consent of any party who is not a party to this Agreement
            shall be required for the waiver, variation or termination of any part of this Agreement.</p>
          <p>16.10</p>
          <p>Governing Law. The laws of the Commonwealth of The Bahamas (with the exclusion of any rules that might lead
            to the use of any other law which is not the law of the Commonwealth of The Bahamas) will govern the
            validity and construction of this Agreement, any separate agreement whereby we provide you any services and
            any dispute arising out of or in relation to this Agreement or such separate agreement.</p>
          <p>16.11</p>
          <p>Governing Law. Any controversy, claim, dispute or disagreement that may arise between the Users and
            Microwave out of or in relation to this Agreement or any separate agreement made based on this Agreement
            shall be settled by the Court of the Bahamas.</p>
        </section>
        <section>
          <h2 className="title">17. CONTACT US</h2>
          <p>17.1</p>
          <p>If you have any feedback, question, or complaint, contact us via email at <a
            href="mailto:info@microwave.dog">info@microwave.dog</a> When you
            contact us please provide us with your name, wallet address, and any other information we may need to
            identify you, your Microwave Account, and the issue on which you have feedback, questions, or
            complaints.</p>
        </section>
      </Container>
    </section>
  </StaticPageWrapper>

