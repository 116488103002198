import {
  NormalizedProjectInfo,
  ProjectStatusesProps,
  ProjectTypes,
  WhitelistAllowance
} from '@components/Project/types';

type ProjectGetter<T> = (project: NormalizedProjectInfo) => T

const getIsMultipleVesting: ProjectGetter<boolean> = project => project.settings.multiple_vesting
const getIsExternalPresale: ProjectGetter<boolean> = project => project.settings.is_external_presale
const getIsNFTPresaleProject: ProjectGetter<boolean> = project => project.project_type === ProjectTypes.NFT_presale
const getIsNFTClaimProject: ProjectGetter<boolean> = project => project.project_type === ProjectTypes.NFT_claim
const getIsNFTCampaign: ProjectGetter<boolean> = project => getIsNFTPresaleProject(project) || getIsNFTClaimProject(project)
const getIsProjectInProgress: ProjectGetter<boolean> = project =>
  [ProjectStatusesProps['Guaranteed Live'], ProjectStatusesProps['FCFS Live']].includes(project.presale.statusMessage as ProjectStatusesProps)
const getProjectTypeSuffix: ProjectGetter<string> = project => {
  switch (project.project_type) {
    case ProjectTypes.NFT_presale:
    case ProjectTypes.token_presale:
      return 'Pre-sale'
    case ProjectTypes.NFT_claim:
      return 'Claim'
    default:
      return ''
  }
}

export const projectGetters = {
  getIsMultipleVesting,
  getIsExternalPresale,
  getIsNFTCampaign,
  getIsNFTPresaleProject,
  getIsNFTClaimProject,
  getIsProjectInProgress,
  getProjectTypeSuffix
}

const getIsEmailRequired: ProjectGetter<boolean> = project => project.whitelisting.fields.email_required
const getIsTelegramRequired: ProjectGetter<boolean> = project => project.whitelisting.fields.telegram_required
const getIsTwitterRequired: ProjectGetter<boolean> = project => project.whitelisting.fields.twitter_required
const getIsFollowDSRequired: ProjectGetter<boolean> = project => Boolean(project.whitelisting.fields.follow_discord_url)
const getIsFollowTwitterRequired: ProjectGetter<boolean> = project => Boolean(project.whitelisting.fields.follow_twitter_url)
const getIsFollowTelegramRequired: ProjectGetter<boolean> = project => Boolean(project.whitelisting.fields.follow_telegram_url)
const getWhitelistAllowances: ProjectGetter<WhitelistAllowance[]> = project => project.whitelisting.participants_allowed

export const whitelistGetters = {
  getIsEmailRequired,
  getIsTelegramRequired,
  getIsTwitterRequired,
  getIsFollowDSRequired,
  getIsFollowTwitterRequired,
  getIsFollowTelegramRequired,
  getWhitelistAllowances
}
