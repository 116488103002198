import { NetworkId } from '@contracts/networks';
import { ContractAddress } from '@contracts/address';
import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import { useWeb3 } from '@hooks/useWeb3';
import { useNetwork } from '@hooks/useNetwork';
import { useMemo } from 'react';
import {
  getProjectPresaleContract,
  getProjectVestingContract,
  getTokenContract,
  getWhitelistContract
} from '@contracts/getContract';

const useDynamicContract = (getter: (chainId: NetworkId, address?: ContractAddress, web3?: Web3) => Contract | null, address?: ContractAddress): Contract | null => {
  const web3 = useWeb3();
  const { currentNetworkId } = useNetwork()
  return useMemo(
    () => currentNetworkId ? getter(currentNetworkId, address, web3) : null,
    [address, web3, currentNetworkId]
  )
}

export const useTokenContract = (address?: ContractAddress) => {
  return useDynamicContract(getTokenContract, address)
};

export const useProjectPresaleContract = (address?: ContractAddress) => {
  return useDynamicContract(getProjectPresaleContract, address)
};

export const useProjectVestingContract = (address?: ContractAddress) => {
  return useDynamicContract(getProjectVestingContract, address)
};

export const useWhitelistContract = (address?: ContractAddress) => {
  return useDynamicContract(getWhitelistContract, address)
};

export const useMultipleVestingContracts = (addresses: ContractAddress[]) => {
  const web3 = useWeb3();
  const { currentNetworkId } = useNetwork()
  return useMemo(
    () => currentNetworkId ? addresses.map(address => getProjectVestingContract(currentNetworkId, address, web3) as Contract) : [],
    [addresses, web3, currentNetworkId]
  );
};
