import { useSelector } from './useSelector';
import { ActionType, AppState } from '@store/types';
import { useDispatch } from './useDispatch';
import { getPlatformSettings } from '@api/settings';
import { PlatformSettingsResponse } from '@api/settings/types';
import { MWaveProjectId } from '@constants';

export interface PlatformSettings {
  featuredProjectId: string
  flameTiersFactor: number
}

const mapSettings = ({
  featured_project_id,
  flame_tiers_factor,
}: PlatformSettingsResponse): PlatformSettings => ({
  featuredProjectId: featured_project_id ?? MWaveProjectId as string,
  flameTiersFactor: flame_tiers_factor ?? 1
})

export const usePlatformSettings = () => {
  const settings = useSelector<AppState['settings']>(state => state.settings)
  const dispatch = useDispatch()

  const getSettings = async () => {
    const payload = mapSettings(await getPlatformSettings())

    dispatch({
      type: ActionType.SET_PLATFORM_SETTINGS,
      payload
    })
  }

  return {
    settings,
    getSettings
  }
}
