import React from 'react';
import './TeamCard.css'
import { Image } from 'react-bootstrap';

export interface TeamMember {
  avatar: string
  name: string
  logo?: string
  bio: string | React.ReactNode
}

export const TeamCard = ({
  avatar,
  name,
  logo,
  bio
}: TeamMember) => {

  return (
    <div className="team-card">
      <div className='team-card__inner'>
        <div className='team-card__image-box'>
          <Image roundedCircle loading="lazy" src={avatar} alt={name} className='team-card__image' />
          {logo && (
            <img
              className="team-card__logo"
              src={logo}
              alt={`${name} Logo`}
            />
          )}
        </div>
        <h4 className='team-card__name'>{name}</h4>
        <p className='team-card__bio'>
          {bio}
        </p>
      </div>
    </div>
  )
}
