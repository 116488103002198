import React from 'react';
import './AboutUs.scss'
import { SEOTags, TeamCard, TeamMember } from '@components';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { seoTags } from '@/seo-content';
import { partners } from '../../Homepage/contents';
import PlusIcon from '../../../assets/add-icon.svg'
import PageHeading from '@components/layout/PageHeading/PageHeading'
import Banner from '@assets/banners/about-us.png'

const teamMembers: TeamMember[] = [
]

const investors: TeamMember[] = [
]

export const AboutUs = () => {

  return (
    <>
      <SEOTags {...seoTags.aboutUs} />
      <PageHeading banner={Banner} className='about-us'>
        <PageHeading.Title>
          <Row className='justify-content-center'>
            <Col className="text-center" xl={{ span: 8 }}>
              Throughout the year, we provide resources to the top projects and communities. Launching IDOs, projects
              open their pre-sale funding rounds to early supporters and $MWAVE Token Holders
            </Col>
          </Row>
        </PageHeading.Title>
      </PageHeading>
      <section className='team'>
        <Container>
          <div className='team__heading'>
            <Row className="justify-content-center">
              <Col xl={{ span: 10 }}>
                <h2 className='title'>The Core Team</h2>
                <p>
                  Our team consists of Founders, Marketers and Key Opinion Leaders from top 100 crypto projects allowing
                  us
                  to create network effects for projects joining our incubator with ease.
                  <br />
                  <br />
                  Microwave is a San Francisco based crypto company
                </p>
              </Col>
            </Row>
          </div>
          <Row className="team__list g-5 justify-content-center">
            {
              teamMembers.map((member) => (
                <Col key={member.name} md={{ span: 6 }} lg={{ span: 4 }}>
                  <TeamCard
                    avatar={`/team/${member.avatar}`}
                    name={member.name}
                    bio={member.bio}
                  />
                </Col>
              ))
            }
          </Row>
        </Container>
      </section>
      {/*<section className="partnerships-about-us">*/}
      {/*  <Container>*/}
      {/*    <h2 className="title text-center">Partners</h2>*/}
      {/*    <Row className="align-items-stretch justify-content-center">*/}
      {/*      {partners.map((item) => (*/}
      {/*        <Col*/}
      {/*          key={item.name}*/}
      {/*          sm={{ span: 6 }}*/}
      {/*          md={{ span: 4 }}*/}
      {/*          lg={{ span: 3 }}*/}
      {/*          className="partnerships-col"*/}
      {/*        >*/}
      {/*          {item.logo}*/}
      {/*        </Col>*/}
      {/*      ))}*/}
      {/*    </Row>*/}
      {/*  </Container>*/}
      {/*</section>*/}
      <section className='investors'>
        <Container>
          <h2 className='title text-center'>Investors</h2>
          <Row className="investors-list g-5 justify-content-center">
            {
              investors.map((member) => (
                <Col key={member.name} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 3 }}>
                  <TeamCard
                    avatar={`/investors/${member.avatar}`}
                    name={member.name}
                    bio={member.bio}
                    logo={member.logo && `/investors/logos/${member.logo}`}
                  />
                </Col>
              ))
            }
            <Col md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 3 }}>
              <div className='add-investor-card'>
                <div className='add-investor-card__image-box'>
                  <Image
                    roundedCircle
                    className="add-investor-card__icon"
                    src={PlusIcon}
                    alt="Become Investor"
                  />
                </div>
                <p className='add-investor-card__text'>
                  Become <br />
                  an investor
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
