import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import './IndividualProject.scss'
import { generatePath, useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import {
  BackLink,
  LoadingWrap,
  ProjectDescription,
  ProjectStats,
  SEOTags,
  SwapForm,
  TimezoneSwitcher,
  WrongNetworkBlock
} from '@components';
import { ProjectStatusesProps, ProjectStatusProp } from '@components/Project/types'
import { useWhitelist, WhitelistStatuses } from '@contracts/hooks/useWhitelist'
import { balanceToNumber, } from '@utils/balanceFormatter';
import { useApproval } from '@contracts/hooks/useApproval';
import { IWhitelistUserData, usePresale } from '@contracts/hooks/usePresale';
import { useProjectsState } from '@contracts/hooks/useProjects';
import { OGProperties } from '@/seo-content/types';
import { RoutesPaths } from '@router/constants';
import { createSAFTDocContent, SAFTfonts } from '@components/Project/SAFTModal/contents/pdf';
import { generatePdfAndOpen } from '@utils/pdfMake';
import { projectGetters } from '@contracts/getters/projectGetters';
import { useNetwork } from '@hooks/useNetwork';
import { checkIsMerkleWhitelisting } from '@contracts/networks';
import { NotifyTxCallbacks } from '@contracts/notify';
import { useWeb3React } from '@web3-react/core';
import { getIsSingleApprovalToken } from '@contracts/address';
import { maxUint256String } from '@utils/web3';
import { useReactiveDate } from '@hooks/useReactiveDate';
import { normalizeStatus } from '@api/projects/mapping';
import { SECOND } from '@constants/dates';
import { useTimezoneFormat } from '@hooks/useTimezoneFormat'
import {
  ClaimButton,
  ClaimSection,
  ExternalDescription,
  WhitelistBlock,
  WrongNetwork
} from '@pages/IndividualProject/IndividualProjectComponents'

interface ParamTypes {
  id: string
}

export const IndividualProject = () => {
  const { id } = useParams<ParamTypes>()

  const {
    loading,
    currentProject: project,
    getProjectById
  } = useProjectsState()

  const {
    currentFormat,
    switchFormat,
    formatDate
  } = useTimezoneFormat()

  useEffect(() => {
    if (id !== project?.id || !project) {
      getProjectById(id)
    }
  }, [id, project])

  const currentDate = useReactiveDate()

  const statusMessage: ProjectStatusProp = useMemo(
    () => {
      if (!project) return ProjectStatusesProps['Coming Soon'];
      return normalizeStatus({
        status: project.presale.status,
        whitelistStart: project.whitelisting.starts_at,
        whitelistEnd: project.whitelisting.end_at,
        privatePresaleStart: project.presale.private_starts_at,
        privatePresaleEnd: project.presale.private_end_at,
        publicPresaleStart: project.presale.public_starts_at,
        publicPresaleEnd: project.presale.public_end_at
      });
    },
    [project, currentDate]
  );

  const isExternalPresale = useMemo(() => project ? projectGetters.getIsExternalPresale(project) : false, [project])
  const isNFTCampaign = useMemo(() => project ? projectGetters.getIsNFTCampaign(project) : false, [project])
  const isNFTClaim = useMemo(() => project ? projectGetters.getIsNFTClaimProject(project) : false, [project])

  const isPrivatePresaleInProgress = useMemo(
    () => statusMessage === ProjectStatusesProps['Guaranteed Live'],
    [statusMessage]
  )
  const isPublicPresaleInProgress = useMemo(
    () => statusMessage === ProjectStatusesProps['FCFS Live'],
    [statusMessage]
  )
  const isPresaleInProgress = useMemo(
    () => isPublicPresaleInProgress || isPrivatePresaleInProgress,
    [isPublicPresaleInProgress, isPrivatePresaleInProgress]
  )

  const { account } = useWeb3React()

  const {
    checkIfSelected,
    getNetwork,
  } = useNetwork()
  const isProjectNetworkSelected = useMemo(() => project ? checkIfSelected(project.chainId) : false, [project, checkIfSelected])
  const projectNetwork = useMemo(() => project ? getNetwork(project.chainId) : null, [project])

  const {
    allowance,
    onApprove
  } = useApproval(
    ...(isProjectNetworkSelected ? [
      project?.presale.fund_token.address,
      project?.presale.presale_contract_address
    ] : [])
  )

  const handleApprove = useCallback(async (
    amount?: string,
    callbacks: NotifyTxCallbacks = {}
  ) => {
    if (!project || !project.presale.fund_token.address) return
    const isSingleApproval = getIsSingleApprovalToken(project.presale.fund_token.address)
    if (!isSingleApproval) {
      await onApprove(amount, callbacks)
      return
    }

    if (!allowance.isZero()) {
      await onApprove('0')
    }
    await onApprove(maxUint256String, callbacks)
  }, [project, allowance, onApprove])

  const {
    whiteListStatus,
    isKycPassed,
    publicMaxAllocation,
    privateMaxAllocation,
    privatePresaleAllowed,
    merkleProof,
  } = useWhitelist(
    id,
    isProjectNetworkSelected ? project?.presale.whitelist_contract_address : undefined
  )

  const whitelistStatusMessage = useMemo(() => {
    switch (whiteListStatus) {
      case WhitelistStatuses.passed:
        return 'Wallet is whitelisted'
      case WhitelistStatuses.in_review:
        return 'Application is pending...'
      default:
        return 'Wallet is NOT whitelisted'
    }
  }, [whiteListStatus])

  const {
    fundTokenBalance: balance,
    totalSwapAmount,
    fundsSwapped,
    totalRewardsAmount,
    swappedByUser,
    swappedPublicByUser,
    swappedPrivateByUser,
    swapExchangeRate,
    participants,
    closePeriod,
    fetchActualInfo,
    onDeposit,
    onDepositPrivate,
    onDepositEth,
    onDepositEthPrivate,
    fundsDecimals,
    rewardsDecimals
  } = usePresale(
    ...(isProjectNetworkSelected ? [
      project?.presale.presale_contract_address,
      project?.presale.fund_token.address,
      project?.presale.reward_token.address
    ] : [])
  )

  const fetchingInterval = useRef<ReturnType<typeof setInterval> | null>(null)

  useEffect(() => {
    if (!fetchingInterval.current && isPresaleInProgress) {
      fetchingInterval.current = setInterval(() => {
        fetchActualInfo()
      }, 10 * SECOND)
    }

    return () => {
      fetchingInterval.current && clearInterval(fetchingInterval.current)
    }
  }, [isPresaleInProgress, fetchActualInfo, fetchingInterval])

  const isClosePeriod = useMemo<boolean>(() => {
    if (!project?.presale.public_end_at || !closePeriod) {
      return false
    }

    const closePeriodStarts = +project.presale.public_end_at - closePeriod;
    return +currentDate > closePeriodStarts && +currentDate < +project.presale.public_end_at
  }, [project, closePeriod, currentDate])

  const depositMethod = useCallback(async (
    amount: string,
    callbacks: NotifyTxCallbacks = {}
  ) => {
    if (!project || !account) return
    const isMerkleWhitelisting = checkIsMerkleWhitelisting(project.chainId)
    if (isMerkleWhitelisting) {
      if (!merkleProof) return

      const userData: IWhitelistUserData = {
        //TODO: Add checking for actual KYC status
        isKycPassed,
        wallet: account,
        allowedPrivateSale: privatePresaleAllowed,
        privateMaxAlloc: privateMaxAllocation.toFixed(0, 1),
        publicMaxAlloc: publicMaxAllocation.toFixed(0, 1)
      }

      await onDepositEth(
        amount,
        userData,
        merkleProof,
        callbacks
      )
    } else {
      await onDeposit(amount, callbacks)
    }
  }, [
    project,
    account,
    onDeposit,
    onDepositEth,
    whiteListStatus,
    privateMaxAllocation,
    privatePresaleAllowed,
    publicMaxAllocation,
    merkleProof,
  ])

  const depositPrivateMethod = useCallback(async (
    amount: string,
    callbacks: NotifyTxCallbacks = {}
  ) => {
    if (!project || !account) return
    const isMerkleWhitelisting = checkIsMerkleWhitelisting(project.chainId)
    if (isMerkleWhitelisting) {
      if (!merkleProof) return

      const userData: IWhitelistUserData = {
        //TODO: Add checking for actual KYC status
        isKycPassed,
        wallet: account,
        allowedPrivateSale: privatePresaleAllowed,
        privateMaxAlloc: privateMaxAllocation.toFixed(0, 1),
        publicMaxAlloc: publicMaxAllocation.toFixed(0, 1)
      }

      await onDepositEthPrivate(
        amount,
        userData,
        merkleProof,
        callbacks
      )
    } else {
      await onDepositPrivate(amount, callbacks)
    }
  }, [
    project,
    account,
    onDepositPrivate,
    onDepositEthPrivate,
    whiteListStatus,
    privateMaxAllocation,
    privatePresaleAllowed,
    publicMaxAllocation,
    merkleProof,
  ])

  const downloadTermsAndConditions = useCallback(async () => {
    if (!project || !project.saft) {
      return
    }
    const docDefinition = createSAFTDocContent({
      projectName: project.name,
      registrationData: project.saft.company_registration_data,
      exchangeRate: project.saft.exchange_rate,
      companyOfficialName: project.saft.official_company_name,
      rewardTokenName: project.presale.reward_token.name,
      fundTokenName: project.presale.fund_token.name,
      contactEmail: project.saft.contact_email,
    })

    await generatePdfAndOpen({
      docDefinition,
      fonts: SAFTfonts
    })
  }, [project])

  return (
    <div className="project-page page">
      <SEOTags
        url={`${window.location.origin}${generatePath(RoutesPaths.PROJECT, { id })}`}
        title={`| Project ${project?.name || ''}`}
        properties={[
          {
            property: OGProperties.title,
            content: `Microwave | Project ${project?.name || ''}`,
          },
          {
            property: OGProperties.description,
            content: 'Swap Tokens during Project Campaign and receive rewards',
          },
          {
            property: OGProperties.url,
            content: `${window.location.origin}${generatePath(RoutesPaths.PROJECT, { id })}`,
          },
        ]}
      />
      <LoadingWrap loading={loading}>
        <section className='details-section'>
          <Container>
            <BackLink to={RoutesPaths.PROJECTS}>
              Back to Projects
            </BackLink>
            {!!project &&
              <Row className="gx-4 gy-4 gx-xl-5 gy-xl-5">
                <Col xs={{ span: 12 }} xl={{ span: 6 }}>
                  <ProjectDescription
                    logo={project.assets.logo_image_url}
                    name={project.name}
                    projectType={project.project_type}
                    subtitle={project.info.subtitle}
                    status={statusMessage}
                    network={project.network}
                    fundTokenName={project.presale.fund_token.name}
                    description={project.info.description}
                    onDownloadTerms={project.saft && downloadTermsAndConditions}
                    socialLinks={project.social_links}
                  />
                </Col>
                <Col xs={{ span: 12 }} xl={{ span: 6 }}>
                  <ProjectStats
                    totalAmount={balanceToNumber(totalSwapAmount, fundsDecimals)}
                    totalRewards={balanceToNumber(totalRewardsAmount, rewardsDecimals)}
                    fundsSwapped={balanceToNumber(fundsSwapped, fundsDecimals)}
                    participants={participants}
                    fundTokenName={project.presale.fund_token.name}
                    rewardTokenName={project.presale.reward_token.name}
                    swapExchangeRate={balanceToNumber(swapExchangeRate, 0)}
                    status={statusMessage}
                    whitelistingOpens={project.whitelisting.starts_at}
                    whitelistingCloses={project.whitelisting.end_at}
                    privateOpens={project.presale.private_starts_at}
                    privateCloses={project.presale.private_end_at}
                    publicOpens={project.presale.public_starts_at}
                    publicCloses={project.presale.public_end_at}
                    minAllocation={project.presale.min_allocation}
                    maxAllocation={project.presale.max_allocation}
                    isExternalPresale={isExternalPresale}
                    isProjectNetworkSelected={isProjectNetworkSelected}
                    expanded
                    formatDate={formatDate}
                  >
                    <div className={'text-center d-flex align-items-center justify-content-center mt-4 mb-4'}>
                      <div className={'me-2 main-grey-text fw-medium'}>
                        Timezone
                      </div>
                      <TimezoneSwitcher
                        name={`projects-tz-format`}
                        id={`projects-tz-format`}
                        value={currentFormat}
                        onChange={switchFormat}
                      />
                    </div>
                    {!isProjectNetworkSelected &&
                      <WrongNetworkBlock prefix={'To see full stats'} expectedNetwork={projectNetwork!} />}
                    <WhitelistBlock
                      whiteListStatus={whiteListStatus}
                      statusMessage={statusMessage}
                      projectId={id}
                      whitelistStatusMessage={whitelistStatusMessage}
                    />
                  </ProjectStats>
                  {isExternalPresale && isPresaleInProgress &&
                    <ClaimButton project={project} nftClaim={isNFTClaim} />}
                  {!!project
                    && isPresaleInProgress
                    && !isExternalPresale
                    && (isProjectNetworkSelected
                        ? <SwapForm
                          totalSwapAmount={totalSwapAmount}
                          fundsSwapped={fundsSwapped}
                          publicMaxAllocation={publicMaxAllocation}
                          privateMaxAllocation={privateMaxAllocation}
                          privatePresaleAllowed={privatePresaleAllowed}
                          swappedByUser={swappedByUser}
                          swappedPrivateByUser={swappedPrivateByUser}
                          swappedPublicByUser={swappedPublicByUser}
                          balance={balance}
                          fundsDecimals={fundsDecimals}
                          allowance={allowance}
                          whiteListStatus={whiteListStatus}
                          projectId={project.id}
                          fundToken={project.presale.fund_token}
                          isPrivatePhaseInProgress={isPrivatePresaleInProgress}
                          isPublicPhaseInProgress={isPublicPresaleInProgress}
                          isClosePeriod={isClosePeriod}
                          onApprove={handleApprove}
                          onDeposit={depositMethod}
                          onDepositPrivate={depositPrivateMethod}
                        />
                        : <WrongNetwork projectNetwork={projectNetwork} />
                    )}
                  {!!project
                    && isExternalPresale
                    && <ExternalDescription
                      project={project}
                      nftCampaign={isNFTCampaign}
                      presaleInProgress={isPresaleInProgress}
                      nftClaim={isNFTClaim}
                    />
                  }
                  {!!project
                    && statusMessage === ProjectStatusesProps.Closed
                    && whiteListStatus === WhitelistStatuses.passed
                    && <ClaimSection
                      projectNetworkSelected={isProjectNetworkSelected}
                      externalPresale={isExternalPresale}
                      project={project}
                      projectNetwork={projectNetwork}
                    />}
                </Col>
              </Row>
            }
          </Container>
        </section>
      </LoadingWrap>
    </div>
  )
}
