import { ContractAddress } from '../address';
import { NotifyTxCallbacks } from '../notify';
import { useCallback, useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import { useMultipleVestingContracts } from './useContracts';
import { sendExceptionReport } from '@utils/errors';
import { useTransactions } from './useTransactions';
import { useIsMounted } from '@hooks/useIsMounted';
import { BlockNumber, TransactionReceipt } from 'web3-core';

export interface UseVestingReturns {
  isClaiming: boolean
  totalVested: BigNumber
  unvested: BigNumber
  claimed: BigNumber
  withdrawable: BigNumber
  participants: number
  withdraw: () => void
}

export interface VestingRecipientsResponse {
  totalAmount: string
  amountWithdrawn: string
}

interface MultipleVestingPromiseReturns {
  vestedSum: BigNumber
  claimedSum: BigNumber
  withdrawableSum: BigNumber
  participantsCount: number
}

export const useMultipleVesting = (contractAddresses: ContractAddress[]): UseVestingReturns => {
  const isMountedRef = useIsMounted()
  const { callTransaction, sendTransaction } = useTransactions()
  const { account } = useWeb3React()
  const vestingContracts = useMultipleVestingContracts(contractAddresses)

  const [loading, setLoading] = useState(false)
  const [blockNumber, setBlockNumber] = useState<BlockNumber>('latest')
  const [totalVested, setVested] = useState<BigNumber>(new BigNumber(0))
  const [claimed, setClaimed] = useState<BigNumber>(new BigNumber(0))
  const [withdrawable, setWithdrawable] = useState<BigNumber>(new BigNumber(0))
  const [participants, setParticipants] = useState(0)

  const unvested = useMemo(() => {
    return totalVested.minus(claimed).minus(withdrawable)
  }, [totalVested, claimed, withdrawable])

  const resetVestingInfo = () => {
    setVested(new BigNumber(0))
    setClaimed(new BigNumber(0))
    setWithdrawable(new BigNumber(0))
    setParticipants(0)
  }

  const getVestingPromise = async (blockNumber: BlockNumber): Promise<MultipleVestingPromiseReturns> => {
    let vestedSum = new BigNumber(0)
    let claimedSum = new BigNumber(0)
    let withdrawableSum = new BigNumber(0)
    let participantsCount = 0

    await Promise.all([
      callTransaction(vestingContracts[0].methods.participantCount(), blockNumber)
        .then(result => {
          participantsCount = +result;
        }),
      ...vestingContracts.map((contract) => {
        return callTransaction(contract.methods.recipients(account), blockNumber)
          .then(result => {
            vestedSum = vestedSum.plus(new BigNumber(result.totalAmount));
            claimedSum = claimedSum.plus(new BigNumber(result.amountWithdrawn));
          });
      }),
      ...vestingContracts.map((contract) => {
        return callTransaction(contract.methods.withdrawable(account), blockNumber)
          .then(result => {
            withdrawableSum = withdrawableSum.plus(new BigNumber(result));
          });
      })
    ]);

    return {
      vestedSum,
      claimedSum,
      withdrawableSum,
      participantsCount
    }
  }

  const getVestingInfo = useCallback(async () => {
    if (!account || !vestingContracts.length){
      resetVestingInfo()
      return
    }

    try {
      const {
        vestedSum,
        claimedSum,
        withdrawableSum,
        participantsCount,
      } = await getVestingPromise(blockNumber)

      if (isMountedRef.current) {
        setVested(vestedSum)
        setClaimed(claimedSum)
        setWithdrawable(withdrawableSum)
        setParticipants(participantsCount)
      }
    } catch (err) {
      sendExceptionReport(err)
      isMountedRef.current && resetVestingInfo()
    }
  }, [isMountedRef, account, vestingContracts, blockNumber])

  useEffect(() => {
    if (!loading) {
      getVestingInfo()
    }
  }, [loading, vestingContracts, blockNumber, account])

  const withdraw = useCallback(async (
    callbacks: NotifyTxCallbacks = {}
  ) => {
    if (!account || !vestingContracts.length) {
      return
    }

    for (let contract of vestingContracts) {
      setLoading(true)

      try {
        const withdrawableAmount = await callTransaction(
          contract.methods.withdrawable(account),
          'latest'
        )

        if (new BigNumber(withdrawableAmount).isGreaterThan(new BigNumber(0))) {
          const receipt = await sendTransaction(
            contract.methods.withdraw(),
            callbacks,
          ) as TransactionReceipt

          setBlockNumber(receipt.blockNumber)
        }

      } finally {
        setLoading(false)
      }
    }

  }, [account, vestingContracts, sendTransaction, callTransaction])

  return {
    isClaiming: loading,
    totalVested,
    unvested,
    claimed,
    withdrawable,
    participants,
    withdraw
  }
}
