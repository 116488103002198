import React from 'react';
import './StaticPageWrapper.css';
import classNames from 'classnames';
import { Container } from 'react-bootstrap';

interface Props {
  title: string,
  showTitle: boolean
  children: React.ReactNode
}

const StaticPageWrapper = ({ title, showTitle, children }: Props) => {

  return (
    <div className={classNames({
      'page': true,
      'marketing-page': true,
      [`${title.toLowerCase().split(' ').join('-')}-page`]: true
    })}>
      <section className='marketing-content'>
        <Container className="title-container">
          {
            showTitle && (
              <h1 className='title'>
                {title}
              </h1>
            )
          }
        </Container>
        {children}
      </section>
    </div>
  )
}

StaticPageWrapper.defaultProps = {
  showTitle: true
}

export { StaticPageWrapper }
