import axios from 'axios';
import { GasPriceOptions } from './types';
import { mapGasPrice } from './mappers';

const oracleEndpoint = new URL(process.env.REACT_APP_BNBTESTNET_GASSTATION || 'https://gbsc.blockscan.com/gasapi.ashx?apikey=key&method=gasoracle')
const baseURL = oracleEndpoint.origin
const path = oracleEndpoint.pathname
const params = Object.fromEntries(oracleEndpoint.searchParams.entries())

const instance = axios.create({
  baseURL: baseURL
})

export const getBNBTGasPrice = async (): Promise<GasPriceOptions> => {
  const { data } = await instance.get(path, {
    params
  })
  return mapGasPrice(data)
}
