import React, { useMemo } from 'react';
import './ProjectStats.scss'
import { ProjectStatusesProps, ProjectStatusProp, TokenName } from '../types';
import { useTimeLeft } from '@hooks/useTimeLeft'
import { Timer } from '@components/common';
import { ProgressBar } from 'react-bootstrap';
import { isPast } from 'date-fns';
import { numberToCurrency } from '@utils/balanceFormatter';
import classNames from 'classnames';
import { isDefined } from '@utils/object';
import { IUseTimezoneFormat } from '@hooks/useTimezoneFormat';
import { formatDateToUTC } from '@utils/dates'

export interface ProjectContractProps {
  totalRewards?: number
  fundsSwapped?: number
  totalAmount?: number
  swapExchangeRate?: number
}

export interface ProjectNonContractProps {
  fundTokenName: TokenName
  rewardTokenName: TokenName
  status: ProjectStatusProp
  whitelistingOpens?: Date | null
  whitelistingCloses?: Date | null
  privateOpens?: Date | null
  privateCloses?: Date | null
  publicOpens?: Date | null
  publicCloses?: Date | null
  participants?: number
  formatDate?: IUseTimezoneFormat['formatDate']
  isExternalPresale: boolean
}

interface Props extends ProjectContractProps, ProjectNonContractProps{
  expanded?: boolean
  minAllocation?: number
  maxAllocation?: number
  isProjectNetworkSelected: boolean
}

const ProjectStats: React.FC<Props> = React.memo(({
  fundTokenName,
  rewardTokenName,
  status,
  whitelistingOpens,
  whitelistingCloses,
  privateOpens,
  privateCloses,
  publicOpens,
  publicCloses,
  totalAmount,
  fundsSwapped,
  swapExchangeRate,
  totalRewards,
  participants,
  expanded,
  minAllocation,
  maxAllocation,
  isExternalPresale,
  isProjectNetworkSelected,
  formatDate,
  children
}) => {
  const statsTitle = useMemo(() => {
    switch (status) {
      case ProjectStatusesProps['Registration Closed']:
      case ProjectStatusesProps['Registration Open']:
        return 'Starts in'
      case ProjectStatusesProps['Guaranteed Live']:
      case ProjectStatusesProps['FCFS Live']:
        return 'In Progress'
      case ProjectStatusesProps['FCFS Coming']:
        return 'Next Round'
      default:
        return status
    }
  }, [status])

  const isWhitelistingDatesDefined = useMemo(
    () => !!(whitelistingOpens && whitelistingCloses),
    [whitelistingOpens, whitelistingCloses]
  )

  const isRegistrationOver = useMemo(
    () => !!whitelistingCloses && isPast(whitelistingCloses),
    [whitelistingCloses]
  )

  const isPrivatePhaseInProgress = useMemo(
    () => status === ProjectStatusesProps['Guaranteed Live'],
    [status]
  )

  const isPublicPhaseInProgress = useMemo(
    () => status === ProjectStatusesProps['FCFS Live'],
    [status]
  )

  const isPresaleInProgress = useMemo(
    () => isPrivatePhaseInProgress || isPublicPhaseInProgress,
    [isPrivatePhaseInProgress, isPublicPhaseInProgress]
  )

  const hasPrivatePhase = useMemo(
    () => isDefined(privateOpens) && isDefined(privateCloses),
    [privateOpens, privateCloses]
  )

  const hasPublicPhase = useMemo(
    () => isDefined(publicOpens) && isDefined(publicCloses),
    [publicOpens, publicCloses]
  )

  const dateForTimer = useMemo(() => {
    if (isDefined(privateOpens) && isDefined(publicOpens) && status === ProjectStatusesProps['FCFS Coming']) {
      return publicOpens
    }
    if (isDefined(privateOpens)) {
      return privateOpens
    }
    return publicOpens
  }, [privateOpens, publicOpens, status])

  const startsIn = useTimeLeft(dateForTimer)
  const progressRange = useMemo(() => {
    if (!fundsSwapped) return 0
    if (isExternalPresale && status === ProjectStatusesProps['FCFS Live']) {
      return Math.round(
        (+new Date() - +(publicOpens ?? 0)) / (+(publicCloses ?? 0) - +(publicOpens ?? 0)) * 100
      )
    }
    return Math.round(fundsSwapped / (totalAmount ?? 1) * 100);
  }, [fundsSwapped, totalAmount, isExternalPresale, status, publicCloses, publicOpens])

  return (
    <div className={classNames('project-stats', { 'tile': expanded })}>
      {expanded && (
        <>
          {
            isPresaleInProgress && (
              <div className='project-stats__phase text-wide'>
                <span className='fw-medium main-grey-text'>Phase {isPrivatePhaseInProgress ? 1 : 2}:{' '}</span>
                <span className='fw-semibold main-yellow-text'>{isPrivatePhaseInProgress ? 'Guaranteed' : 'FCFS'}</span>
              </div>
            )
          }
          <div className='project-stats__status'>
            <h3 className="title">{statsTitle}</h3>
            {
              !!startsIn
              && status !== ProjectStatusesProps.Closed
              && !isPresaleInProgress
              && (
                <Timer duration={startsIn} />
              )
            }
          </div>
          <div className='project-stats__progress'>
            <span className="percentage">{!!fundsSwapped &&  `${progressRange}  %`}</span>
            <ProgressBar now={progressRange} />
          </div>
          {
            !isExternalPresale
            && isProjectNetworkSelected
            && status !== ProjectStatusesProps['Coming Soon']
            && !!fundsSwapped
            && !!totalAmount
            && (
              <dl className="project-stats__list info-list">
                <div>
                  <dt className="name">Total funds swapped</dt>
                  <dd className="value">{`${numberToCurrency(fundsSwapped, 0)}/${numberToCurrency(totalAmount, 0)} ${fundTokenName}`}</dd>
                </div>
                <div>
                  <dt className="name">Participants </dt>
                  <dd className='value'>{participants}</dd>
                </div>
              </dl>
            )
          }
        </>
      )}
      <dl className='project-stats__info'>
        {
          expanded
          && !isExternalPresale
          && isProjectNetworkSelected
          && status !== ProjectStatusesProps['Coming Soon']
          && !!totalRewards
          && swapExchangeRate
          && (
            <>
              <div className='stats-block'>
                <dt className="name">Total Swap Amount</dt>
                <dd className='value'>{`${numberToCurrency(totalRewards)} ${rewardTokenName}`}</dd>
              </div>
              <div className='stats-block'>
                <dt className='name'>Swap Rate</dt>
                <dd className='value'>{`1 ${rewardTokenName} = ${numberToCurrency(swapExchangeRate, 10)} ${fundTokenName}`}</dd>
              </div>
            </>
          )
        }
        {
          !!(minAllocation || maxAllocation) && (
            <>
              <div className='stats-block'>
                <dt className='name'>Min Allocation Amount</dt>
                <dd className='value'>{minAllocation ? `${numberToCurrency(minAllocation)} ${fundTokenName}` : '-'}</dd>
              </div>
              <div className='stats-block'>
                <dt className='name'>Max Allocation Amount</dt>
                <dd className='value'>{maxAllocation ? `${numberToCurrency(maxAllocation)} ${fundTokenName}` : '-'}</dd>
              </div>
            </>
          )
        }
        {
          isWhitelistingDatesDefined && !isRegistrationOver && (
            <div className={classNames('stats-block stats-block--double', {
              active: status === ProjectStatusesProps['Registration Open'],
            })}
            >
              <div className='stats-block__badge'>
                {
                  status === ProjectStatusesProps['Registration Open']
                    ? 'In progress'
                    : 'Next phase'
                }
              </div>
              <div>
                <dt className='name'>Registration Opens</dt>
                <dd className='value'>{whitelistingOpens ? formatDate!(whitelistingOpens) : 'TBA'}</dd>
              </div>
              <div className='separator'/>
              <div>
                <dt className='name'>Registration Closes</dt>
                <dd className='value'>{whitelistingCloses ? formatDate!(whitelistingCloses) : 'TBA'}</dd>
              </div>
            </div>
          )
        }
        {
          hasPrivatePhase && (
            <div className={classNames('stats-block stats-block--double', {
              active: isPrivatePhaseInProgress,
              completed: privateCloses && isPast(privateCloses)
            })}
            >
              <div className='stats-block__badge'>
                {
                  isPrivatePhaseInProgress
                    ? 'In Progress'
                    : (privateCloses && isPast(privateCloses))
                      ? 'Completed'
                      : (whitelistingOpens && isPast(whitelistingOpens))
                        ? 'Next phase' : ''
                }
              </div>
              <div>
                <dt className='name'>Guaranteed Opens</dt>
                <dd className='value'>{privateOpens ? formatDate!(privateOpens) : 'TBA'}</dd>
              </div>
              <div className='separator'/>
              <div>
                <dt className='name'>Guaranteed Closes</dt>
                <dd className='value'>{privateCloses ? formatDate!(privateCloses) : 'TBA'}</dd>
              </div>
            </div>
          )
        }
        {
          hasPublicPhase && (
            <div className={classNames('stats-block stats-block--double', {
              active: isPublicPhaseInProgress,
              completed: status === ProjectStatusesProps.Closed
            })}
            >
              <div className='stats-block__badge'>
                {
                  status === ProjectStatusesProps.Closed
                    ? 'Completed'
                    : isPublicPhaseInProgress
                      ? 'In Progress'
                      :  (privateOpens && isPast(privateOpens))
                        ? 'Next Phase' : ''
                }
              </div>
              <div>
                <dt className='name'>FCFS Opens</dt>
                <dd className='value'>{publicOpens ? formatDate!(publicOpens) : 'TBA'}</dd>
              </div>
              <div className='separator'/>
              <div>
                <dt className='name'>FCFS Closes</dt>
                <dd className='value'>{publicCloses ? formatDate!(publicCloses) : 'TBA'}</dd>
              </div>
            </div>
          )
        }
      </dl>
      {children}
    </div>
  )
})

ProjectStats.defaultProps = {
  totalAmount: 0,
  fundsSwapped: 0,
  totalRewards: 0,
  participants: 0,
  privateOpens: undefined,
  privateCloses: undefined,
  publicOpens: undefined,
  publicCloses: undefined,
  formatDate: formatDateToUTC,
  expanded: false,
}

export { ProjectStats }
