import React from 'react';
import { Web3ReactProvider } from '@web3-react/core';
import Routes from '@router/Routes';
import { StateProvider } from '@/store';
import { seoTags } from '@/seo-content';
import { SEOTags } from '@components';
import Web3 from 'web3';

const getLibrary = (provider: any, connector: any): Web3 => {
  return provider
};

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <StateProvider>
        <SEOTags {...seoTags.main} />
        <Routes />
      </StateProvider>
    </Web3ReactProvider>
  );
}

export default App;
