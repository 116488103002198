export const setTimeoutPromise = (timeout: number) => {
  return new Promise(resolve => {
    setTimeout(resolve, timeout)
  })
}

export const retry = (callback: any, maxTries = 5, tryAfter = 300): Promise<any> =>
  new Promise(async (resolve, reject) => {
    try {
      const result = await callback()
      return resolve(result)
    } catch (err) {
      if (!maxTries) {
        return reject(err)
      }
      await setTimeoutPromise(tryAfter)
      return resolve(retry(callback, maxTries - 1, tryAfter))
    }
  })

export function mapSettledToFulfilled<T>(results: PromiseSettledResult<T>[]): T[] {
  return results
    .filter(res => res.status === 'fulfilled')
    .map(res => (res as PromiseFulfilledResult<T>).value)
}
