//TODO: Remove this file after implementing responsive design
import MobileDetect from 'mobile-detect';

export const minWebsiteWidth = 991

export function detectMobile(): boolean {
  const ua = navigator.userAgent
  const detect = new MobileDetect(ua, minWebsiteWidth)
  return !!detect.mobile()
}
