import axios from 'axios';
import { sendEnvExceptionReport } from '@utils/errors';
import { GasPriceOptions } from './types';
import { NetworksIDs, defaultEnvironmentNetworkId } from '@contracts/networks';

const baseURL = process.env.REACT_APP_MUMBAI_GASSTATION
if (!baseURL && defaultEnvironmentNetworkId === NetworksIDs.maticmum) {
  sendEnvExceptionReport(new Error('REACT_APP_MUMBAI_GASSTATION variable is not specified'))
}

const instance = axios.create({
  baseURL
})

export const getMumbaiGasPrice = async (): Promise<GasPriceOptions> => {
  const { data } = await instance.get('/')
  return data
}
