import React from 'react';
import { LockPeriodInfo } from '@contracts/hooks/useMultiperiodLocking';
import { CommonTooltip } from '@components/common';
import { ReactComponent as TooltipIcon } from '@assets/tooltip-icon.svg';
import { numberToPercentage } from '@utils/balanceFormatter';
import { secondsToDays } from '@utils/dates';
import { Divider } from '@components/layout';
import { getReadablePeriod } from '../utils';
import { OverlayProps } from 'react-bootstrap';
import { SECOND } from '@constants';

interface Props {
  period: LockPeriodInfo,
  id: string,
  placement?: OverlayProps['placement']
  triggerClass?: string,
  tooltipClass?: string
}

export const LockupPeriodTooltip: React.FC<Props> = ({
  period,
  id,
  placement,
  triggerClass,
  tooltipClass,
}) => {
  return (
    <CommonTooltip
      id={id}
      placement={placement}
      triggerClass={triggerClass}
      tooltipClass={tooltipClass}
    >
      <h4 className='lockup-tooltip__title d-flex align-items-center mb-4'>
        <TooltipIcon className='me-3' />
        {getReadablePeriod(period.lockPeriod)} locking info
      </h4>
      <h6 className='lockup-tooltip__subtitle mb-3'>Terms</h6>
      <ul className='lockup-tooltip__list'>
        <li>
          <span>Early Unlock Reward Penalty</span>
          <span>{period.rewardPenalty ? numberToPercentage(period.rewardPenalty) : '–'}</span>
        </li>
        <li>
          <span>Early Unlock Principle Penalty</span>
          <span>{numberToPercentage(period.penalty)}</span>
        </li>
        <li>
          <span>Additional Penalty</span>
          <span className={'text-end'}>
            {
              period.additionalPenalty ? (
                <>
                  {numberToPercentage(period.additionalPenalty)}{' '}penalty
                  <br/>
                  <small>to principal during first {secondsToDays(period.fullPenaltyCliff / SECOND)} days</small>
                </>
              ) : '–'
            }
          </span>
        </li>
        <li>
          <span>Penalty Model</span>
          <span className="text-capitalize">{period.penaltyMode.toLowerCase()}</span>
        </li>
        <li>
          <span>Period</span>
          <span>{secondsToDays(period.lockPeriod / SECOND)}</span>
        </li>
      </ul>
      <Divider />
      <h6 className='lockup-tooltip__subtitle'>Rewards</h6>
      <ul className='lockup-tooltip__list'>
        <li><span>MWAVE Power Multiplier</span><span>{period.power}X</span></li>
        <li><span>APY</span><span>{numberToPercentage(period.apy)}</span></li>
        {
          !period.exclusiveRewards?.length && (
            <li><span>Exclusive Rewards</span><span>–</span></li>
          )
        }
      </ul>
      {
        period.exclusiveRewards?.length && (
          <>
            <h6>Exclusive Rewards:</h6>
            <ul className='lockup-tooltip__rewards-list'>
              {period.exclusiveRewards.map((reward) => (
                <li key={reward}>{reward}</li>
              ))}
            </ul>
          </>
        )
      }
    </CommonTooltip>
  )
}
