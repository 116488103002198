import { NormalizedProjectInfo } from '@components/Project/types'
import { DiscoverButton } from '@pages/Homepage/buttons'
import { Container, Row } from 'react-bootstrap'
import { RoundButton } from '@/components'
import React, { memo } from 'react'
import './HeroRow.scss'
import { buyMWAVELink, MWaveProjectId } from '@constants'
import { ReactComponent as LinkArrow } from '@assets/link-arrow.svg'

const DiscoverButtons = (props: { bannerButton: DiscoverButton }) => {
  if (!MWaveProjectId) return null
  return <>
    <RoundButton {...props.bannerButton.props} size="large" className='discover-button'>
      {props.bannerButton.text}
    </RoundButton>

    {/*TODO: temporary hidden until MWAVE deployed*/}
    {/*<RoundButton
      href={buyMWAVELink}
      color='DARK'
      size="large"
    >
      Buy MWAVE
      <LinkArrow />
    </RoundButton>*/}
  </>
}

const GreyMonthComponent = (props: { bannerButton: DiscoverButton }) =>
  <>
    <span className='main-grey-text'>0</span>
    <span>{props.bannerButton.date && props.bannerButton.date.slice(1, 2)}</span>
  </>

const DateComponent = (props: { bannerButton: DiscoverButton }) =>
  <div className='campaign-date'>
    <span className='month'> {props.bannerButton.date && props.bannerButton.date.slice(0, 1) == '0'
      ? <GreyMonthComponent bannerButton={props.bannerButton} />
      : props.bannerButton.date && props.bannerButton.date.slice(0, 2)}
    </span>
    <span className='day'>{props.bannerButton.date && props.bannerButton.date.slice(3, 5)}</span>
    <span className='year'>{props.bannerButton.date && props.bannerButton.date.slice(6, 8)}</span>
  </div>

const CampaignButton = (props: { bannerButton: DiscoverButton }) => {
  if (props.bannerButton.date === 'Apply') {
    return (
      <>
        <span className="hero-buttons__whitelist-date">
          {props.bannerButton.text}
        </span>
        <div className="hero-buttons__countdown">
          <RoundButton
            {...props.bannerButton.props}
            size="large"
          >
            Apply
          </RoundButton>
        </div>
      </>
    )
  }
  return (
    <>
      <span className="hero-buttons__whitelist-date">
        {(props.bannerButton.date === 'LIVE' || props.bannerButton.date === 'Apply' || props.bannerButton.date === 'Soon')
          ? props.bannerButton.date
          : <DateComponent bannerButton={props.bannerButton}/>}
      </span>
      <div className="hero-buttons__countdown">
        <RoundButton
          {...props.bannerButton.props}
          size="large"
        >
          {props.bannerButton.text}
        </RoundButton>
      </div>
    </>
  )
}

export const HeroRow = memo((props: {
  currentProject: NormalizedProjectInfo | null,
  featuredProjectId: string | undefined,
  bannerButton: DiscoverButton
}) =>
  <Container>
    <Row className="hero-row">
      <div className="hero-row__main">
        <div className="hero-row__text">
          <h1 className="title">
            A Launchpad for<br />Initial Dex Offerings
          </h1>
          <p className="subtitle">
            Much Launch
          </p>
        </div>
        {
          props.currentProject && props.currentProject.id === props.featuredProjectId && (
            <div className="hero-buttons">
              {props.currentProject.presale.statusMessage === 'Closed'
                ? <DiscoverButtons bannerButton={props.bannerButton} />
                : <CampaignButton bannerButton={props.bannerButton} />}
            </div>
          )
        }
      </div>
    </Row>
  </Container>
)
