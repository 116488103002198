import { useCallback, useEffect, useState } from 'react'
import { MWAVETier, MWAVETiers } from './constants';
import { useWeb3React } from '@web3-react/core';
import { getUserTier } from '@api/tiers/getUserTier'
import { IUserTier } from '@api/tiers/types'

export const tiersColors: Record<MWAVETier, string> = {
  [MWAVETiers.LOW]: '#FFCC7E',
  [MWAVETiers.MEDIUM]: '#FFCC7E',
  [MWAVETiers.HIGH]: '#FFCC7E',
  [MWAVETiers.CONVECTION]: '#FFCC7E',
  [MWAVETiers.GRILL]: '#FFCC7E',
}

export const tiersImages: Record<MWAVETier, string> = {
  [MWAVETiers.LOW]: 'tiers/low.svg',
  [MWAVETiers.MEDIUM]: 'tiers/medium.svg',
  [MWAVETiers.HIGH]: 'tiers/high.svg',
  [MWAVETiers.CONVECTION]: 'tiers/convection.svg',
  [MWAVETiers.GRILL]: 'tiers/grill.svg',
}

export interface UserTierInfo extends IUserTier {
  tierColor: string | null
  tierImage: string | null
}

export interface IUseMWAVETier {
  userTierInfo: UserTierInfo | null,
  getTier: () => Promise<void>
  loading: boolean
}

export const useMWAVETier = (): IUseMWAVETier => {
  const { account } = useWeb3React()
  const [userTierInfo, setUserTier] = useState<UserTierInfo | null>(null)
  const [loading, setLoading] = useState(false)

  const getTier = useCallback(async () => {
    if (!account) return
    setLoading(true)
    const tierInfo = await getUserTier(account)
    setUserTier(tierInfo ? {
      ...tierInfo,
      tierColor: tierInfo.tier ? tiersColors[tierInfo.tier] : null,
      tierImage: tierInfo.tier ? tiersImages[tierInfo.tier] : null
    } : null)
    setLoading(false)
  }, [account])

  useEffect(() => {
    if (account) {
      getTier()
    }
  }, [account])

  return {
    userTierInfo,
    getTier,
    loading
  }
}
