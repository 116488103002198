import { useCallback, useState } from 'react';
import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz';

export type DateTimezoneFormat = 'UTC' | 'LOCAL'

export interface IUseTimezoneFormat {
  timeZone: string
  currentFormat: DateTimezoneFormat
  formatDate: (date: Date | number) => string
  switchFormat: () => void
}

export const useTimezoneFormat = (): IUseTimezoneFormat => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const [currentFormat, setCurrentFormat] = useState<DateTimezoneFormat>('UTC')

  const switchFormat = useCallback(() => {
    setCurrentFormat(currentFormat === 'UTC' ? 'LOCAL' : 'UTC')
  }, [currentFormat])

  const formatDate = useCallback((date: Date | number) => {
    const zonedDate = currentFormat === 'UTC' ? +date - getTimezoneOffset(timeZone, date) : date
    const formatStr = currentFormat === 'UTC' ? `yyyy-MM-dd HH:mm 'UTC` : 'yyyy-MM-dd HH:mm zzz'

    return formatInTimeZone(zonedDate, timeZone, formatStr)
  }, [timeZone, currentFormat])

  return {
    timeZone,
    currentFormat,
    switchFormat,
    formatDate,
  }
}
