import { OGProperties, PageTags } from '@/seo-content/types';
import { Helmet } from 'react-helmet';
import React, { useMemo } from 'react';
import { seoTags } from '@/seo-content';

interface Props extends PageTags {
  titleTemplate?: string
  params?: Record<string, string>
}

export const SEOTags = ({
  titleTemplate,
  title,
  url,
  description,
  properties,
  params,
}: Props) => {

  const exactUrl = useMemo(() => {
    if (!params) return url
    let result = url
    const paramsKeys = url?.match(/:([^\s/]+)/g)
    for (let key in paramsKeys) {
      result = result?.replace(key, params[key.slice(1)])
    }
    return result
  }, [url, params])

  return (
    <Helmet
      titleTemplate={titleTemplate}
    >
      <title>
        {title !== seoTags.main.title ? `${seoTags.main.title} ${title}` : title}
      </title>
      <link rel="canonical" href={exactUrl} />
      <meta property={OGProperties.url} content={exactUrl} />
      {description && <meta name="description" content={description} />}
      {
        properties?.map(item => (
          <meta key={item.property} property={item.property} content={item.content} />
        ))
      }
    </Helmet>
  )
}
