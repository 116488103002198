import { ReactComponent as Polygon } from '../../assets/partners/Polygon.svg'
import { ReactComponent as Parsiq } from '../../assets/partners/parsiq.svg'
import { ReactComponent as Blockpass } from '../../assets/partners/blockpass.svg'
import { ReactComponent as Quantstamp } from '../../assets/partners/quantstamp.svg'
import { ReactComponent as SSB } from '../../assets/partners/ssb.svg'
import { ReactComponent as Chainlink } from '../../assets/partners/chainlink.svg'
import { ReactComponent as TransformGroup } from '../../assets/partners/transform-group.svg'
import { ReactComponent as Kucoin } from '../../assets/partners/kucoin.svg'
import { ReactComponent as Quickswap } from '../../assets/partners/quickswap.svg'
import { ReactComponent as CoinGecko } from '../../assets/partners/coingecko.svg'
import { LaunchpadProdAppUrl } from '@constants'
import { tokenAddresses } from '@contracts/address'

export const partners = [
  {
    name: 'Polygon',
    logo: <Polygon />
  },
  {
    name: 'Parsiq',
    logo: <Parsiq />
  },
  {
    name: 'BlockPass',
    logo: <Blockpass />
  },
  {
    name: 'Quantstamp',
    logo: <Quantstamp />
  },
  {
    name: 'Satoshi Street Bets',
    logo: <SSB />
  },
  {
    name: 'Chainlink',
    logo: <Chainlink />
  },
  {
    name: 'Transform Group',
    logo: <TransformGroup />
  },
  {
    name: 'KuCoin',
    logo: <Kucoin />
  },
  {
    name: 'QuickSwap',
    logo: <Quickswap />
  },
  {
    name: 'CoinGecko',
    logo: <CoinGecko />
  },
];

export const faqItems = [
  {
    title: 'What is Token Burn?',
    body: (
      <p>
        A token burn is done by permanently removing some tokens from circulation, thus increasing scarcity. Unlocking
        or unstacking before the 30 day mark will result in penalties. A % of penalties if burned to increase scarcity
      </p>
    )
  },
  {
    title: 'How can projects use Microwave?',
    body: (
      <p>
        The Microwave platform allows early-stage crypto projects to raise funds through our IDO launch process. In
        utilizing the Microwave Networks, projects are able to reach massive audiences and fund at an unmatched pace.
        Much success.
      </p>
    )
  },
  /*TODO: temporary hidden until MWAVE deployed*/
  /*{
    title: 'What is the $MWAVE Token Address?',
    body: (
      <p>
        The $MWAVE Token Address: {tokenAddresses.MWAVEToken}
      </p>
    )
  },*/
  /*TODO: temporary hidden until MWAVE deployed*/
  /*{
    title: 'How do I lock $MWAVE?',
    body: (
      <p>
        <b>Step 1.</b> Go to <a href={LaunchpadProdAppUrl} target="_blank" rel="noopener noreferrer">{LaunchpadProdAppUrl}</a>
        <br />
        <b>Step 2.</b> Connect your Wallet
        <br />
        <b>Step 3.</b> Go to Accounts Page
        <br />
        <b>Step 4.</b> Scroll Down to Lock Section
        <br />
        <b>Step 5.</b> Determine amount of $MWAVE to be locked and click Approve to make sure enough funds are available
        to participate.
        <br />
        <b>Step 6.</b> Once approved, you can then lock your $MWAVE with the Lock Button and establish your Tier.
      </p>
    )
  },*/
  //TODO: fill the answer when the Tiers logic is fully defined
  /*{
    title: 'What are the FireStarter Tiers?',
    body: (
      <p>
        Please leave room for Tiers.
      </p>
    )
  },*/
  {
    title: 'How do I choose a Microwave Project?',
    body: (
      <>
        <p>
          Microwave Projects is where Defi and Culture Collide. Providing backers with a diverse range of investment
          opportunities that have mainstream stay and influence.
        </p>
        <p>
          <b>Step 1.</b> Go to <a href={LaunchpadProdAppUrl} target="_blank" rel="noopener noreferrer">{LaunchpadProdAppUrl}</a>
          <br />
          <b>Step 2.</b> Connect your Wallet
          <br />
          <b>Step 3.</b> Go to Projects Page
          <br />
          <b>Step 4.</b> Select an "In Progress" Project
          <br />
          <b>Step 5.</b> Review Project's details and hit select to proceed to Whitelisting.
        </p>
      </>
    )
  },
  {
    title: 'How to Apply to Whitelist?',
    body: (
      <>
        <p>
          To ensure safety and security, Microwave implements a Whitelisting process for users wishing to invest in
          Microwave Projects. This process should be done before investing in a project. Users are required to go
          through a KYC process, provide additional details, and follow us on our Social Channels.
        </p>
        <p>
          <b>Step 1.</b> Go to <a href={LaunchpadProdAppUrl} target="_blank" rel="noopener noreferrer">{LaunchpadProdAppUrl}</a>
          <br />
          <b>Step 2.</b> Connect your Wallet
          <br />
          <b>Step 3.</b> Go to Projects Page
          <br />
          <b>Step 4.</b> Go to Upcoming Project's Page and Choose Project
          <br />
          <b>Step 5.</b> Click on Apply to Whitelist
        </p>
      </>
    )
  },
  {
    title: 'How to Swap?',
    body: (
      <>
        <p>
          Microwave facilities the peer to peer swapping of tokens that make up our fundraising rounds. Exchange the
          Projects Contribution Token, determined by protocol, for Project Reward Tokens specific to the project.
        </p>
        <p>
          <b>Step 1.</b> Go to <a href={LaunchpadProdAppUrl} target="_blank" rel="noopener noreferrer">{LaunchpadProdAppUrl}</a>
          <br />
          <b>Step 2.</b> Connect your Wallet
          <br />
          <b>Step 3.</b> Go to Projects Page
          <br />
          <b>Step 4.</b> Select an "In Progress" Project
          <br />
          <b>Step 5.</b> Locate Swap section and enter the amount you wish to contribute.
          <br />
          <b>Step 6.</b> Click on Approve to ensure enough funds are available.
          <br />
          <b>Step 7.</b> Confirm by clicking on Swap.
        </p>
      </>
    )
  },
  {
    title: 'How to Claim?',
    body: (
      <p>
        <b>Step 1.</b> Go to Microwave website
        <br />
        <b>Step 2.</b> Connect your wallet
        <br />
        <b>Step 3.</b> Go to Account Page
        <br />
        <b>Step 4.</b> Switch to My Portfolio
        <br />
        <b>Step 5.</b> Locate needed project and click on Claim
        <br />
        <b>Step 6.</b> Submit transaction in you wallet
      </p>
    )
  },
];
