import { mainPage } from './pages/main';
import { accountStaking } from './pages/staking';
import { accountPortfolio } from './pages/portfolio';
import { projects } from './pages/projects';
import { aboutUs } from './pages/aboutUs';
import { joinNetwork } from './pages/joinNetwork';
import { userAgreement } from './pages/userAgreement';
import { privacy } from './pages/privacy';
import { kycPage } from './pages/kyc';
import { addNetwork } from './pages/addNetwork';
import { faqPage } from './pages/faq';
import { accountLockupNew } from './pages/lockup-new';

export const titleTemplate = 'Microwave %s'

export const seoTags = {
  main: mainPage,
  accountLockupNew,
  accountStaking,
  accountPortfolio,
  projects,
  aboutUs,
  joinNetwork,
  userAgreement,
  privacy,
  kycPage,
  addNetwork,
  faqPage
}
