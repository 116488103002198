import { useWeb3React } from '@web3-react/core';
import { useCallback, useMemo } from 'react';
import { geyKYCStatus, updateWalletKYC } from '@api/kyc';
import { sendExceptionReport } from '@utils/errors';
import { useSelector } from '@hooks/useSelector';
import { useDispatch } from '@hooks/useDispatch';
import { ActionType, AppState } from '@store/types';

export const useKYC = () => {
  const {
    KYC,
    loading
  } = useSelector<Pick<AppState, 'loading' | 'KYC'>>(
    ({ KYC, loading }) => ({
      KYC,
      loading
    })
  )
  const dispatch = useDispatch()
  const KYCStatus = useMemo(() => KYC.status, [KYC])
  const KYCRefId = useMemo(() => KYC.referenceId, [KYC])
  const { account } = useWeb3React()

  const getKYCStatus = useCallback(async () => {
    if (!account) {
      dispatch({
        type: ActionType.SET_KYC_DATA,
        payload: {
          status: null,
          referenceId: null
        }
      })
      return
    }
    dispatch({type: ActionType.SET_LOADING})
    const data = await geyKYCStatus(account)
    if (data) {
      dispatch({
        type: ActionType.SET_KYC_DATA,
        payload: {
          status: data.kyc_status,
          referenceId: data.reference_id
        }
      })
    }
  }, [account])

  const updateKYCStatus = useCallback( async () => {
    if (account) {
      dispatch({type: ActionType.SET_LOADING})
      const data = await updateWalletKYC(account)
      if (data) {
        dispatch({
          type: ActionType.SET_KYC_DATA,
          payload: {
            status: data.kyc_status,
            referenceId: KYCRefId as string
          }
        })
      }
    } else {
      sendExceptionReport(new Error('Trying to update KYC status without connected wallet'))
    }
  }, [account, KYCRefId])

  return {
    loading,
    KYCStatus,
    KYCRefId,
    getKYCStatus,
    updateKYCStatus,
  }
}
