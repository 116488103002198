import { Form } from 'react-bootstrap'
import React from 'react'
import { ProjectLaunchType } from '@components/formfills/LaunchModal/LaunchModal'

interface Props {
  projectType: ProjectLaunchType
  handleChange: any
  handleBlur: any
}

const launchOptions = [
  { label: 'NFT Launch', value: 'nft' },
  { label: 'Token Launch', value: 'launchpad' },
  { label: 'DEX Listing', value: 'dex' }
];

export const LaunchRadio = ({ projectType, handleChange, handleBlur }: Props) =>
  <div className="launch-radio-container">
    <p>I want to launch</p>
    <div className="launch-radio">
      {launchOptions.map(item => (
        <Form.Group
          key={item.value}
          controlId={item.value}
          className={`form-group--radio ${item.value === projectType && "active"}`}
        >
          <Form.Check
            hidden
            type="radio"
            name="projectType"
            label={item.label}
            value={item.value}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Form.Group>))}
    </div>
  </div>
