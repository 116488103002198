import { OGProperties, PageTags } from '../types';
import { RoutesPaths } from '@router/constants';

const title = 'FAQ'
const url = window.location.origin + RoutesPaths.FAQ

export const faqPage: PageTags = {
  title: `| ${title}`,
  url: url,
  properties: [
    {
      property: OGProperties.title,
      content: `Microwave | ${title}`,
    },
    {
      property: OGProperties.description,
      content: 'Microwave FAQ',
    },
    {
      property: OGProperties.url,
      content: url,
    },
  ]
}
