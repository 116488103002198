import { ReactComponent as MicrowaveLogo } from '../../assets/logo.svg';
import './Mobile.scss'
import { RoundButton } from '@components'
import { MicrowaveLandingUrl } from '@constants'

export const Mobile = () =>
  <div className="mobile-screen">
    <div className="mobile-screen__header">
      <MicrowaveLogo />
    </div>
    <div className="mobile-screen__content">
      <h1 className='mobile-screen__text'>
        The mobile version is coming soon. Check out our other projects.
        <RoundButton
          href={MicrowaveLandingUrl}>
          Go To Microwave
        </RoundButton>
      </h1>
    </div>
  </div>
