export const RoutesPaths = {
  MAIN: '/',
  ACCOUNT: {
    MAIN: '/account',
    LOCKING_MULTIPERIOD: '/account/lockup-new',
    STAKING: '/account/staking',
  },
  PROJECTS: '/projects',
  PROJECT: '/projects/:id',
  WHITELIST: '/whitelist/:project_id',
  ABOUT_US: '/about-us',
  JOIN_NETWORK: '/join-our-network',
  USER_AGREEMENT: '/user-agreement',
  PRIVACY: '/privacy-policy',
  FAQ: '/faq',
  KYC_PROCESS: '/kyc-process',
  ADD_NETWORK_GUIDE: '/add-network-to-metamask',
}
