import React, { useState } from 'react';
import './GetReadyBadge.css'
import { RoundButton } from '../RoundButton';
import { LaunchModal } from '@components/formfills/LaunchModal'

export const GetReadyBadge = () => {
  const [showLaunchModal, setShowLaunchModal] = useState(false);

  return (
    <div className="tile text-center get-ready-badge">
      <div className="tile__main">
        Is your project ready<br/>
        to launch?
      </div>
      <RoundButton onClick={() => setShowLaunchModal(true)} size="large" wide>
        Apply here
      </RoundButton>
      <LaunchModal show={showLaunchModal} setShow={setShowLaunchModal}/>
    </div>
  )
}
