import React, { useCallback, useEffect } from 'react';
import { Col, Container, Row, Image } from 'react-bootstrap';
import {
  ProjectsList,
  LoadingWrap,
  GetReadyBadge,
  SEOTags,
  ProjectFilter,
  TimezoneSwitcher,
  NoProjectsTile
} from '@components';
import './Projects.scss';
import { useProjectsState } from '@contracts/hooks/useProjects';
import { seoTags } from '@/seo-content';
import { useSearchParams } from '@hooks/useSearchParams';
import { getNetworkIcon, NetworkId, supportedNetworks } from '@contracts/networks';
import PageHeading from '@components/layout/PageHeading/PageHeading'

import Banner from '@assets/banners/projects-hero-banner.jpg'
import { useTimezoneFormat } from '@hooks/useTimezoneFormat'

export const projectFilterItems = [
  ...Object.values(supportedNetworks).map(item => ({
    value: item.networkId,
    content: <>
      <Image
        width={32}
        height={32}
        src={getNetworkIcon(item)}
        roundedCircle
      />
      {' '}
      {item.userFriendlyName}
    </>
  })),
  {
    value: 'all',
    content: 'All Networks'
  },
]

export const Projects = () => {
  const {
    loading,
    liveProjects,
    upcomingProjects,
    completedProjects,
    getProjects,
    abortRequest,
  } = useProjectsState()

  const {
    query,
    replaceQuery
  } = useSearchParams()

  const {
    currentFormat,
    switchFormat,
    formatDate
  } = useTimezoneFormat()

  const changeFilter = useCallback((value: NetworkId | 'all') => {
    if (value === 'all') {
      return replaceQuery({})
    }
    replaceQuery({ chainId: value })
  }, [replaceQuery])

  const loadProjects = useCallback(async () => {
    abortRequest()
    await getProjects(query)
  }, [abortRequest, getProjects, query])

  useEffect(() => {
    loadProjects()
  }, [query])

  return (
    <div className="projects-page page">
      <SEOTags { ...seoTags.projects } />
      <PageHeading banner={Banner}>
        <PageHeading.Title>
          Projects
        </PageHeading.Title>
        <PageHeading.Subtitle>
          Explore the projects
        </PageHeading.Subtitle>
        <PageHeading.Body>
          <div className={'text-center d-flex align-items-center justify-content-center'}>
            <div className={'me-2 text-white fw-medium'}>
              Timezone
            </div>
            <TimezoneSwitcher
              name={`projects-tz-format`}
              id={`projects-tz-format`}
              value={currentFormat}
              onChange={switchFormat}
            />
          </div>
        </PageHeading.Body>
      </PageHeading>
      {/*<section className="project-top">
        <Container className="py-5">
          <div className='d-flex align-items-center justify-content-between'>
            <h1 className="title mb-0">Projects</h1>
            <Navbar>
              <ProjectFilter
                name={'Network '}
                value={query.chainId ?? 'all'}
                items={projectFilterItems}
                onChange={changeFilter}
              />
            </Navbar>
          </div>
        </Container>
      </section>*/}
      <section className="projects-live">
        <Container>
          <h2 className="title mb-5">Live Projects</h2>
          <Row className="g-4">
            <LoadingWrap loading={loading}>
              {
                liveProjects.length
                  ? <ProjectsList projects={liveProjects} formatDate={formatDate} />
                  : (
                    <Col md={{ span: 6 }}>
                      <NoProjectsTile>
                        There are no live projects yet
                      </NoProjectsTile>
                    </Col>
                  )
              }
            </LoadingWrap>
          </Row>
        </Container>
      </section>
      <section className="projects-upcoming">
        <Container>
          <h2 className="title mb-5">Upcoming Projects</h2>
          <Row className="g-4">
            <LoadingWrap loading={loading}>
              {
                upcomingProjects.length
                  ? <ProjectsList projects={upcomingProjects} formatDate={formatDate} />
                  : (
                    <Col md={{ span: 6 }}>
                      <NoProjectsTile>
                        There are no upcoming projects now
                      </NoProjectsTile>
                    </Col>
                  )
              }
            </LoadingWrap>
          </Row>
        </Container>
      </section>
      <section className="projects-completed">
        <Container>
          <h2 className="title mb-5">Completed Projects</h2>
          <Row className="g-4">
            <LoadingWrap loading={loading}>
              {
                completedProjects.length
                  ? <ProjectsList projects={completedProjects} formatDate={formatDate} />
                  : (
                    <Col md={{ span: 6 }}>
                      <NoProjectsTile>
                        No projects have been completed yet
                      </NoProjectsTile>
                    </Col>
                  )
              }
            </LoadingWrap>
          </Row>
        </Container>
      </section>
      <section>
        <GetReadyBadge />
      </section>
    </div>
  );
};
