import { AppState } from '@store/types';
import { useContext, useMemo } from 'react';
import { AppContext } from '@/store';

type SelectorCallback = (state: AppState | Partial<AppState>) => any

export const useSelector = <SELECTED>(callback: SelectorCallback): SELECTED => {
  const { state } = useContext(AppContext)

  return useMemo(() => callback(state), [state])
}
