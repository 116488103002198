import React, { useCallback, useEffect, useState } from 'react';
import { blockpassScript } from '@constants';
import { RoundButton } from '../RoundButton';
import { sendEnvExceptionReport } from '@utils/errors';
import { useWeb3React } from '@web3-react/core';
import { retry } from '@utils/promises';

const scriptId = 'blockpass-cdn'
const blockpassClientId = process.env.REACT_APP_BLOCKPASS_CLIENT_ID
const blockpassEnv = process.env.REACT_APP_BLOCKPASS_ENV

if (!blockpassClientId) {
  sendEnvExceptionReport(new Error(`BlockPass client id isn't provided`))
}

if (!blockpassEnv) {
  sendEnvExceptionReport(new Error(`BlockPass environment isn't provided`))
}

interface Props {
  refId: string
  onFinish: () => unknown | Promise<unknown>
}

export const KYCButton = ({ refId, onFinish }: Props) => {
  const { account } = useWeb3React()
  const [scriptLoaded, setScriptLoaded] = useState(false)

  useEffect(() => {
    const existingScript = document.getElementById(scriptId)

    if (existingScript) {
      setScriptLoaded(true)
    } else {
      const script = document.createElement('script')
      script.src = blockpassScript
      script.id = scriptId

      document.body.appendChild(script)

      script.onload = () => setScriptLoaded(true)
    }
  }, [refId, account])

  const loadWidget = useCallback(() => {
    // @ts-ignore
    const blockpass = new BlockpassKYCConnect(
      blockpassClientId,
      {
        env: blockpassEnv,
        refId,
        elementId: 'blockpass-kyc-connect'
      }
    )

    blockpass.startKYCConnect()

    blockpass.on('KYCConnectSuccess', async () => {
      await onFinish()
    })
  }, [scriptLoaded, refId, onFinish])

  useEffect(() => {
    if (scriptLoaded) {
      retry(loadWidget)
    }
  }, [scriptLoaded, loadWidget])

  return (
    (scriptLoaded && refId) ? <RoundButton id="blockpass-kyc-connect" size="large">Begin KYC</RoundButton> : <></>
  )
}
