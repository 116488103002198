import './ProjectsSection.scss'
import { RoutesPaths } from '@router/constants'
import { ProjectsSlider } from '@/components'
import { NormalizedProjectInfo } from '@components/Project/types'
import { memo } from 'react'

export const ProjectsSection = memo((props: { loadingProjects: boolean, projects: NormalizedProjectInfo[] }) =>
  <section className="projects-section">
    <ProjectsSlider
      title="Projects"
      projectsLink={RoutesPaths.PROJECTS}
      linkText="All Projects"
      loadingProjects={props.loadingProjects}
      projects={props.projects}
    />
  </section>
)

