import { sendExceptionReport } from '@utils/errors';
import { LaunchFormValues } from '@components/formfills/LaunchModal'
import { sharedApiInstance } from '@api/config'

export const submitLaunchForm = async (values: LaunchFormValues): Promise<any> => {
  try {
    const { data } = await sharedApiInstance.post(
      '/storeIntakeForm',
      {
        projectHash: process.env.REACT_APP_PROJECT_HASH,
        project: {
          userName: values.firstName,
          lastName: values.lastName,
          userEmail: values.email,
          userTelegram: values.telegram,
          projectType: values.projectType,
          projectName: values.projectName,
          projectWebsite: values.projectWebsite,
          projectTelegram: values.projectTelegram,
          projectTwitter: values.projectTwitter
        }
      }
    )

    return data
  } catch (err) {
    sendExceptionReport(err)
    return {
      status: 'error',
      error: err
    }
  }
}

