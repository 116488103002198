import React, { useCallback, useState } from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import {
  checkIsMultipleNetworks,
  getNetworkIcon,
  NetworkId,
  NetworkInfo,
  supportedNetworks
} from '@contracts/networks';
import './NetworkSwitcher.css'
import classNames from 'classnames';
import { useNetwork } from '@hooks/useNetwork';
import { RoundButton } from '../RoundButton';

const getNetworkContent = (network: NetworkInfo, showName = true): React.ReactNode => {
  return (
    <>
      <img
        className="network-switcher__icon"
        src={getNetworkIcon(network)}
        alt={network.icon.toUpperCase()}
      />
      {
        showName && (
          <span className="network-switcher__text">{network.userFriendlyName}</span>
        )
      }
    </>
  )
}

export const NetworkSwitcher: React.FC = ({ children }) => {
  const [switching, setSwitching] = useState(false)
  const {
    currentNetwork,
    checkIfSelected,
    switchNetwork,
  } = useNetwork()

  const handleSwitch = useCallback(async (id: NetworkId) => {
    setSwitching(true)
    await switchNetwork(id)
    setSwitching(false)
  }, [switchNetwork])

  return (
    <Dropdown
      as={ButtonGroup}
      className={classNames({
        'network-switcher__dropdown': true,
        disabled: switching,
        single: Object.values(supportedNetworks).length === 1
      })}
      show={checkIsMultipleNetworks()}
    >
      <Dropdown.Toggle
        as={RoundButton}
        split={!!children}
        color="DARK"
        id="network-switcher-split"
        className={classNames({
          'network-switcher__toggle': true,
          disabled: !currentNetwork,
          'small': true,
        })}
      >
        {currentNetwork ? getNetworkContent(currentNetwork, !children) : '--'}
      </Dropdown.Toggle>
      <Dropdown.Menu className="network-switcher__menu nav-dropdown__menu">
        {
          Object.values(supportedNetworks).map((network: NetworkInfo) => (
            <Dropdown.Item
              key={network.networkId}
              className={classNames({
                'network-switcher__item': true,
                'nav-dropdown__item': true,
                'selected': checkIfSelected(network.networkId)
              })}
              onClick={() => handleSwitch(network.networkId)}
              as={'div'}
            >
              {getNetworkContent(network)}
            </Dropdown.Item>
          ))
        }
      </Dropdown.Menu>
      {!!children && children}
    </Dropdown>
  )
}
