import React from 'react';
import classNames from 'classnames';
import './Divider.scss'

type DividerDirection = 'horizontal' | 'vertical'

interface Props {
  className?: string
  direction?: DividerDirection
}

const Divider: React.FC<Props> = ({
  direction,
  className
}) => {

  return (
    <hr
      className={classNames({
        'divider': true,
        [`divider--${direction}`]: direction,
        className
      })}
    />
  )
}

Divider.defaultProps = {
  direction: 'horizontal'
}

export { Divider }
