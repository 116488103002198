import {
  AdvantagesSection,
  FaqSection,
  FeaturesSection,
  GetReadySection,
  HeroRow,
  HomepageBanner,
  ProjectsSection,
  TiersSection
} from '@components';
import { NormalizedProjectInfo, ProjectStatusesProps } from '@components/Project/types';
import React, { useEffect, useMemo } from 'react';
import './Homepage.scss';
import { useProjectsState } from '@contracts/hooks/useProjects';
import { usePlatformSettings } from '@hooks/usePlatformSettings';
import {
  closedButton,
  comingSoonButton,
  defaultButton,
  DiscoverButton,
  DiscoverButtonProps,
  fcfsLiveButton,
  guaranteedLiveButton,
  regClosedButton,
  regOpenButton
} from '@pages/Homepage/buttons'
import PageHeading from '@components/layout/PageHeading/PageHeading'
import Banner from '@assets/background.png'


function getProjectPriority(project: NormalizedProjectInfo): number {
  switch (project.presale.statusMessage) {
    case ProjectStatusesProps.Closed: return 1
    case ProjectStatusesProps['Coming Soon']: return 2
    case ProjectStatusesProps['Registration Closed']: return 3
    case ProjectStatusesProps['Registration Open']: return 4
    case ProjectStatusesProps['FCFS Live']:
    case ProjectStatusesProps['Guaranteed Live']: return 5
    default: return 1
  }
}

export const Homepage = () => {
  const { settings } = usePlatformSettings()
  const featuredProjectId = useMemo(() => settings?.featuredProjectId, [settings])

  const {
    loading,
    featuredProjects,
    currentProject,
    getProjectById,
    getProjects
  } = useProjectsState()

  const projectsForSlider = useMemo(() => {
    return [
      ...((currentProject && currentProject?.id === featuredProjectId) ? [currentProject] : []),
      ...featuredProjects.filter(p => p.id !== featuredProjectId)
    ].sort((a, b) => {
      return getProjectPriority(b) - getProjectPriority(a)
    })
  }, [currentProject, featuredProjects, featuredProjectId])

  useEffect(() => {
    getProjects()
  }, [])

  useEffect(() => {
    if (featuredProjectId && currentProject?.id !== featuredProjectId) {
      getProjectById(featuredProjectId)
    }
  }, [currentProject, featuredProjectId])

  const discoverButton = useMemo((): DiscoverButton => {
    const buttonProps: DiscoverButtonProps = { currentProject, featuredProjectId };
    switch (currentProject?.presale.statusMessage || ProjectStatusesProps['Coming Soon']) {
      case ProjectStatusesProps['Coming Soon']:
        return comingSoonButton(buttonProps)
      case ProjectStatusesProps['Registration Open']:
        return regOpenButton(buttonProps)
      case ProjectStatusesProps['Registration Closed']:
        return regClosedButton(buttonProps)
      case ProjectStatusesProps['Guaranteed Live']:
        return guaranteedLiveButton(buttonProps)
      case ProjectStatusesProps['FCFS Live']:
        return fcfsLiveButton(buttonProps)
      case ProjectStatusesProps.Closed :
        return closedButton(buttonProps)
      default:
        return defaultButton()
    }
  }, [currentProject])

  return (
    <div className="homepage page">
      <div className="main-background">
        <div className='main-background__shadow-top' />
        <section className="top-banner">
          <PageHeading banner={Banner}>
            <HomepageBanner/>
            <HeroRow
              currentProject={currentProject}
              featuredProjectId={featuredProjectId}
              bannerButton={discoverButton}
            />
          </PageHeading>
        </section>
      </div>
      {/*TODO: temporary hidden until MWAVE deployed*/}
      {/*<FeaturesSection />*/}
      {!!projectsForSlider.length && (
        <ProjectsSection
          loadingProjects={loading}
          projects={projectsForSlider}
        />
      )}
      <AdvantagesSection />
      <GetReadySection />
      <TiersSection />
      <FaqSection />
    </div>
  );
};
