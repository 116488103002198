import { Link } from 'react-router-dom'
import React from 'react'

export interface CustomLink {
  to?: string
  href?: string,
  className?: string,
  children: React.ReactNode
}

export const CustomLink = (props: CustomLink) => (
  <>
    {!!props.to
      ?
      <Link to={props.to} className={`${props.className} custom-link`}>{props.children}</Link>
      : <a
        href={props.href}
        target="_blank"
        rel="noopener noreferrer"
        className={`${props.className} custom-link`}
      >
        {props.children}
      </a>}
  </>
)
