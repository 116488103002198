export enum OGProperties {
  type = 'og:type',
  url = 'og:url',
  title = 'og:title',
  image = 'og:image',
  imageWidth = 'og:image:width',
  imageHeight = 'og:image:height',
  description = 'og:description',
}

export interface OpenGraphProperty {
  property: OGProperties[number]
  content: string
}

export interface PageTags {
  title?: string
  description?: string
  url?: string
  properties?: OpenGraphProperty[]
}
