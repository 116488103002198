import { format } from 'date-fns'
import { projectGetters } from '@contracts/getters/projectGetters'
import { MWaveProjectId } from '@constants'
import { StateCurrentProject } from '@store/types'

export interface DiscoverButton {
  date: string | null | undefined
  text: string
  props: {
    to?: string
    disabled?: boolean
  }
}

export interface DiscoverButtonProps {
  currentProject?: StateCurrentProject;
  featuredProjectId?: string | undefined;
}

const formatDate = (date: Date | undefined | null): string =>
  date ? format(date, 'MM/dd/yy') : 'Soon'

const startDate = (currentProject: StateCurrentProject | undefined): string =>
  formatDate(currentProject?.presale.private_starts_at ?? currentProject?.presale.public_starts_at);

const isNFTClaim = (currentProject: StateCurrentProject | undefined): boolean =>
  !!currentProject && projectGetters.getIsNFTClaimProject(currentProject)

export const defaultButton = (): DiscoverButton => ({
  date: null,
  text: 'Discover',
  props: { to: `/projects/${MWaveProjectId}` }
});

export const fcfsLiveButton = (db: DiscoverButtonProps): DiscoverButton => ({
  ...defaultButton(),
  date: 'LIVE',
  props: { to: `/projects/${db.featuredProjectId}` }
})

export const regClosedButton = (db: DiscoverButtonProps): DiscoverButton => ({
  ...defaultButton(),
  text: `${db.currentProject?.name}`,
  date: startDate(db.currentProject)
})

export const guaranteedLiveButton = (db: DiscoverButtonProps): DiscoverButton => ({
  ...defaultButton(),
  text: `${db.currentProject?.name} ${isNFTClaim(db.currentProject) ? 'Claim' : 'Pre-sale'}`,
  props: { to: `/projects/${db.featuredProjectId}` }
})

export const closedButton = (db: DiscoverButtonProps): DiscoverButton => ({
  ...defaultButton(),
  props: { to: `/projects` }
})

export const regOpenButton = (db: DiscoverButtonProps): DiscoverButton => ({
  ...defaultButton(),
  date: 'Apply',
  text: `${db.currentProject?.name} Whitelist`,
  props: { to: `/whitelist/${db.featuredProjectId}` }
});

export const comingSoonButton = (db: DiscoverButtonProps): DiscoverButton => ({
  ...defaultButton(),
  date: formatDate(db.currentProject?.whitelisting.starts_at),
  text: `${db.currentProject?.name} Whitelist`,
  props: { disabled: true }
})
