import { sendEnvExceptionReport } from '@utils/errors';

const polygonPrivateKey = process.env.REACT_APP_POLYGON_RPC_PRIVATE_KEY;

export enum NetworksIDs {
  matic = '137',
  maticmum = '80001',
  eth = '1',
  rin = '4',
  bnb = '56',
  bnbt = '97',
  solana = 'sol-101',
  solanadev = 'sol-103',
  doge = '2000',
  dogetest = '568'
}

export enum NetworkNames {
  matic = 'Matic Mainnet',
  maticmum = 'Matic Testnet Mumbai',
  eth = 'Ethereum Mainnet',
  rin = 'Ethereum Testnet Rinkeby',
  bnb = 'Binance Smart Chain Mainnet',
  bnbt = 'Binance Smart Chain Testnet',
  solana = 'Solana Mainnet',
  solanadev = 'Solana Devnet',
  doge = 'Dogechain',
  dogetest = 'Dogechain Testnet',
}

export enum NetworkUserFriendlyNames {
  matic = 'Polygon',
  maticmum = 'Mumbai',
  eth = 'Ethereum',
  rin = 'Rinkeby',
  bnb = 'BSC',
  bnbt = 'BSC Testnet',
  solana = 'Solana',
  solanadev = 'Solana Devnet',
  doge = 'Dogechain',
  dogetest = 'Dogechain Testnet',
}

export type NetworkName = NetworkNames[number];
export type NetworkId = NetworksIDs[number];
export type NetworkUserFriendlyName = NetworkUserFriendlyNames[number];

export interface NetworkCurrency {
  name: string;
  symbol: string;
  decimals: number;
}

export interface NetworkInfo {
  name: NetworkName;
  icon: string;
  userFriendlyName: NetworkUserFriendlyName;
  networkId: NetworkId;
  nativeCurrency: NetworkCurrency;
  rpcUrl: string;
  privateRPC?: string;
  explorerUrl: string;
  production: boolean;
  isActive: boolean;
  color: string;
}

export type NetworksList = Record<NetworkId, NetworkInfo>;

export const networks: NetworksList = {
  [NetworksIDs.maticmum]: {
    name: NetworkNames.maticmum,
    icon: 'polygon',
    userFriendlyName: NetworkUserFriendlyNames.maticmum,
    networkId: NetworksIDs.maticmum,
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18
    },
    rpcUrl: 'https://rpc-mumbai.maticvigil.com/',
    explorerUrl: 'https://mumbai.polygonscan.com/',
    production: false,
    isActive: false,
    color: '#8248e5',
  },
  [NetworksIDs.matic]: {
    name: NetworkNames.matic,
    icon: 'polygon',
    userFriendlyName: NetworkUserFriendlyNames.matic,
    networkId: NetworksIDs.matic,
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18
    },
    rpcUrl: process.env.REACT_APP_POLYGON_RPC_URL ?? 'https://polygon-rpc.com/',
    // rpcUrl: 'https://rpc-mainnet.maticvigil.com/',
    privateRPC: polygonPrivateKey
      ? `https://polygon-mainnet.g.alchemy.com/v2/${polygonPrivateKey}`
      : '',
    explorerUrl: 'https://polygonscan.com/',
    production: true,
    isActive: false,
    color: '#8248e5'
  },
  [NetworksIDs.eth]: {
    name: NetworkNames.eth,
    icon: 'eth',
    userFriendlyName: NetworkUserFriendlyNames.eth,
    networkId: NetworksIDs.eth,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrl: process.env.REACT_APP_ETH_RPC_URL ?? 'https://main-light.eth.linkpool.io/',
    explorerUrl: 'https://etherscan.io',
    production: true,
    isActive: false,
    color: '#627EEA'
  },
  [NetworksIDs.rin]: {
    name: NetworkNames.rin,
    icon: 'rinkeby',
    userFriendlyName: NetworkUserFriendlyNames.rin,
    networkId: NetworksIDs.rin,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrl: 'https://rinkeby-light.eth.linkpool.io/',
    explorerUrl: 'https://rinkeby.etherscan.io',
    production: false,
    isActive: false,
    color: '#686868'
  },
  [NetworksIDs.bnb]: {
    name: NetworkNames.bnb,
    icon: 'bsc',
    userFriendlyName: NetworkUserFriendlyNames.bnb,
    networkId: NetworksIDs.bnb,
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 8
    },
    rpcUrl: process.env.REACT_APP_BNB_RPC_URL ?? 'https://bsc-dataseed.binance.org/',
    explorerUrl: 'https://bscscan.com',
    production: true,
    isActive: false,
    color: '#F8D12F'
  },
  [NetworksIDs.bnbt]: {
    name: NetworkNames.bnbt,
    icon: 'bsc',
    userFriendlyName: NetworkUserFriendlyNames.bnbt,
    networkId: NetworksIDs.bnbt,
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 8
    },
    rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
    explorerUrl: 'https://testnet.bscscan.com/',
    production: false,
    isActive: false,
    color: '#F8D12F'
  },
  [NetworksIDs.solana]: {
    name: NetworkNames.solana,
    icon: 'solana',
    userFriendlyName: NetworkUserFriendlyNames.solana,
    networkId: NetworksIDs.solana,
    nativeCurrency: {
      name: 'SOL',
      symbol: 'SOL',
      decimals: 9
    },
    rpcUrl: 'https://solana-mainnet.phantom.tech',
    explorerUrl: 'https://solscan.io',
    production: true,
    isActive: false,
    color: '#DC1FFF'
  },
  [NetworksIDs.solanadev]: {
    name: NetworkNames.solanadev,
    icon: 'solana',
    userFriendlyName: NetworkUserFriendlyNames.solanadev,
    networkId: NetworksIDs.solanadev,
    nativeCurrency: {
      name: 'SOL',
      symbol: 'SOL',
      decimals: 9
    },
    rpcUrl: 'https://api.devnet.solana.com',
    explorerUrl: 'https://solscan.io',
    production: false,
    isActive: false,
    color: '#DC1FFF'
  },
  [NetworksIDs.doge]: {
    name: NetworkNames.doge,
    icon: 'dogechain',
    userFriendlyName: NetworkUserFriendlyNames.doge,
    networkId: NetworksIDs.doge,
    nativeCurrency: {
      name: 'wDOGE',
      symbol: 'wDOGE',
      decimals: 9,
    },
    rpcUrl: process.env.REACT_APP_DOGE_RPC_URL ?? 'https://dogechain.ankr.com/',
    explorerUrl: 'https://explorer.dogechain.dog',
    production: true,
    isActive: true,
    color: '#8247e5'
  },
  [NetworksIDs.dogetest]: {
    name: NetworkNames.dogetest,
    icon: 'dogetestnet',
    userFriendlyName: NetworkUserFriendlyNames.dogetest,
    networkId: NetworksIDs.dogetest,
    nativeCurrency: {
      name: 'wDOGE',
      symbol: 'wDOGE',
      decimals: 9,
    },
    rpcUrl: process.env.REACT_APP_DOGETEST_RPC_URL ?? 'https://rpc-testnet.dogechain.dog/',
    explorerUrl: 'https://explorer-testnet.dogechain.dog/',
    production: false,
    isActive: false,
    color: '#8247e5'
  },
};

export const getNetworkIcon = (network: NetworkInfo): string => {
  return `/networks/${network.icon}.png`
}

export const checkIsMerkleWhitelisting = (networkId: NetworkId): boolean => {
  return [
    NetworksIDs.eth,
    NetworksIDs.rin,
    NetworksIDs.matic,
    NetworksIDs.maticmum,
    NetworksIDs.bnb,
    NetworksIDs.bnbt,
    NetworksIDs.doge,
    NetworksIDs.dogetest,
  ].includes(networkId as NetworksIDs)
}

export const defaultEnvironmentNetworkId = process.env
  .REACT_APP_SELECTED_NETWORK_ID as NetworkId;
export const defaultEnvironmentNetworkData = networks[defaultEnvironmentNetworkId];
export const defaultEnvironmentNetworkName = networks[defaultEnvironmentNetworkId].name;
if (!defaultEnvironmentNetworkId) {
  sendEnvExceptionReport(new Error(`Selected network ID isn't provided`));
}

export const supportedNetworks: Record<NetworkId, NetworkInfo> = Object.entries(networks).reduce(
  (acc, [id, network]) => {
    if (!network.isActive) {
      return acc
    }
    if (process.env.REACT_APP_ENV === 'production' && !network.production) {
      return acc;
    }
    if (process.env.REACT_APP_ENV !== 'production' && network.networkId === NetworksIDs.solana) {
      return acc
    }

    acc[id] = networks[id];
    return acc;
  },
  {} as Record<NetworkId, NetworkInfo>
);

export const checkIsMultipleNetworks = () => Object.keys(supportedNetworks).length > 1
