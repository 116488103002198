import React from 'react';
import { DateTimezoneFormat } from '@hooks/useTimezoneFormat';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import './TimezoneSwitcher.scss'

interface Props {
  className?: string
  name: string
  id: string
  value: DateTimezoneFormat
  onChange: (value: any, event?: any) => void
}

const timezoneOptions: DateTimezoneFormat[] = ['UTC', 'LOCAL']

export const TimezoneSwitcher: React.FC<Props> = ({
  className,
  name,
  id,
  value,
  onChange,
}) => {
  return (
    <ToggleButtonGroup
      className={`timezone-switcher ${className ?? ''}`}
      type={'radio'}
      name={name}
      value={value}
      onChange={onChange}
    >
      {
        timezoneOptions.map((option) => (
          <ToggleButton
            bsPrefix={'timezone-option'}
            id={`${id}-${option}`}
            key={option}
            value={option}
          >
            {option === 'LOCAL' ? option.toLowerCase() : option}
          </ToggleButton>
        ))
      }
    </ToggleButtonGroup>
  )
}
