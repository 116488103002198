import { InjectedConnector } from '@web3-react/injected-connector';
import { defaultEnvironmentNetworkId, networks, supportedNetworks } from './networks';

const networkId = +networks[defaultEnvironmentNetworkId].networkId

const supportedNetworkIds = Object.values(supportedNetworks).map(network => parseInt(network.networkId))

if (!networkId) {
  throw new Error('No network selected')
}

export enum ConnectorNames {
  MetaMask = 'MetaMask'
}

type ConnectorByName = {
  [connectorName in ConnectorNames]: any;
};

export const MetaMask = new InjectedConnector({
  supportedChainIds: supportedNetworkIds,
});

export const connectorsByName: ConnectorByName = {
  [ConnectorNames.MetaMask]: MetaMask
};
