import { WalletAddress } from '@contracts/address'
import { IUserTier, UserTierResponse } from '@api/tiers/types'
import { instance as API } from '@api/config'
import { mapTierResponse } from '@api/tiers/mapping'
import { sendExceptionReport } from '@utils/errors'

export const getUserTier = async (wallet_address: WalletAddress): Promise<IUserTier | null> => {
  try {
    const { data } = await API.get<UserTierResponse>('/get-user-tier', {
      params: {
        wallet_address
      }
    })

    return mapTierResponse(data)
  } catch (err) {
    sendExceptionReport(err)
    return null
  }
}
