import { NetworkInfo } from '@contracts/networks'
import { LoadingWrap, ProjectBio, RoundButton, WrongNetworkBlock } from '@components'
import { NormalizedProjectInfo, ProjectStatusesProps, ProjectStatusProp } from '@components/Project/types'
import { Col, Image } from 'react-bootstrap'
import { ReactComponent as LinkArrow } from '@assets/link-arrow.svg'
import { RoutesPaths } from '@router/constants'
import { WhitelistStatuses } from '@contracts/hooks/useWhitelist'
import { generatePath } from 'react-router-dom'
import classNames from 'classnames'
import React from 'react'

export const WrongNetwork = (props: { projectNetwork: NetworkInfo | null }) =>
  <section className="swap-section swap-section--wrong-network">
    <div className="tile">
      <h3 className="title">
        Participate in the project
      </h3>
      <WrongNetworkBlock
        prefix={'To participate in the project'}
        expectedNetwork={props.projectNetwork!}
        embedded={false}
      />
    </div>
  </section>


export const ExternalDescription = (props: {
  project: NormalizedProjectInfo,
  nftCampaign: boolean,
  presaleInProgress: boolean,
  nftClaim: boolean
}) =>
  <section className="external-description-section mt-5">
    <Col>
      {
        props.project.info.bio && <ProjectBio {...props.project.info.bio} />
      }
    </Col>
    <Col xs={{ span: 12 }} xl={{ span: 6 }} className="mt-5 mt-xl-0">
      {
        props.nftCampaign && props.project.assets.nft_image_url && (
          <div className="external-description__nft">
            <div className="external-description__nft-image">
              <Image src={props.project.assets.nft_image_url} />
            </div>
            {
              props.project.info.subtitle && (
                <h2 className="external-description__nft-subtitle mt-3">
                  {props.project.info.subtitle}
                </h2>
              )
            }
            {
              props.presaleInProgress && (
                <RoundButton
                  href={props.project.presale.external_presale_link}
                  className="mt-3"
                  size="large"
                >
                  Proceed to {props.project.name} {props.nftClaim ? 'Claim' : 'Pre-sale'} <LinkArrow />
                </RoundButton>
              )
            }
          </div>
        )
      }
    </Col>
  </section>

export const ClaimSection = (props: {
  projectNetworkSelected: boolean,
  externalPresale: boolean,
  project: NormalizedProjectInfo,
  projectNetwork: NetworkInfo | null
}) =>
  <section className="claim-section">
    <div className="tile">
      <h3 className="title">
        Claim your tokens
      </h3>
      {
        props.projectNetworkSelected ? (
          <RoundButton
            {
              ...(props.externalPresale ? {
                href: props.project.presale.external_presale_link
              } : {
                to: RoutesPaths.ACCOUNT.MAIN
              })
            }
            size="large"
            wide
          >
            Claim now
          </RoundButton>
        ) : (
          <WrongNetworkBlock
            prefix={'To claim your tokens'}
            expectedNetwork={props.projectNetwork!}
            embedded={false}
          />
        )
      }
    </div>
  </section>

export const WhitelistBlock = (props: {
  whiteListStatus: 'not_submitted' | 'in_review' | 'rejected' | 'passed' | null,
  statusMessage: ProjectStatusProp,
  projectId: string,
  whitelistStatusMessage: string
}) =>
  <div className="whitelist-block">
    <LoadingWrap loading={!props.whiteListStatus}>
      {
        (
          props.statusMessage === ProjectStatusesProps['Registration Open'] &&
          props.whiteListStatus === WhitelistStatuses.not_submitted
        ) ? (
          <RoundButton to={generatePath(RoutesPaths.WHITELIST, { project_id: props.projectId })} size="large">
            Apply to whitelist
          </RoundButton>
        ) : (
          <div
            className={classNames('whitelist-badge', {
              [`${props.whiteListStatus}`]: !!props.whiteListStatus,
              'invalid': !props.whiteListStatus
            })}
          >
            <span>
              {props.whitelistStatusMessage}
            </span>
          </div>
        )
      }
    </LoadingWrap>
  </div>

export const ClaimButton = (props: { project: NormalizedProjectInfo, nftClaim: boolean }) =>
  <RoundButton
    href={props.project.presale.external_presale_link}
    className="mt-5"
    size="large"
  >
    Proceed to {props.project.name} {props.nftClaim ? 'Claim' : 'Pre-sale'} <LinkArrow />
  </RoundButton>
