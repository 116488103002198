import { useEffect, useState, useRef, useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import Web3 from 'web3';
import { web3NoAccountInstances } from '@utils/web3';
import { defaultEnvironmentNetworkId } from '@contracts/networks';

export const useWeb3 = () => {
  const { library, chainId } = useWeb3React();
  const web3NoAccount = useMemo(
    () => web3NoAccountInstances[chainId ? chainId.toString() : defaultEnvironmentNetworkId],
    [chainId]
  )
  const refChainId = useRef(chainId)
  const refWeb3 = useRef(library);
  const [web3, setweb3] = useState(library ? new Web3(library) : web3NoAccount);

  useEffect(() => {
    if (library !== refWeb3.current || chainId !== refChainId.current) {
      setweb3(library ? new Web3(library) : web3NoAccount);
      refWeb3.current = library;
      refChainId.current = chainId
    }
  }, [chainId, library, web3NoAccount]);

  return web3;
};
