import React, { useState } from 'react';
import { RoundButton, Spinner } from '@components/common';
import { Row, Col, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup'

export interface JoinFormValues {
  email: string
}

const initialValues: JoinFormValues = {
  email: '',
}

const validationSchema = yup.object().shape({
  email: yup.string().email('Wrong format of email').required('Type your email to contact'),
})

interface Props {
  onSubmit: (formData: JoinFormValues) => Promise<unknown> | unknown
}

export const JoinForm = ({ onSubmit }: Props) => {
  const [loading, setLoading] = useState(false)

  const onSubmitHandler = async (
    values: JoinFormValues,
  ) => {
    if (loading) {
      return
    }

    setLoading(true)
    await onSubmit(values)
    setLoading(false)
  }

  const {
    errors,
    touched,
    handleSubmit,
    isValid,
    getFieldProps,
  } = useFormik({
    validateOnChange: true,
    validateOnMount: true,
    validationSchema,
    onSubmit: onSubmitHandler,
    initialValues
  })

  return (
    <Form
      noValidate
      className="join-form marketing-page-form tile"
      onSubmit={handleSubmit}
    >
      <div className="marketing-page-form__inner custom-scroll">
        <h5 className="title mb-4 light-yellow-text">
          Stay up to date, join our Newsletter!
        </h5>
        <Row>
          <Col>
            <Form.Group controlId="email">
              <Form.Label>Email *</Form.Label>
              <Form.Control
                type="email"
                placeholder="youremail@mail.com"
                {...getFieldProps('email')}
                isValid={touched.email && !errors.email}
                isInvalid={touched.email && !!errors.email}
              />
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="text-center marketing-page-form__submit-row">
          <Col>
            <RoundButton
              type="submit"
              size="large"
              wide
              disabled={!isValid || loading}
            >
              {
                loading
                  ? <Spinner />
                  : 'Submit'
              }
            </RoundButton>
          </Col>
        </Row>
      </div>
    </Form>
  )
}
