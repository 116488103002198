import React, { useState } from 'react';
import { FormFillResponseView, JoinForm, JoinFormValues, SEOTags } from '@components';
import { Container } from 'react-bootstrap';
import { submitToJoinCommunity } from '@api/form-fills';
import { seoTags } from '@/seo-content';
import PageHeading from '@components/layout/PageHeading/PageHeading'
import Banner from '@assets/banners/join-network.png'
import './JoinCommunity.scss'


export const JoinCommunity = () => {
  const [formStage, setFormStage] = useState<'FILL' | 'RESPONSE'>('FILL')
  const [responseStatus, setResponseStatus] = useState<'error' | 'success' | null>(null)

  const resetForm = () => {
    setFormStage('FILL')
    setResponseStatus(null)
  }

  const submitJoining = async (values: JoinFormValues) => {
    const response = await submitToJoinCommunity(values)
    setResponseStatus(response.result)
    setFormStage('RESPONSE')
  }

  return (
    <>
      <SEOTags {...seoTags.joinNetwork} />
      <div className="page">
        <PageHeading banner={Banner} className='community'>
          <PageHeading.Title>
            Join Our Network
          </PageHeading.Title>
        </PageHeading>
        <Container className='d-flex'>
          {
            formStage === 'FILL'
              ? <JoinForm onSubmit={submitJoining} />
              : <FormFillResponseView
                status={responseStatus}
                onReset={resetForm}
              />
          }
        </Container>
      </div>
    </>
  )
}
