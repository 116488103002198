import { useEffect, useState } from 'react';
import { SECOND } from '@constants/dates';

export const useReactiveDate = (): Date => {
  const [currentDate, setCurrentDate] = useState(new Date())

  useEffect(() => {
    setTimeout(() => setCurrentDate(new Date()), SECOND)
  }, [currentDate])

  return currentDate
}
