import React, { CSSProperties, useMemo } from 'react';
import { ProjectStatus, ProjectType, ProjectTypes } from '../types';
import { Image } from 'react-bootstrap';
import classNames from 'classnames'
import './ProjectTag.scss'

enum ProjectTagTypes {
  status = 'status',
  network = 'network',
  token = 'token'
}

type ProjectTagType = keyof typeof ProjectTagTypes

type NetworkAbbreviation = 'ETH' | 'BSC' | 'POLYGON' | 'SOLANA' | 'RINKEBY'

interface Props {
  type: ProjectTagType
  value: ProjectStatus | NetworkAbbreviation | string
  projectType?: ProjectType
  style?: CSSProperties
}

export const ProjectTag = ({ type, value, projectType, style }: Props) => {
  const icon = type === 'network'
    ? `/networks/${value.toLowerCase()}.png`
    : null

  const statusClass = value.split(' ').join('-').toLowerCase()
  const tagClasses = classNames({
    'project-tag': true,
    [statusClass]: type === 'status',
    'project-tag--status': type === 'status',
    'project-tag--token': type === 'token',
    'project-tag--network': type === 'network',
  })

  const formattedValue = useMemo(() => {
    switch (projectType) {
      case ProjectTypes.NFT_claim:
        return 'NFT DROP';
      case ProjectTypes.NFT_presale:
        return 'NFT PRE-SALE';
      case ProjectTypes.token_presale:
        return value;
      default:
        return value;
    }
  }, [projectType, value])

  return (
    <span className={tagClasses} style={style}>
      {icon && <Image src={icon} className="project-tag__image" />}
      <span>
        {formattedValue}
      </span>
    </span>
  )
}
