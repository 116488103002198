import { TDocumentDefinitions, TFontDictionary } from 'pdfmake/interfaces';
import { SAFTDynamicProps } from './markup';
import { format } from 'date-fns';

export const SAFTfonts: TFontDictionary = {
  TimesNewRoman: {
    normal: 'TimesNewRomanPSMT.ttf',
    bold: 'TimesNewRomanPS-BoldMT.ttf',
    italics: 'TimesNewRomanPS-ItalicMT.ttf'
  }
}

export const createSAFTDocContent = ({
  projectName,
  companyOfficialName,
  fundTokenName,
  rewardTokenName,
  registrationData,
  exchangeRate,
  contactEmail,
  dateCreated
}: SAFTDynamicProps): TDocumentDefinitions => {
  return {
    info: {
      title: `SAFT agreement for ${projectName}`,
      subject: 'SAFT Agreement'
    },
    pageSize: {
      width: 612,
      height: 792
    },
    pageMargins: [70, 76],
    defaultStyle: {
      fontSize: 11,
      lineHeight: 1.3,
      font: 'TimesNewRoman'
    },
    styles: {
      header: {
        fontSize: 11.5,
        bold: true,
        margin: [0, 0, 0, 15]
      },
      paragraph: {
        margin: [0, 0, 0, 15],
        fontSize: 10.5,
        lineHeight: 1.2,
      },
      largeMarginParagraph: {
        margin: [0, 0, 0, 18],
        fontSize: 10.5,
        lineHeight: 1.12,
      },
      list1: {
        margin: [17, 0, 0, 25]
      },
      list2: {
        margin: [25, 0, 0, 0]
      },
      listHeading1: {
        italics: true,
      },
      listHeading2: {
        decoration: 'underline',
      },
    },
    pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
      return !!currentNode.headlineLevel && followingNodesOnPage.length === 0
    },
    content: [
      {
        text: `SIMPLE AGREEMENT FOR FUTURE TOKENS for the project “${projectName}”`,
        style: ['header'],
      },
      {
        text: `This Simple Agreement for Future Tokens (“SAFT”) as of ${dateCreated ? format(dateCreated, 'dd/MMMM/yyyy') : '__ ______ 202_'} certifies that in exchange for the payment by`,
        style: ['paragraph']
      },
      {
        text: 'YOU, a natural person, (the “Purchaser”) to',
        style: ['paragraph']
      },
      {
        text: `${registrationData} (the "Company")`,
        style: ['paragraph']
      },
      {
        text: [
          `In the amount of up to max allocation per Microwave Tier in ${fundTokenName} (the “Purchase Price”) for amount (“Purchase Amount”) of ${rewardTokenName} `,
          `cryptographic utility tokens (“Tokens”) issued by the company (Issuer) on the basis of exchange rate of ${fundTokenName} `,
          `${exchangeRate} per Token when generated (at the “Token Generation Event”) and distributed in relation to the Issuer’s`,
          'platform and/or application and/or broader ecosystem, as defined and explained in the Whitepaper and other ',
          'project documentation as made available to the Purchaser via the website (“Project”), subject to the terms set ',
          'forth below.'
        ],
        style: ['paragraph']
      },
      {
        ol: [
          [
            {
              text: 'Payment and Delivery',
              style: ['listHeading1'],
              headlineLevel: 2
            },
            {
              ol: [
                [
                  {
                    text: [
                      { text: 'Payment ', style: ['listHeading2'], headlineLevel: 3 },
                      'The Purchase Price shall be payable upon signing the SAFT during the time of the ICO ',
                      '(“Initial Coin Offering”) of the Issuer on Microwave platform by swapping the crypto ',
                      'assets in the amount of Purchase Price on the FCFS (“First come, first served”) basis.'
                    ],
                  },
                ],
                [
                  {
                    text: [
                      { text: 'Delivery ', style: ['listHeading2'], headlineLevel: 3 },
                      'Tokens purchased under this SAFT are to be delivered immediately after the ',
                      'Token Generation Event, which is expected to occur within an appropriate timeframe ',
                      'and will be announced by the Issuer in his social media channels. The Tokens are to ',
                      'be delivered to the wallet address, specified by the Purchaser to the Company before ',
                      'or upon the Token Generation Event. Notwithstanding the foregoing, the Company ',
                      'reserves the right to extend the deadline for delivery of the Tokens to the Purchaser ',
                      'for additional reasonable time, if deemed necessary to address unanticipated technical ',
                      'difficulties.'
                    ],
                  },
                ],
                [
                  {
                    text: [
                      { text: 'Vesting ', style: ['listHeading2'], headlineLevel: 3 },
                      'The Purchaser explicitly agrees and acknowledges that the Purchase Amount ',
                      'will be unlocked and made available to the Purchaser in stages, as specified in the ',
                      'Terms of Vesting as outlined by the Issuer in his tokenomics, which can be also found ',
                      `on the ${projectName} one-sheet under the Token Economics section (Tokenomics). The `,
                      'Parties specifically agree and acknowledge that the vesting schedule is of informative ',
                      'information only and that the Company reserves to change (in unilateral manner) the ',
                      'vesting terms at its sole discretion, if required for any commercial purposes of for the ',
                      'benefit of successful implementation of the Project (that need not be revealed to the ',
                      'Purchaser if of confidential nature). Notwithstanding the foregoing, the whole ',
                      'Purchase Amount shall be fully unlocked and made available to the Purchaser by the ',
                      'end of vesting period (“End Date”).'
                    ],
                  },
                ]
              ],
              style: ['list2']
            }
          ],
          [
            {
              text: 'SAFT Termination',
              style: ['listHeading1'],
              headlineLevel: 2
            },
            {
              ol: [
                [
                  {
                    text: [
                      { text: 'Termination ', style: ['listHeading2'], headlineLevel: 3 },
                      'The SAFT will expire and terminate upon: (a) the delivery of Tokens to ',
                      'the Purchaser pursuant to Section 1; or (b) upon the Company deciding to no longer ',
                      'offer any Tokens to the Purchaser or (c) the dissolution or winding-up of the ',
                      'Company or the Issuer.'
                    ]
                  }
                ],
              ],
              style: ['list2']
            }
          ],
          [
            {
              text: 'Acknowledgements and representations',
              style: ['listHeading1'],
              headlineLevel: 2
            },
            {
              ol: [
                [
                  {
                    text: [
                      { text: 'Project Development and Exclusion of Representations. ', style: ['listHeading2'], headlineLevel: 3 },
                      'The Purchaser hereby ',
                      'acknowledges and confirms that the Purchaser has received and reviewed the ',
                      'Project’s whitepaper and the website, as well as any other websites or project ',
                      'documents referenced therein) and obtained and reviewed in detail any and all other ',
                      'material, information and documents they required the Company to provide before ',
                      'this purchase (“Project Documentation”), and is therefore familiar with the Token and ',
                      'the Issuer’s Project. The Purchaser acknowledges that the Project Documentation is ',
                      'of descriptive nature only, is subject to change due to required development work, ',
                      'and does not form part of the terms of this SAFT as binding to the Issuer or the ',
                      'Company. The Purchaser is informed that if, after the Token delivery, an upgrade of ',
                      'Tokens is required and the Purchaser decided to skip this upgrade, the Tokens may ',
                      'lose their functionality in full. The Purchaser acknowledges and understands that the ',
                      'Company is a reseller of Tokens issued by the Issuer and that no warranties or ',
                      'representations, express or implied, with regard to the Issuer or the Project or the ',
                      'Token or its functionalities or utilities are given under this SAFT. To the fullest extent ',
                      'as permitted by law, any liability of the Company with respect to the sale of the ',
                      'Token under this SAFT shall be excluded.'
                    ]
                  },
                ],
                [
                  {
                    text: [
                      { text: 'Utility Token ', style: ['listHeading2'], headlineLevel: 3 },
                      'The Purchaser acknowledges that the purchase, ownership, receipt or ',
                      'possession of Tokens carries no rights, express or implied, other than the right to use ',
                      'the Tokens as per Project Documentation, in each case, to the extent that the Issuer’s ',
                      'ecosystem, software, platform or application remains in use after its deployment by ',
                      'the Issuer, and within limitation of the applicable Terms of Use of the Issuer’s ',
                      'ecosystem, software, platform or application. The Token does not represent or confer ',
                      'any ownership right or stake, share or security or equivalent rights, or any right to ',
                      'receive future revenue shares, intellectual property rights or any other form of ',
                      'participation in or relating to the Issuer and its corporate affiliates, including the ',
                      'governance of the Issuer and its corporate affiliates. Tokens are not intended to be a ',
                      'digital currency (except as far as being a means of exchange of value between users ',
                      'within the Issuer’s software, platform or application; or as a means of payment for ',
                      'access to various products and services made available to users on the Issuer’s ',
                      'platform or applications - closed loop currency), security, commodity or any other ',
                      'kind of financial instrument.'
                    ]
                  },
                ],
                [
                  {
                    text: [
                      { text: 'Regulatory ', style: ['listHeading2'], headlineLevel: 3 },
                      'The Purchaser has been advised that the Issuer considers this SAFT to not ',
                      'be a “security” in the jurisdiction of the Issuer and is not required to register this ',
                      'SAFT or the underlying Tokens with any securities regulator in the jurisdiction of the ',
                      'Issuer. The Purchaser is informed that the Company nor the Issuer do not provide ',
                      'financial, legal or tax advice and that the Purchaser has consulted with such ',
                      'professionals as needed and conducted own due diligence. The Purchaser confirms ',
                      'that signing the SAFT is not unlawful according to the Purchaser’s jurisdiction and ',
                      'undertakes to comply with all of relevant regulatory demands. The Purchaser ',
                      'confirms to have a sufficient understanding of the functionality, usage, storage, ',
                      'transmission mechanisms and intricacies associated with cryptographic tokens, token ',
                      'storage facilities, blockchain technology and blockchain-based software systems and ',
                      'are fully aware of the risks involved in the DLT and the cryptocurrency markets. The ',
                      'Purchaser further confirms the understanding that such risks may incur a complete ',
                      'loss of the Purchase Amount and that this would not impair the Purchaser’s financial ',
                      'condition. The Purchaser understands to not have any right against the Company or ',
                      'the Issuer, their founders and team members or any other party to request the refund ',
                      'of the whole Purchase Amount except if required under this SAFT, applicable law or ',
                      'regulation.'
                    ]
                  }
                ],
                [
                  {
                    text: [
                      { text: 'Exclusion of non accredited investors ', style: ['listHeading2'], headlineLevel: 3 },
                      'The Purchaser warrants not to be a citizen of or ',
                      'resident or domiciled or green card holder in any territory under the jurisdiction of ',
                      'the United States of America, including any state of the United States of America or ',
                      'the District of Columbia (the “USA”).'
                    ]
                  }
                ]
              ],
              style: ['list2']
            }
          ],
          [
            {
              text: 'Miscellaneous.',
              style: ['listHeading1'],
              headlineLevel: 2
            },
            {
              ol: [
                [
                  {
                    text: [
                      { text: ' Entirety and notices.', style: ['listHeading2'], headlineLevel: 3 },
                      ' The SAFT and its Schedules represent the entire agreement ',
                      'between the parties with respect to the subject matter hereof and supersedes any prior ',
                      'discussions or stipulations.'
                    ]
                  }
                ],
                [
                  {
                    text: [
                      { text: 'Confidential Information', style: ['listHeading2'], headlineLevel: 3 },
                      ' This SAFT is confidential, and it and any information ',
                      'provided in connection with it or as a part of it constitutes confidential information ',
                      'shall not be disclosed to any third party, without the prior written consent of the other ',
                      'Parties, except as required for tax purposes, or as required by applicable laws. None ',
                      'of the Parties may issue any press release or public announcement in relation to this ',
                      'agreement except as may be agreed to in writing by the Parties.'
                    ]
                  }
                ],
                [
                  {
                    text: [
                      { text: 'Severability', style: ['listHeading2'], headlineLevel: 3 },
                      ' In the event any one or more of the provisions of this SAFT is for any ',
                      'reason held to be invalid, illegal or unenforceable, in whole or in part or in any ',
                      'respect, then and in any such event, such provision(s) only will be deemed null and ',
                      'void and will not affect any other provision of this SAFT, and the remaining ',
                      'provisions of this SAFT will remain operative and in full force and effect and will be ',
                      'interpreted according to the true will of the parties.'
                    ]
                  }
                ],
                [
                  {
                    text: [
                      { text: 'Dispute Resolution', style: ['listHeading2'], headlineLevel: 3 },
                      ' All disputes or claims arising out of or in connection with this ',
                      'SAFT shall be tried to be resolved in a peaceful manner. If this would not be possible ',
                      'in 90 days after notification of dispute, each Party irrevocably agrees that the dispute ',
                      'shall be finally settled by mandatory final and binding individual (not class) ',
                      'arbitration in accordance with the Arbitration Chamber of Paris at the International ',
                      'Chamber of Commerce and Industry of France with the application of the Rules for ',
                      'Expedited Arbitral Proceedings, with arbitral tribunal composed of three arbitrators, ',
                      'arbitral proceedings in English, seat of the arbitration in Paris, France, EU. The ',
                      'governing law of the contract shall be the substantive law of the Country of France, ',
                      'EU.'
                    ]
                  }
                ]
              ],
              style: ['list2']
            }
          ],
          [
            {
              text: [
                {
                  text: 'No Warranty. Risks.',
                  style: ['listHeading1'],
                  headlineLevel: 2
                },
                ` To the fullest extent permitted by law the ${rewardTokenName} Tokens are sold on an `,
                '“as is” and “as available” basis without warranties of any kind. You accept that due to the ',
                'high degree of speculation and possibility of changes of applicable laws and the high ',
                'volatility of the crypto-markets, your purchase involves a high degree of risk and may result ',
                `in the loss of the entire Purchase Price. The purchase of ${rewardTokenName} Tokens should not be seen `,
                'as an investment into securities or an exchange for any form of shares or holdings in the ',
                'Company. None of the information presented by the Company is intended to form the basis ',
                'for an investment decision.'
              ]
            }
          ],
          [
            {
              text: [
                {
                  text: 'Eligibility.',
                  style: ['listHeading1'],
                  headlineLevel: 2
                },
                ' Natural persons who are not of legal age to form a binding contract and natural ',
                'and legal persons being based, domiciled, located or incorporated in or is a citizen or resident ',
                'of a Restricted Area shall not participate in any way, including for example through another ',
                `natural or legal person, in the sale of the ${rewardTokenName} Tokens. Notwithstanding any other `,
                'provision of these Terms, the Company reserves the right to exclude any person from the ',
                `${rewardTokenName} Token sale due to KYC/AML concerns or due to any other grounded reasons`
              ]
            }
          ],
          [
            {
              text: [
                {
                  text: 'Indemnification.',
                  style: ['listHeading1'],
                  headlineLevel: 2
                },
                ' Purchaser will indemnify the Company, its affiliates and respective officers, ',
                'employees or agents from and against any claims, liabilities and expenses arising out of or ',
                'relating to any third-party claim concerning these Terms or your participation in the sale of ',
                `${rewardTokenName} Tokens contrary to these Terms.`
              ]
            }
          ],
          [
            {
              text: [
                {
                  text: 'Liability.',
                  style: ['listHeading1'],
                  headlineLevel: 2
                },
                ` ${companyOfficialName}, its affiliates and their officers, employees or agents shall not in any `,
                'way be liable for any losses or damages of any kind whatsoever, incurred as a result of the ',
                `purchase of ${rewardTokenName} Tokens by the Purchaser or arising from reliance on any information `,
                'contained in this document or any information provided by the Company in relation to ',
                `${rewardTokenName} Tokens.`
              ]
            }
          ],
          [
            {
              text: [
                {
                  text: 'Intellectual Property.',
                  style: ['listHeading1'],
                  headlineLevel: 2
                },
                ' You may not use any of the intellectual property of the Company for ',
                'any reason, except with its express, prior, written consent.'
              ]
            }
          ],
          [
            {
              text: [
                {
                  text: 'Force Majeure.',
                  style: ['listHeading1'],
                  headlineLevel: 2
                },
                '  Neither Party shall be in breach of these Terms nor liable for delay in ',
                'performing, or failure to perform, any of its obligations under these Terms, if such delay or ',
                'failure results from events, circumstances or causes beyond its reasonable control (included, ',
                'but not limited to: hacker attacks, change of laws or regulations, adverse regulatory or ',
                'enforcement action of public authorities, technical failures and the like). In such ',
                'circumstances, time for performance shall be extended by a period equivalent to the period ',
                'during which performance of the obligation has been delayed or failed to be performed ',
                'because of Force Majeure events.'
              ]
            }
          ]
        ],
        style: ['list1']
      },
      {
        text: `With any questions regarding these Terms, contact us at ${contactEmail}`,
        style: ['largeMarginParagraph']
      },
      {
        text: `LAST UPDATED: ${dateCreated ? format(dateCreated, 'dd/MMMM/yyyy') : '__ ______ 202_'}`,
        style: ['paragraph']
      },
      {
        text: `I agree to the terms and conditions of ${companyOfficialName} SAFT agreement.`,
        style: ['paragraph']
      },
    ]
  }
}

