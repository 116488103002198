import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ReactComponent as LowIcon } from '@assets/tiers/low.svg'
import { ReactComponent as MediumIcon } from '@assets/tiers/medium.svg'
import { ReactComponent as HighIcon } from '@assets/tiers/high.svg'
import { ReactComponent as ConvectionIcon } from '@assets/tiers/convection.svg'
import { ReactComponent as GrillIcon } from '@assets/tiers/grill.svg'
import { MWAVETier, MWAVETiers, tierBreakpoints } from '@contracts/hooks/useMWAVETier/constants';
import './MWAVELevel.scss';
import { usePlatformSettings } from '@hooks/usePlatformSettings';
import { numberToCurrency } from '@utils/balanceFormatter';

interface Props {
  currentTier: MWAVETier | null
}

const MWAVELevel = ({ currentTier }: Props) => {
  const { settings } = usePlatformSettings()
  const breakpoints = useMemo(() => {
    return tierBreakpoints.map(point => numberToCurrency(+point * (settings?.flameTiersFactor ?? 1)))
  }, [settings])

  const overlayWidth = useMemo(() => {
    switch (currentTier) {
      case MWAVETiers.LOW: return 15
      case MWAVETiers.MEDIUM: return 33
      case MWAVETiers.HIGH: return 50
      case MWAVETiers.CONVECTION: return 70
      case MWAVETiers.GRILL: return 100
      default: return 0
    }
  }, [currentTier])

  return (
    <Row className="heat-row gx-0">
      <Col className="text-left column">
        <div className="color-strip">
          <div
            className='color-strip__overlay'
            style={{
              width: `${100 - overlayWidth}%`
            }}
          />
        </div>
        <div className="progress">
          <div className="item">
            <div className="icon" />
            <div className="title">Start</div>
          </div>
          <div className="item">
            <div className="icon">
              <LowIcon />
            </div>
            <div className="description">
              <div className="title">
                Low
              </div>
              <div className="subtitle">
                {breakpoints[0]} MWAVE
              </div>
              <p>
                Surprise Lottery Round
              </p>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <MediumIcon />
            </div>
            <div className="description">
              <div className="title">
                Medium
              </div>
              <div className="subtitle">
                {breakpoints[1]} MWAVE
              </div>
              <p>First Come - First<br/>Served 10% Allocation</p>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <HighIcon />
            </div>
            <div className="description">
              <div className="title">
                High
              </div>
              <div className="subtitle">
                {breakpoints[2]} MWAVE
              </div>
              <p>First Come - First<br/>Served 25% Allocation</p>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <ConvectionIcon />
            </div>
            <div className="description">
              <div className="title">
                Convection
              </div>
              <div className="subtitle">
                {breakpoints[3]} MWAVE
              </div>
              <p>Guaranteed Access<br/>50% Allocation</p>
            </div>
          </div>
          <div className="item">
            <div className="icon">
              <GrillIcon />
            </div>
            <div className="description">
              <div className="title">
                Grill
              </div>
              <div className="subtitle">
                {breakpoints[4]} MWAVE
              </div>
              <p>Guaranteed Access<br/>100% Allocation</p>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

MWAVELevel.defaultProps = {
  currentTier: MWAVETiers.GRILL
}

export { MWAVELevel }
