import React, { useState } from 'react';
import { Accordion as BaseAccordion, Button, Card, useAccordionToggle } from 'react-bootstrap';
import { ReactComponent as Minus } from '@assets/minus.svg';
import './Accordion.css';

interface Item {
  title: string;
  body: React.ReactNode;
}

interface Props {
  items: Item[];
}

export const ArrowIcon = () => (
  <svg
    width="22"
    height="13"
    viewBox="0 0 22 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4366 0.806353L11 8.24302L3.5633 0.806353C2.8158 0.0588525 1.6083 0.0588525 0.860796 0.806353C0.113296 1.55385 0.113296 2.76135 0.860796 3.50885L9.6583 12.3064C10.4058 13.0539 11.6133 13.0539 12.3608 12.3064L21.1583 3.50885C21.9058 2.76135 21.9058 1.55385 21.1583 0.806353C20.4108 0.0780192 19.1841 0.0588525 18.4366 0.806353Z"
      fill="white"
    />
  </svg>
);

const CustomToggle = ({ children, eventKey }: any) => {
  const [isOpened, setOpened] = useState(false);
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    setOpened(!isOpened);
  });

  return (
    <Button
      variant="main"
      className={isOpened ? 'active' : ''}
      onClick={decoratedOnClick}
    >
      {children}
    </Button>
  );
};

export const Accordion = ({ items }: Props) => {
  const itemList = items.map((item, i) => (
    <BaseAccordion key={item.title} >
      <Card className="accordion">
        <Card.Header>
          <CustomToggle eventKey={`${i}`}>
            {item.title}
            <span>
              <span className='minus'>
                <Minus />
              </span>
              <span className='plus'>
                <Minus />
              </span>
            </span>
          </CustomToggle>
        </Card.Header>
        <BaseAccordion.Collapse eventKey={`${i}`}>
          <Card.Body>
            {item.body}
          </Card.Body>
        </BaseAccordion.Collapse>
      </Card>
    </BaseAccordion>
  ));

  return <>{itemList}</>;
};
