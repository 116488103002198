import React from 'react'
import classNames from 'classnames'
import { Container } from 'react-bootstrap'
import './PageHeading.scss'

interface PageHeadingProps {
  className?: string
  banner: string
}

const PageHeading: React.FC<PageHeadingProps> = ({
  className,
  banner,
  children
}) => {
  return (
    <div
      className={classNames('page-heading', className)}
      // @ts-ignore
      style={{ '--page-heading-banner': `url('${banner}')` }}
    >
      <Container fluid>
        <div className="page-heading__inner">
          {children}
        </div>
      </Container>
    </div>
  )
}

interface PageHeadingTitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

const PageHeadingTitle: React.FC<PageHeadingTitleProps> = ({
  variant: Component = 'h1',
  children,
  ...props
}) => {
  return (
    <Component {...props} className={classNames('page-heading__title title', props.className)}>
      {children}
    </Component>
  )
}

const PageHeadingSubtitle: React.FC<React.HTMLAttributes<HTMLParagraphElement>> = ({
  children,
  ...props
}) => {
  return (
    <p className={classNames('page-heading__subtitle', props.className)}>
      {children}
    </p>
  )
}

const PageHeadingBody: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => {
  return (
    <div className={classNames('page-heading__body', props.className)}>
      {children}
    </div>
  )
}

export default Object.assign(PageHeading, {
  Title: PageHeadingTitle,
  Subtitle: PageHeadingSubtitle,
  Body: PageHeadingBody,
})
