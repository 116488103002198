import React, { useRef, memo } from 'react';
import './HomepageBanner.scss'
import Doge from '@assets/doge.png'
import Microwave from '@assets/microwave.png'
import { Image } from 'react-bootstrap';
import { useParallax } from '@hooks/useParallax';

export const HomepageBanner = () => {
  const microwaveRef = useRef<HTMLImageElement | null>(null)
  const dogeRef = useRef<HTMLImageElement | null>(null)

  useParallax<HTMLImageElement>(
    microwaveRef,
    {
      scrollY: {
        translateY: [
          ['elInY', 'elCenterY', 'elOutY'],
          [0, 50, 100],
        ]
      }
    }
  );

  useParallax<HTMLImageElement>(
    dogeRef,
    {
      scrollY: {
        translateY: [
          ['elInY', 'elCenterY', 'elOutY'],
          [0, 50, 100],
        ]
      }
    }
  );

  return (
    <>
      {/*TODO: temporary hidden until MWAVE deployed*/}
      {/*<Image ref={dogeRef} src={Doge} className='top-banner__doge' />*/}
      <Image ref={microwaveRef} src={Microwave} className='top-banner__microwave' />
    </>
  )
}
