import React from 'react';
import { NormalizedProjectInfo } from '../types';
import { ProjectWidget } from '../ProjectWidget';
import { Col } from 'react-bootstrap';
import { projectGetters } from '@contracts/getters/projectGetters';
import { IUseTimezoneFormat } from '@hooks/useTimezoneFormat'

interface Props {
  projects: NormalizedProjectInfo[]
  formatDate: IUseTimezoneFormat['formatDate']
}

export const ProjectsList = ({
  projects,
  formatDate
}: Props) => {

  return (
    <>
      {projects.map(project =>
        <Col key={project.id} lg={{ span: 6 }}>
          <ProjectWidget
            id={project.id}
            name={project.name}
            chainId={project.chainId}
            projectType={project.project_type}
            subtitle={project.info.subtitle}
            mainColor={project.info.main_color}
            logo={project.assets.logo_image_url}
            description={project.info.description}
            network={project.network}
            status={project.presale.statusMessage}
            fundToken={project.presale.fund_token}
            rewardToken={project.presale.reward_token}
            whitelistingOpens={project.whitelisting.starts_at}
            whitelistingCloses={project.whitelisting.end_at}
            privateOpens={project.presale.private_starts_at}
            privateCloses={project.presale.private_end_at}
            publicOpens={project.presale.public_starts_at}
            publicCloses={project.presale.public_end_at}
            socialLinks={project.social_links}
            isExternalPresale={projectGetters.getIsExternalPresale(project)}
            formatDate={formatDate}
          />
        </Col>
      )}
    </>
  )
}
