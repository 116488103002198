import {
  EthGasPriceResponse,
  GasPriceOptions,
  GasPriceResponse,
  GPolyGasPriceResponse,
  MaticGasPriceResponse
} from './types';

export const mapGasPrice = (response: GasPriceResponse): GasPriceOptions => {
  if ('currentBaseFee' in response) {
    return {
      safeLow: response.safeLow * response.currentBaseFee,
      standard: response.standard * response.currentBaseFee,
      fast: response.fast * response.currentBaseFee,
      fastest: response.fastest * response.currentBaseFee
    }
  }

  if ((response as GPolyGasPriceResponse).result) {
    let result = (response as GPolyGasPriceResponse).result
    return {
      safeLow: +result.SafeGasPrice / 2,
      standard: +result.SafeGasPrice,
      fast: +result.ProposeGasPrice,
      fastest: +result.FastGasPrice,
    }
  }

  return response as MaticGasPriceResponse | EthGasPriceResponse
}
