import { Col, Container, Row } from 'react-bootstrap';
import { faqItems } from '../../Homepage/contents';
import { Accordion, RoundButton, SEOTags } from '@components';
import React from 'react';
import { supportEmail } from '@constants';
import { seoTags } from '@/seo-content';
import './FAQ.scss'
import PageHeading from '@components/layout/PageHeading/PageHeading'
import Banner from '@assets/banners/faq-banner.png'

export const FAQPage = () => {
  return (
    <div className="page marketing-page faq-page">
      <SEOTags {...seoTags.faqPage} />
      <PageHeading banner={Banner}>
        <PageHeading.Title>
          FAQ
        </PageHeading.Title>
        <PageHeading.Subtitle>
          Didn't find the answer to your question?
        </PageHeading.Subtitle>
        <PageHeading.Body>
          <div className={'text-center d-flex align-items-center justify-content-center'}>
            <div className={'text-white fw-medium'}>
              <RoundButton
                href={`mailto:${supportEmail}`}
                size="large"
                wide
              >
                Contact Support
              </RoundButton>
            </div>
          </div>
        </PageHeading.Body>
      </PageHeading>
      <section className='marketing-content'>
        <Container>
          <Row className='justify-content-center'>
            <Col xs={{ span: 12 }} lg={{ span: 9 }} xl={{ span: 8 }}>
              <Accordion items={faqItems} />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}
