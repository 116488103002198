import { ActionType, AppState, StateAction, StateNFTContract } from './types';

export function stateReducer(state: AppState, action: StateAction): AppState {

  if (process.env.NODE_ENV === 'development') {
    console.groupCollapsed(`State Action: ${ActionType[action.type]}`)
    console.log(`Action: ${ActionType[action.type]}`);
    console.log('Prev state: ', state);
    if ('payload' in action) {
      console.log('Payload: ', action.payload);
    }
    console.groupEnd()
  }

  switch(action.type) {
    case ActionType.SET_LOADING:
      return {
        ...state,
        loading: action.payload ?? true
      }
    case ActionType.SET_PLATFORM_SETTINGS:
      return {
        ...state,
        settings: action.payload
      }
    case ActionType.SET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
        loading: false
      }
    case ActionType.SET_WHITElLISTED_PROJECTS:
      return {
        ...state,
        whitelistedProjects: action.payload,
        loading: false
      }
    case ActionType.SET_PROJECT:
      return {
        ...state,
        currentProject: action.payload,
        loading: false
      }
    case ActionType.SET_KYC_DATA:
      return {
        ...state,
        KYC: action.payload,
        loading: false
      }
    case ActionType.SET_GAS_PRICE:
      return {
        ...state,
        gasPrices: action.payload
      }
    case ActionType.SET_FETCHING_NFT_COLLECTION: {
      return {
        ...state,
        NFT: {
          ...state.NFT,
          fetchingCollection: action.payload
        }
      }
    }
    case ActionType.SET_FETCHING_NFT_TOKEN: {
      return {
        ...state,
        NFT: {
          ...state.NFT,
          fetchingCurrentToken: action.payload
        }
      }
    }
    case ActionType.SET_FLARE_DATA:
      return {
        ...state,
        NFT: {
          ...state.NFT,
          flare: action.payload
        }
      }
    case ActionType.SET_HIRO_DATA:
      return {
        ...state,
        NFT: {
          ...state.NFT,
          hiro: action.payload
        }
      }
    case ActionType.SET_USER_NFTS:
      return {
        ...state,
        NFT: {
          ...state.NFT,
          userNFTTokens: action.payload,
        }
      }
    case ActionType.SET_CURRENT_NFT:
      return {
        ...state,
        NFT: {
          ...state.NFT,
          currentNFTToken: action.payload,
        }
      }
    case ActionType.SET_IGNITION_STATUS:
      return {
        ...state,
        NFT: {
          ...state.NFT,
          currentNFTToken: {
            ...(state.NFT.currentNFTToken as StateNFTContract),
            ignitionStatus: action.payload
          },
        }
      }
    default:
      return state
  }
}
