import { RoundButton } from '@components/common/RoundButton';
import { LinkProps } from 'react-router-dom';
import React from 'react';
import { ReactComponent as BackArrow } from '@assets/back_arrow.svg'
import './BackLink.scss'

interface Props {
  to?: LinkProps['to']
  onClick?: () => unknown,
  children: React.ReactNode
}

export const BackLink = ({
  to,
  children,
  onClick
}: Props) => {

  return (
    <RoundButton
      color="TRANSPARENT"
      className="back-link"
      to={to}
      onClick={onClick}
    >
      <BackArrow />
      <span className='back-link__label'>
          {children}
      </span>
    </RoundButton>
  )
}
