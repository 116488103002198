import './SAFTModal.scss'
import { NormalizedProjectInfo, SAFTInfo } from '../types';
import { Form, Image, Modal } from 'react-bootstrap';
import { ReactComponent as CloseIcon } from '../../../assets/close.svg';
import { SAFTContent } from './contents/markup';
import { RoundButton } from '@components/common/RoundButton';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { createSAFTDocContent, SAFTfonts } from './contents/pdf';
import { generatePdfAndOpen } from '@utils/pdfMake';

interface Props {
  project: NormalizedProjectInfo
  show: boolean
  setShow: (value: boolean) => void
  agreed: boolean,
  onChangeAgreement: (value: boolean) => void
}

export const SAFTModal = ({
  project,
  show,
  setShow,
  agreed,
  onChangeAgreement,
}: Props) => {
  const contentRef = useRef<HTMLDivElement | null>(null)
  const endOfDocRef = useRef<HTMLParagraphElement | null>(null)
  const [scrolledAll, setScrolledAll] = useState(false)
  const scrollObserver = useRef<IntersectionObserver | null>(null)

  const createObserver = useCallback(() => {
    scrollObserver.current = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setScrolledAll(true)
            observer.unobserve(entry.target)
          }
        })
      },
      {
        root: contentRef.current || null,
        threshold: 0.005,
      }
    )

    endOfDocRef.current && scrollObserver.current.observe(endOfDocRef.current)
  }, [contentRef, endOfDocRef])

  useLayoutEffect(() => {
    if (show && contentRef.current && endOfDocRef.current) {
      createObserver()
    }

    return () => {
      if (endOfDocRef.current && scrollObserver.current) {
        scrollObserver.current && scrollObserver.current.unobserve(endOfDocRef.current)
        scrollObserver.current = null
      }
    }
  },[scrollObserver.current, contentRef.current, endOfDocRef.current, show])

  const disabledContinuing = useMemo(() => {
    if (agreed) {
      return false
    }
    return !scrolledAll
  }, [scrolledAll, agreed])

  const onContinueAndClose = () => {
    onChangeAgreement(true)
    setShow(false)
  }

  const SAFTProps = useMemo(() => {
    const saft = project.saft as SAFTInfo
    return {
      projectName: project.name,
      registrationData: saft.company_registration_data,
      exchangeRate: saft.exchange_rate,
      companyOfficialName: saft.official_company_name,
      fundTokenName: project.presale.fund_token.name,
      rewardTokenName: project.presale.reward_token.name,
      contactEmail: saft.contact_email,
      dateCreated: new Date()
    }
  }, [project])

  const onDownloadPDF = async () => {
    const docDefinition = createSAFTDocContent(SAFTProps)

    await generatePdfAndOpen({
      docDefinition,
      fonts: SAFTfonts
    })
  }

  return (
    <Modal
      show={show}
      size="xl"
      centered
      onHide={() => setShow(false)}
      className="saft-modal"
      contentClassName="saft-modal__content tile"
      dialogClassName="saft-modal__dialog"
    >
      <button
        className='close-button'
        onClick={() => setShow(false)}
      >
        <CloseIcon />
      </button>
      <Modal.Header className="saft-modal__header">
        <Image
          className="saft-modal__project-logo"
          roundedCircle
          src={project.assets.logo_image_url}
          loading="lazy"
        />
        <h4 className='saft-modal__project-name'>{project.name}</h4>
      </Modal.Header>
      <Modal.Body className="saft-modal__body">
        <h2 className='title'>
          Read the  Terms and conditions <br/> of SAFT agreement
        </h2>
        <div ref={contentRef} className='saft-content custom-scroll'>
          <SAFTContent
            {...SAFTProps}
            endOfDocRef={endOfDocRef}
          />
          <div className="saft-content-shadow"/>
        </div>
      </Modal.Body>
      <Modal.Footer
        className="saft-modal__footer"
      >
        <div>
          <Form.Group controlId="agree_to_saft">
            <Form.Check
              label="I agree to the  Terms and conditions of SAFT agreement"
              checked={agreed}
              onChange={
                event => onChangeAgreement(event.target.checked)
              }
            />
          </Form.Group>
        </div>
        <div>
          <RoundButton
            className="saft-modal__continue"
            type="button"
            size="large"
            wide
            disabled={disabledContinuing}
            onClick={onContinueAndClose}
          >
            Continue
          </RoundButton>
        </div>
        <div>
          <RoundButton
            className="saft-modal__download"
            color="TRANSPARENT"
            onClick={onDownloadPDF}
          >
            Download SAFT
          </RoundButton>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
