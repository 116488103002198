import {
  NormalizedProjectInfo,
  ProjectStatusesProps,
  ProjectStatusProp
} from '@components/Project/types';
import { AppState } from './types';

const makeProjectsGetter = (...args: ProjectStatusProp[]): (state: AppState) => NormalizedProjectInfo[] => {

  return (state) => {
    const { projects } = state
    if (!projects?.length) {
      return []
    }
    return [...projects].filter(project => args.includes(project.presale.statusMessage))
  }
}

export const getLiveProjects = makeProjectsGetter(
  ProjectStatusesProps['Registration Open'],
  ProjectStatusesProps['Registration Closed'],
  ProjectStatusesProps['Guaranteed Live'],
  ProjectStatusesProps['FCFS Coming'],
  ProjectStatusesProps['FCFS Live'],
)
export const getUpcomingProjects = makeProjectsGetter(
  ProjectStatusesProps['Coming Soon'],
)
export const getCompletedProjects = makeProjectsGetter(ProjectStatusesProps.Closed)

export const getFeaturedProjects = (state: AppState): NormalizedProjectInfo[] => {
  const featuredProjects = [...getLiveProjects(state), ...getUpcomingProjects(state)]

  if (!featuredProjects.length) {
    return []
  }

  return featuredProjects
    .sort(
      (a, b) =>
        (+(b.whitelisting.starts_at ?? 0) - +(a.whitelisting.starts_at ?? 0))
    ).slice(0, 5)
}
