import React from 'react';
import { PortfolioItem } from '../PortfolioItem';
import './PortfolioList.scss'
import { NormalizedProjectInfo } from '@components/Project/types';
import { LoadingWrap } from '@components/common/LoadingWrap';
import { NoProjectsTile } from '@/components'

interface Props {
  projects?: NormalizedProjectInfo[]
}

export const PortfolioList = ({ projects }: Props) => {

  return (
    <div className="portfolio-list">
      <ul className='portfolio-list__header'>
        <li>Project</li>
        <li>Tokens</li>
        <li>Unvested</li>
        <li>Claimed</li>
        <li>Claim</li>
        <li>Action</li>
      </ul>
      <div className='portfolio-list__body'>
        <LoadingWrap loading={!projects}>
          {
            !!projects && projects.length
              ? projects.map(project => (
                <PortfolioItem key={project.id} project={project} />
              )
              ) : (
                <NoProjectsTile>
                  The projects you are participating in will appear here
                </NoProjectsTile>
              )
          }
        </LoadingWrap>
      </div>
      <NoProjectsTile className="hidden">
        The projects you are participating in will appear here
      </NoProjectsTile>
    </div>
  )
}
