import { Image } from 'react-bootstrap';
import './ProjectBio.css'

interface Props {
  title: string
  image?: string
  text: string
}

export const ProjectBio = ({title, image, text}: Props) => {

  return (
    <div className="project-bio tile">
      <div className='project-bio__inner'>
        {
          image && (
            <div className='project-bio__image'>
              <Image src={image} roundedCircle />
            </div>
          )
        }
        <div className='project-bio__content'>
          <h4 className='project-bio__title'>{title}</h4>
          <div className='project-bio__text' dangerouslySetInnerHTML={{ __html: text }}/>
        </div>
      </div>
    </div>
  )
}
