export enum MWAVETiers {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  CONVECTION = 'CONVECTION',
  GRILL = 'GRILL',
}

export type MWAVETier = keyof typeof MWAVETiers

export const tierLevels: Record<number, MWAVETier> = {
  100: MWAVETiers.LOW,
  500: MWAVETiers.MEDIUM,
  5000: MWAVETiers.HIGH,
  10000: MWAVETiers.CONVECTION,
  25000: MWAVETiers.GRILL,
}

export const tierBreakpoints = Object.keys(tierLevels)

export enum TierAccessTypes {
  SURPRISE_LOTTERY = 'surprise_lottery',
  FCFS_LOTTERY = 'fcfs_lottery',
  GUARANTEED = 'guaranteed'
}

export const tierAccesses = {
  [MWAVETiers.LOW]: TierAccessTypes.SURPRISE_LOTTERY,
  [MWAVETiers.MEDIUM]: TierAccessTypes.FCFS_LOTTERY,
  [MWAVETiers.HIGH]: TierAccessTypes.FCFS_LOTTERY,
  [MWAVETiers.CONVECTION]: TierAccessTypes.FCFS_LOTTERY,
  [MWAVETiers.GRILL]: TierAccessTypes.FCFS_LOTTERY,
}

export const allocationMultipliers = {
  [MWAVETiers.LOW]: null,
  [MWAVETiers.MEDIUM]: 1,
  [MWAVETiers.HIGH]: 2,
  [MWAVETiers.CONVECTION]: 4,
  [MWAVETiers.GRILL]: 8,
}

export const MWAVEPowerMultipliers = {
  MWAVE_LOCKED: 1,
  LP_STAKED: 1000000,
  HIROS_AMOUNT: 4500
}

export const lotteryTicketPrice = 500

