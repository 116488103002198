import { FooterLink, FooterSocial, footerSocials } from '@components/layout/Footer/FooterContent'
import { CustomLink, DynamicImage } from '@components'
import React from 'react'
import { RoutesPaths } from '@router/constants'
import { Link } from 'react-router-dom'
import logo from '@assets/logo.svg'

interface FooterElement {
  title: string,
  links: Array<FooterLink>
}

export const socialLink = (item: FooterSocial): React.ReactNode =>
  <li key={item.href} className="footer__socials-item">
    <CustomLink href={item.href}>
      <DynamicImage path={`socials/${item.icon}.svg`} />
    </CustomLink>
  </li>

const getFooterListItem = (item: FooterLink): React.ReactNode =>
  <li key={item.title} className='footer__list-item'>
    <CustomLink to={item.to} href={item.href}>{item.title}</CustomLink>
  </li>

export const FooterList = (props: { item: FooterElement }) =>
  <>
    <h4 className='footer__list-title'>
      {props.item.title}
    </h4>
    <ul className='footer__list'>
      {props.item.links.map(getFooterListItem)}
    </ul>
  </>

export const SocialLinks = (props: { className: string }) =>
  <div className={`${props.className} footer__col--socials`}>
    <h4 className='footer__list-title'>
      Social
    </h4>
    <div className="footer__col-content">
      <ul className="footer__socials">
        {footerSocials.map(socialLink)}
      </ul>
    </div>
  </div>

export const Copyright = (props: { year: number, className: string }) =>
  <div className={`${props.className} text-center`}>
    <span>{`© ${props.year} Microwave`}</span>
  </div>

export const FooterLogo = () =>
  <div className="footer__logo">
    <Link to={RoutesPaths.MAIN}>
      <img
        src={logo}
        alt="Logo"
      />
    </Link>
  </div>
