import { NetworksIDs, NetworkId, defaultEnvironmentNetworkId } from './networks';

export type ContractAddress = string;
export type WalletAddress = string;

interface MWAVEContracts {
  MWAVEToken: ContractAddress;
  MWAVELocking?: ContractAddress;
  multiperiodLocking: ContractAddress;
  MWAVEStaking: ContractAddress;
  MWAVELpToken: ContractAddress;
  flareNFT?: ContractAddress;
  hiroNFT?: ContractAddress;
  ignitorContract?: ContractAddress;
}

const networksTokenAddresses: Record<NetworkId, MWAVEContracts> = {
  [NetworksIDs.doge]: {
    MWAVEToken: process.env.REACT_APP_DOGE_MWAVE_TOKEN || '',
    multiperiodLocking: process.env.REACT_APP_DOGE_MULTIPERIOD_LOCKING || '',
    MWAVEStaking: process.env.REACT_APP_DOGE_MWAVE_STAKING || '',
    MWAVELpToken: process.env.REACT_APP_DOGE_LP_TOKEN || ''
  }
}

export const tokenAddresses: MWAVEContracts =
  networksTokenAddresses[defaultEnvironmentNetworkId];

/**
 * Token addresses in lower case for which approval cannot be increased
 * while it is different from 0
 */
const singleApprovalTokens: Set<ContractAddress> = new Set([
  '0xdac17f958d2ee523a2206206994597c13d831ec7',
  // USDT token on Ethereum network (ERC-20) has such behavior
  // while on other networks it doesn't
])

export const getIsSingleApprovalToken = (tokenAddress: ContractAddress): boolean => {
  return singleApprovalTokens.has(tokenAddress.toLowerCase())
}
