import { instanceWithSignature as API } from '../config';
import { getWhitelistArgs, GetWhitelistDataReturns, WhitelistSuccessResponse } from './types';
import { AxiosError } from 'axios';
import { sendExceptionReport } from '@utils/errors';

export const getWhitelistData = async ({ account, projectId }: getWhitelistArgs): Promise<GetWhitelistDataReturns | null> => {
  try {
    const { data } = await API.get<WhitelistSuccessResponse>('/get-whitelist-data', {
      params: {
        wallet_address: account,
        project_id: projectId
      }
    })

    return {
      status: data.status,
      paramsData: data.params_data
    }
  } catch (err) {
    if ((err as AxiosError).response?.status !== 404) {
      sendExceptionReport(err)
    }
    return null
  }
}
