import { Form } from 'react-bootstrap'
import React from 'react'

interface Props {
  getFieldProps: any
  touched: any
  errors: any
}

export const LaunchFormStepOne = ({ getFieldProps, touched, errors }: Props) =>
  <>
    <Form.Group controlId="firstName">
      <Form.Label>First Name *</Form.Label>
      <Form.Control
        type="text"
        placeholder="First Name"
        {...getFieldProps('firstName')}
        isValid={touched.firstName && !errors.firstName}
        isInvalid={touched.firstName && !!errors.firstName}
      />
      <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
    </Form.Group>
    <Form.Group controlId="lastName">
      <Form.Label>Last Name *</Form.Label>
      <Form.Control
        type="text"
        placeholder="Last Name"
        {...getFieldProps('lastName')}
        isValid={touched.lastName && !errors.lastName}
        isInvalid={touched.lastName && !!errors.lastName}
      />
      <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
    </Form.Group>
    <Form.Group controlId="email">
      <Form.Label>Email *</Form.Label>
      <Form.Control
        type="email"
        placeholder="youremail@mail.com"
        {...getFieldProps('email')}
        isValid={touched.email && !errors.email}
        isInvalid={touched.email && !!errors.email}
      />
      <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
    </Form.Group>
    <Form.Group controlId="telegram">
      <Form.Label>Telegram</Form.Label>
      <Form.Control
        type="text"
        placeholder="@"
        {...getFieldProps('telegram')}
        isValid={touched.telegram && !errors.telegram}
        isInvalid={touched.telegram && !!errors.telegram}
      />
      <Form.Control.Feedback type="invalid">{errors.telegram}</Form.Control.Feedback>
    </Form.Group>
  </>
