import React from 'react';
import './Footer.scss';
import { Col, Container, Row } from 'react-bootstrap';
import { footerLinks } from './FooterContent'
import { Copyright, FooterList, FooterLogo, SocialLinks } from '@components/layout/Footer/FooterComponents'
import { ReactComponent as DogeHead } from '@assets/doge-head.svg';

export const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className='footer'>
      <Container>
        <Row className="justify-content-between">
          <Col className="footer-col" xs={{ span: 3 }}>
            <FooterLogo />
          </Col>
          <Col className="footer-col" xs={{ span: 2 }}>
            <div className='footer__col'>
              <FooterList item={{ title: "Company", links: footerLinks.company }} />
            </div>
          </Col>
          <Col className="footer-col" xs={{ span: 2 }}>
            <div className='footer__col'>
              <FooterList item={{ title: "Developers", links: footerLinks.developers }} />
              <FooterList item={{ title: "Governance", links: footerLinks.governance }} />
            </div>
          </Col>
          <Col className="footer-col" xs={{ span: 2 }}>
            <div className='footer__col'>
              <FooterList item={{ title: "Help", links: footerLinks.help }} />
            </div>
          </Col>
          <Col className="footer-col" xs={{ span: 3 }}>
            <SocialLinks className="footer__col" />
          </Col>
        </Row>
        <Row className='copyrights-row justify-content-center'>
          <Col>
            <Copyright year={year} className="footer-copyrights" />
          </Col>
        </Row>
        <DogeHead className="doge" />
      </Container>
    </footer>
  );
};
