import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz';

export const shortenUnitsInDuration = (durationString: string) => {
  return durationString
    .replace('years', 'y')
    .replace('months', 'M')
    .replace('weeks', 'w')
    .replace('days', 'd')
    .replace('hours', 'h')
    .replace('minutes', 'm')
    .replace('seconds', 's');
};
export const formatLocalDateWithZone = (date: Date | number): string => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return formatInTimeZone(date, timeZone, 'yyyy-MM-dd HH:mm zzz');
};
export const formatDateToUTC = (date: Date | number): string => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return formatInTimeZone(+date - getTimezoneOffset(timeZone, date), timeZone, `yyyy-MM-dd HH:mm 'UTC`);
};

export const secondsToDays = (seconds: number): number => {
  return seconds / 3600 / 24
}
