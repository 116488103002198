import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.scss';
import { ignoreErrors, sendExceptionReport, TAGS } from '@utils/errors';
import { detectMobile, minWebsiteWidth } from '@utils/detectMobile';
import { Mobile } from '@pages'

const sentryDSN = process.env.REACT_APP_SENTRY_DSN

const environment = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_ENV : process.env.NODE_ENV

Sentry.init({
  dsn: sentryDSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: environment,
  attachStacktrace: true,
  ignoreErrors: ignoreErrors
});

const mobile = detectMobile()

if (mobile && window.innerWidth > minWebsiteWidth) {
  sendExceptionReport(
    new Error(`Error in detecting device. UserAgent: ${navigator.userAgent}`),
    { tags: { reason: TAGS.REASON.DEVICE_DETECTION } }
  )
}

ReactDOM.render(
  <React.StrictMode>
    {mobile ? <Mobile /> : <App />}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
