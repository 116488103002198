export const MWaveProjectId = process.env.REACT_APP_MICROWAVE_PROJECT_ID;
export const buyMWAVELink = process.env.REACT_APP_BUY_MWAVE_LINK;
export const buyLpTokenLink = process.env.REACT_APP_BUY_LP_TOKEN_LINK;
export const MWAVE_DECIMALS = 18;
export const LP_TOKEN_DECIMALS = 18;

// export const SOLANA_NETWORK_DOMAIN = process.env.REACT_APP_SOLANA_NETWORK_DOMAIN
// if (!SOLANA_NETWORK_DOMAIN) {
//   throw new Error('SOLANA_NETWORK_DOMAIN variable is not defined')
// }
