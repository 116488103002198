import { WhitelistStatus } from '@contracts/hooks/useWhitelist';
import { instanceWithSignature as API } from '../config';
import { WhitelistRequestArgs, WhitelistSuccessResponse } from './types';
import { sendExceptionReport } from '@utils/errors';
import { toChecksumAddress } from '@utils/string';

export const sendDataForWhitelist = async ({
 project_id,
 wallet_address,
 form_data
}: WhitelistRequestArgs): Promise<WhitelistStatus | null> => {
  try {
    const { data } = await API.post(
      '/apply-to-whitelist',
      {
        wallet_address: toChecksumAddress(wallet_address),
        form_data,
      },
      {
        params: {
          project_id
        }
      }
    )

    return (data as WhitelistSuccessResponse).status
  } catch (err) {
    sendExceptionReport(err)
    return null
  }
}
