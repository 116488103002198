import { instanceWithSignature as API } from '../config';
import { KYCSuccessResponse } from './types';
import { WalletAddress } from '@contracts/address';
import { sendExceptionReport } from '@utils/errors';

export const updateWalletKYC = async (wallet_address: WalletAddress): Promise<Pick<KYCSuccessResponse, 'kyc_status'> | null> => {
  try {
    const { data } = await API.get('/update-kyc-status', {
      params: {
        wallet_address
      }
    })

    return (data as Pick<KYCSuccessResponse, 'kyc_status'>)
  } catch (err) {
    sendExceptionReport(err)
    return null
  }
}
