import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { RoundButton } from '../RoundButton';
import './WrongNetworkModal.css'
import { NoEthereumProviderError } from '@web3-react/injected-connector';
import { UnsupportedChainIdError } from '@web3-react/core';
import { defaultEnvironmentNetworkName } from '@contracts/networks';
import { AddNetworkButton } from '../AddNetworkButton';
import { CustomNetworkError } from '@hooks/useNetwork';

interface Props {
  error?: Error
}

export const WrongNetworkModal = ({ error }: Props) => {
  const [show, setShow] = useState<boolean>(false)

  useEffect(() => {
    setShow(
      error instanceof UnsupportedChainIdError ||
      error instanceof NoEthereumProviderError ||
      error instanceof CustomNetworkError
    )
  }, [error])

  return (
    <Modal
      show={show}
      centered
      onHide={() => setShow(false)}
      className="wrong-network-modal"
      contentClassName="tile text-center"
      backdrop="static"
    >
      <Modal.Title as="h4">
        {
          error instanceof UnsupportedChainIdError
            ? 'Please change your Network'
            : error instanceof NoEthereumProviderError
              ? 'No provider detected'
              : 'Error'
        }
      </Modal.Title>
      <Modal.Body className="p-0">
        {
          error instanceof UnsupportedChainIdError ? (
            <>
              <p>It looks like your Wallet is connected to the wrong network</p>
              <p>Please change to the {defaultEnvironmentNetworkName} Network on this page</p>
            </>
          ) : error instanceof NoEthereumProviderError ? (
            <p>
              Please make sure you have
              <a
                href='https://metamask.io/download.html'
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}MetaMask extension{' '}
              </a>
              installed for your browser and connected your wallet to the website.
            </p>
          ) : (
            <p>{error?.message}</p>
          )
        }
        <div className='wrong-network-modal__buttons'>
          {
            error instanceof UnsupportedChainIdError ? (
              <>
                <RoundButton
                  size="large"
                  wide
                  onClick={() => setShow(false)}
                >
                  Dismiss
                </RoundButton>
                <AddNetworkButton
                  size="large"
                />
              </>
            ) : (
              <RoundButton
                size="large"
                wide
                onClick={() => setShow(false)}
              >
                OK
              </RoundButton>
            )
          }
        </div>
      </Modal.Body>
    </Modal>
  )
}
