import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import {
  Wallet,
  Account,
  Homepage,
  Projects,
  IndividualProject,
  Whitelist,
  AboutUs,
  JoinCommunity,
  UserAgreement,
  Privacy,
  KYCPage,
  AddNetworkPage,
  FAQPage,
} from '@pages';
import { Header, Footer, WrongNetworkModal, ScrollToTop, ScrollRestoration, Splash } from '@components';
import { useEagerConnect, useInactiveListener } from '@hooks/useEagerConnect';
import { useWeb3React } from '@web3-react/core';
import { useGasPrice } from '@contracts/hooks/useGasPrice';
import { RoutesPaths } from './constants';
import { usePlatformSettings } from '@hooks/usePlatformSettings';
import { useKYC } from '@contracts/hooks/useKYC';
import { isDefined } from '@utils/object';
import { useIsMounted } from '@hooks/useIsMounted';

const Routes = React.memo(() => {
  const { connector, account, error, chainId } = useWeb3React();
  const [activatingConnector, setActivatingConnector] = React.useState<any>()
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector])
  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager || !!activatingConnector)

  const isMountedRef = useIsMounted()
  const { settings, getSettings } = usePlatformSettings()
  const { gasPrice, getGasPrice } = useGasPrice();
  const { KYCStatus, getKYCStatus } = useKYC()

  useEffect(() => {
    getSettings()
  }, [])

  useEffect(() => {
    getKYCStatus()
  }, [account])

  let gasPriceListener: ReturnType<typeof setInterval>

  useEffect(() => {
    getGasPrice()
    gasPriceListener = setInterval(
      getGasPrice,
      30000
    )

    return () => clearInterval(gasPriceListener)
  }, [chainId])

  const [showSplash, setShowSplash] = useState(true)

  useEffect(() => {
    if (settings && isDefined(KYCStatus) && gasPrice) {
      setTimeout(() => {
        isMountedRef.current && setShowSplash(false)
      }, 2000)
    }
  }, [
    settings,
    KYCStatus,
    gasPrice,
    isMountedRef,
  ])

  return (
    <BrowserRouter>
      <ScrollRestoration>
        <Header />
        <Switch>
          <Route exact path={RoutesPaths.MAIN} component={Homepage} />
          <Route path={RoutesPaths.ACCOUNT.MAIN} component={account ? Account : Wallet} />
          <Route exact path={RoutesPaths.PROJECTS} component={Projects}/>
          <Route exact path={RoutesPaths.PROJECT} component={IndividualProject}/>
          <Route exact path={RoutesPaths.WHITELIST} component={account ? Whitelist : Wallet}/>
          <Route exact path={RoutesPaths.ABOUT_US} component={AboutUs}/>
          <Route exact path={RoutesPaths.JOIN_NETWORK} component={JoinCommunity}/>
          <Route exact path={RoutesPaths.USER_AGREEMENT} component={UserAgreement}/>
          <Route exact path={RoutesPaths.PRIVACY} component={Privacy}/>
          <Route exact path={RoutesPaths.FAQ} component={FAQPage}/>
          <Route exact path={RoutesPaths.KYC_PROCESS} component={KYCPage}/>
          <Route exact path={RoutesPaths.ADD_NETWORK_GUIDE} component={AddNetworkPage}/>
          <Route path="/*" component={Homepage} />
        </Switch>
        <Footer />
        <ScrollToTop />
        <WrongNetworkModal error={error} />
        <Splash show={showSplash} />
      </ScrollRestoration>
    </BrowserRouter>
  );
});
export default Routes;
