import { Col, Container, Row } from "react-bootstrap";
import { Accordion, RoundButton } from "@/components";
import { faqItems } from "@pages/Homepage/contents";
import './FaqSection.scss'
import React, { memo } from "react";
import { RoutesPaths } from '@router/constants'

export const FaqSection: React.FC = memo(() =>
  <section className="faq-section">
    <Container>
      <Row className="faq-column justify-content-center">
        <Col
          className="text-center col-xxl-8"
          md={{ span: 12 }}
          xl={{ span: 11 }}
        >
          <h2 className="title">FAQ</h2>
          <Accordion items={faqItems.slice(0, 4)} />
          <RoundButton to={RoutesPaths.FAQ}>SEE ALL QUESTIONS</RoundButton>
        </Col>
      </Row>
    </Container>
  </section>
)
