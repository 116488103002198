import './KYCPage.scss'
import { KYCBadge, SEOTags } from '@components';
import { Col, Container, Row } from 'react-bootstrap';
import { useKYC } from '@contracts/hooks/useKYC';
import { useWeb3React } from '@web3-react/core';
import React, { useEffect } from 'react';
import { seoTags } from '@/seo-content';
import PageHeading from '@components/layout/PageHeading/PageHeading'
import Banner from '@assets/banners/kyc.png'

export const KYCPage = () => {
  const { account } = useWeb3React()
  const { getKYCStatus } = useKYC()

  useEffect(() => {
    getKYCStatus()
  }, [account])

  return (
    <>
      <SEOTags {...seoTags.kycPage} />
      <div className="kyc page">
        <PageHeading banner={Banner} className='kyc-heading'>
          <PageHeading.Title>
            KYC
          </PageHeading.Title>
        </PageHeading>
        <section className='marketing-content'>
          <Row className='justify-content-center'>
            <Col xl={{ span: 8 }} md={{ span: 10 }} xs={{ span: 12 }}>
              <section className='kyc-page__caption'>
                <Container>
                  <p>
                    In order to participate in the early funding rounds of Microwave Projects, you will need to
                    complete
                    our
                    KYC process.
                  </p>
                </Container>
              </section>
              <section className='kyc-page__requirements'>
                <Container>
                  <div className='description'>
                    <h3 className='title'>
                      Please make sure you have the following before you begin:
                    </h3>
                    <ul>
                      <li><span>Passport or National ID</span></li>
                      <li><span>Proof of address</span></li>
                    </ul>
                  </div>
                  <KYCBadge showWallet={true}/>
                </Container>
              </section>
            </Col>
          </Row>
        </section>
      </div>
    </>
  );
};
