import React, { useMemo } from 'react';
import { useKYC } from '@contracts/hooks/useKYC';
import { KYCStatuses } from '@api/kyc/types';
import { KYCButton } from '../KYCButton';
import { LoadingWrap, RoundButton } from '@/components';
import { supportEmail } from '@constants';
import './KYCBadge.scss'
import { Copiable } from '../Copiable';
import { useWeb3React } from '@web3-react/core';
import { shorterETHAddress } from '@utils/string';
import { ReactComponent as CopyIcon } from '../../../assets/copy.svg';
import { ReactComponent as VerifiedIcon } from '../../../assets/verified.svg';
import { Col, Container, Row } from 'react-bootstrap'


interface Props {
  fullWidth?: boolean
  showWallet?: boolean
}

export const KYCBadge = ({ fullWidth, showWallet }: Props) => {
  const { account } = useWeb3React()
  const {
    loading,
    KYCStatus,
    KYCRefId,
    updateKYCStatus,
  } = useKYC()

  const statusText: JSX.Element = useMemo(() => {
    if (loading) return <>Checking KYC status...</>

    switch (KYCStatus) {
      case KYCStatuses.approved:
        return <>Your wallet is <span className="success-text">Verified</span></>
      case KYCStatuses.incomplete:
      case KYCStatuses.inreview:
      case KYCStatuses.waiting:
        return <>Verifying your wallet is in progress</>
      case KYCStatuses.resubmit:
        return <>Your wallet verifying was <span className="red-text">rejected</span></>
      default:
        return <>Your wallet is <span className="red-text">Not Verified</span></>
    }
  }, [KYCStatus, loading]);

  const messageText = useMemo(() => {
    if (loading) return ''

    switch (KYCStatus) {
      case null:
      case KYCStatuses.not_sent:
        return 'You need to verify your wallet to be able to participate in IDOs'
      case KYCStatuses.incomplete:
      case KYCStatuses.inreview:
      case KYCStatuses.waiting:
        return 'Please come back later to check your status'
      case KYCStatuses.resubmit:
        return 'Contact support for assistance'
      case KYCStatuses.approved:
        return 'You are approved to participate in IDOs'
      default:
        return ''
    }
  }, [KYCStatus, loading])

  const KYCComponent = () =>
    <div className={`kyc-badge ${!fullWidth && 'tile'}`}>
      <div className='kyc-badge__inner'>
        <div>
          {
            account && showWallet && (
              <div className='kyc-badge__wallet'>
                <Copiable text={account}>
                  <span>{shorterETHAddress(account)}</span>
                  <CopyIcon/>
                </Copiable>
              </div>
            )
          }
          <h4 className='kyc-badge__status'>
            {statusText}
          </h4>
          <p className='kyc-badge__message'>
            {messageText}
          </p>
        </div>
        <LoadingWrap loading={loading as boolean}>
          <div className='kyc-badge__action'>
            {KYCStatus === KYCStatuses.approved &&
              <div className="kyc-approved-mark">
                <VerifiedIcon />
                <span className="text-big text-wide fw-semibold">
                  KYC Approved
                </span>
              </div>
            }
            {KYCStatus === KYCStatuses.not_sent && KYCRefId &&
              <KYCButton refId={KYCRefId} onFinish={updateKYCStatus} />
            }
            {KYCStatus !== KYCStatuses.not_sent && KYCStatus !== KYCStatuses.approved &&
              <RoundButton href={`mailto:${supportEmail}`} size="large" wide>Get help</RoundButton>
            }
          </div>
        </LoadingWrap>
      </div>
    </div>

  const FullWidthKYCComponent = () =>
    <div className='kyc-badge-line'>
      <Container>
        <Row className='kyc-badge-row'>
          <Col xs={{ span: 12 }}>
            <KYCComponent />
          </Col>
        </Row>
      </Container>
    </div>

  return fullWidth ? <FullWidthKYCComponent /> : <KYCComponent />
}
