import { useCallback, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import {
  useTokenContract,
} from './useContracts';
import { ContractAddress } from '../address';
import { useIsMounted } from '@hooks/useIsMounted';
import { useTransactions } from './useTransactions';
import { BlockNumber } from 'web3-core';

export const useTokenBalance = (
  tokenAddress?: ContractAddress,
  loading = false,
  blockNumber: BlockNumber = 'latest'
): BigNumber => {
  const isMountedRef = useIsMounted()
  const [balance, setBalance] = useState<BigNumber>(new BigNumber(0));
  const { account } = useWeb3React();
  const tokenContract = useTokenContract(tokenAddress);
  const { callTransaction } = useTransactions()

  const fetchBalance = useCallback(async () => {
    if (!tokenContract || !account) {
      setBalance(new BigNumber(0))
      return
    }

    const res = await callTransaction(
      tokenContract.methods.balanceOf(account),
      blockNumber
    )
    isMountedRef.current && setBalance(new BigNumber(res))
  }, [tokenContract, account, isMountedRef, blockNumber])

  useEffect(() => {
    if (!loading && account && tokenContract) {
      fetchBalance();
    }
  }, [loading, blockNumber, account, tokenContract]);

  return balance;
};

export const useDecimals = (tokenAddress?: ContractAddress) => {
  const isMountedRef = useIsMounted()
  const [decimals, setDecimals] = useState(18);
  const tokenContract = useTokenContract(tokenAddress)

  useEffect(() => {
    const getDecimals = async () => {
      if (!tokenContract) {
        setDecimals(18)
        return
      }

      const result = await tokenContract.methods.decimals().call()
      if (isMountedRef.current) {
        setDecimals(+result)
      }
    }

    getDecimals()
  }, [tokenContract, isMountedRef])

  return decimals
}
