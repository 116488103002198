import { Col, Container, Row } from "react-bootstrap";
import { RoundButton } from "@components";
import { RoutesPaths } from "@router/constants";
import React, { memo } from "react";
import './AdvantagesSection.scss'
import { buyMWAVELink } from '@constants'
import { ReactComponent as LinkArrow } from '@assets/link-arrow.svg'

export const AdvantagesSection: React.FC = memo(() =>
  <section className="advantages-section">
    <Container>
      <Row className="justify-content-between">
        <Col lg={{ span: 7 }}
             xl={{ span: 6 }}>
          <h2 className="advantages-section__title title">
            Connecting the best projects on Dogechain to the Microwave community. We offer early stage funding to Microwave holders.
          </h2>
          {/*TODO: temporary hidden until MWAVE deployed*/}
          {/*<AdvantagesButtons className={"advantages-section__buttons"} />*/}
        </Col>
        <Col lg={{ span: 5 }}
             xl={{ span: 5 }}>
          <div className="platform-advantages">
            <div className="advantages-item">
              <h3 className="advantages-item__title">Initial Dex Offerings</h3>
              <p className="advantages-item__text">
                An initial DEX offering or IDO refers to the launching of a cryptocurrency on a decentralized exchange
                (DEX). In an IDO, a blockchain project makes a coin’s first public debut on a DEX in order to raise
                funding from the Microwave community.
              </p>
            </div>
            <div className="advantages-item">
              <h3 className="advantages-item__title">Such Launch</h3>
              <p className="advantages-item__text">
                Here at Microwave, we ensure that the projects we launch are hot and ready! We go through a rigorous
                process of ensuring that we do our best to bring you the top projects.
              </p>
            </div>
          </div>
        </Col>
        {/*TODO: temporary hidden until MWAVE deployed*/}
        {/*<AdvantagesButtons className={"advantages-section__buttons-below"} />*/}
      </Row>
    </Container>
  </section>
);

interface Props {
  className: string
}

const AdvantagesButtons = ({ className }: Props) =>
  <div className={className}>
    <RoundButton
      className='bridge-token'
      to={buyMWAVELink}
      size="large"
      color='DARK'
    >
      Buy $MWAVE Tokens
      <LinkArrow />
    </RoundButton>
    <RoundButton
      to={RoutesPaths.ACCOUNT.LOCKING_MULTIPERIOD}
      color="LIGHT"
      size="large"
    >
      Lock $MWAVE Tokens
    </RoundButton>
  </div>

