import { Form } from 'react-bootstrap'
import React from 'react'
import classNames from 'classnames'
import { ReactComponent as InfoIcon } from '@assets/info-icon.svg'

interface Props {
  getFieldProps: any
  touched: any
  errors: any
  errorMessage: string
}

export const LaunchFormStepTwo = ({ getFieldProps, touched, errors, errorMessage }: Props) =>
  <>
    <Form.Group controlId="projectName">
      <Form.Label>Project Name *</Form.Label>
      <Form.Control
        type="text"
        placeholder="Project Name"
        {...getFieldProps('projectName')}
        isValid={touched.projectName && !errors.projectName}
        isInvalid={touched.projectName && !!errors.projectName}
      />
      <Form.Control.Feedback type="invalid">{errors.projectName}</Form.Control.Feedback>
    </Form.Group>
    <Form.Group controlId="projectWebsite">
      <Form.Label>Project's Website *</Form.Label>
      <Form.Control
        type="text"
        placeholder="www."
        {...getFieldProps('projectWebsite')}
        isValid={touched.projectWebsite && !errors.projectWebsite}
        isInvalid={touched.projectWebsite && !!errors.projectWebsite}
      />
      <Form.Control.Feedback type="invalid">{errors.projectWebsite}</Form.Control.Feedback>
    </Form.Group>
    <Form.Group controlId="projectTelegram">
      <Form.Label>Project's Telegram *</Form.Label>
      <Form.Control
        type="text"
        placeholder="@"
        {...getFieldProps('projectTelegram')}
        isValid={touched.projectTelegram && !errors.projectTelegram}
        isInvalid={touched.projectTelegram && !!errors.projectTelegram}
      />
      <Form.Control.Feedback type="invalid">{errors.projectTelegram}</Form.Control.Feedback>
    </Form.Group>
    <Form.Group controlId="projectTwitter">
      <Form.Label>Project's Twitter</Form.Label>
      <Form.Control
        type="text"
        placeholder="@"
        {...getFieldProps('projectTwitter')}
        isValid={touched.projectTwitter && !errors.projectTwitter}
        isInvalid={touched.projectTwitter && !!errors.projectTwitter}
      />
      <Form.Control.Feedback type="invalid">{errors.projectTwitter}</Form.Control.Feedback>
    </Form.Group>
    <div
      className={classNames(
        'form-message form-message--warning text-center mt-3',
        { hidden: !errorMessage }
      )}
    >
      <InfoIcon />
      <span>{errorMessage}</span>
    </div>
  </>
