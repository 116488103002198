import React, { useMemo } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import './RoundButton.scss';
import classNames from 'classnames';
import { Spinner } from '@/components'

interface ExternalLink {
  href?: string
  to?: undefined
}

interface InternalLink {
  to?: LinkProps['to']
  href?: undefined
}

export type RoundButtonProps = {
  variant?: 'DARK' | 'LIGHT' | 'TRANSPARENT'
  size?: 'base' | 'large' | 'small' | 'xs'
  wide?: boolean
  type?: 'submit' | 'reset' | 'button'
  badgeText?: string
  disabled?: boolean
  loading?: boolean
  rounded?: boolean
  children: React.ReactNode
} & (InternalLink | ExternalLink) & React.HTMLAttributes<HTMLElement>

const RoundButton = ({
  id,
  className,
  to,
  href,
  color,
  size,
  wide,
  type,
  badgeText,
  disabled,
  loading,
  rounded,
  onClick,
  children,
  style
}: RoundButtonProps) => {
  if (href && to) {
    throw new Error('Invalid props: component RoundButton cannot get "href" and "to" property at the same time')
  }

  const linkProp = useMemo(() => {
    return to
      ? { to }
      : href
        ? { href, target: '_blank', rel: 'noreferrer noopener' }
        : {}
  }, [to, href])

  const buttonType: 'submit' | 'reset' | 'button' | undefined = useMemo(() => {
    if (href || to) return
    return type ?? 'button'
  }, [type, to, href])

  return React.createElement<any>(
    to ? Link : href ? 'a' : 'button',
    {
      id,
      className: classNames(className, size, color?.toLowerCase(), {
        'round-button': true,
        'round-button--with-badge': !!badgeText,
        'wide': wide,
        'rounded-circle': rounded,
        'loading': loading
      }),
      disabled,
      onClick,
      type: buttonType,
      style,
      ...linkProp
    },
    <>
      {
        badgeText && (
          <span className='round-button__badge'>{badgeText}</span>
        )
      }
      {children}
      {loading && <Spinner />}
    </>
  )
};

RoundButton.defaultProps = {
  color: 'LIGHT',
  size: 'base'
} as RoundButtonProps

const IconButton: React.FC<RoundButtonProps> = ({ children, ...props }) =>
  <RoundButton
    {...props}
    className={classNames(props.className, 'icon-button')}
  >
    {children}
  </RoundButton>

export { RoundButton, IconButton }
