import { RoundButton } from '@components/common/RoundButton';
import { useMemo } from 'react';

interface Props {
  status: 'error' | 'success' | null
  onReset: () => unknown
}

export const FormFillResponseView = ({
 status,
 onReset
}: Props) => {

  const title = useMemo(() => {
    if (status === 'error') {
      return 'Oops..';
    }
    if (status === 'success') {
      return 'Thank you';
    }
    return '';
  }, [status]);

  const message = useMemo(() => {
    if (status === 'error') {
      return `Your response wasn't submitted. Please try again`;
    }
    if (status === 'success') {
      return 'Your response was submitted successfully';
    }
    return '';
  }, [status]);

  const buttonText = useMemo(() => {
    return status === 'error' ? 'Try again' : 'Submit another response';
  }, [status]);

  return (
    <div className='form-fill-response tile text-center align-items-center d-flex flex-column'>
      <h2 className='tile__main'>{title}</h2>
      <p className='tile__description'>{message}</p>
      <RoundButton
        size='large'
        wide
        color='DARK'
        onClick={onReset}
      >
        {buttonText}
      </RoundButton>
    </div>
  );
};
