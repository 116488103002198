import TooltipIcon from '../../../assets/tooltip-icon.svg';
import { Image } from 'react-bootstrap';
import { RoundButton } from '../RoundButton';
import { DynamicImage } from '../DynamicImage';
import { tiersArticleUrl } from '@constants';
import { lotteryTicketPrice } from '@contracts/hooks/useMWAVETier/constants';
import { usePlatformSettings } from '@hooks/usePlatformSettings';
import { numberToCurrency } from '@utils/balanceFormatter';

export const FPRequirementsTooltip = () => {

  return (
    <div className="tiers-tooltip__inner">
      <h5 className='tiers-tooltip__title'>
        <Image src={TooltipIcon} />
        <span>MWAVE Power</span>
      </h5>
      <div className='tiers-tooltip__content'>
        <p>
          MWAVE Power is an aggregator that counts
          all eligible MWAVE balances across several sources.
        </p>
        {/*TODO: uncomment when tiers article is published*/}
        {/*<RoundButton
          className="tiers-tooltip__button"
          href={tiersArticleUrl}
          color="DARK"
          size="large"
        >
          Read more
        </RoundButton>*/}
      </div>
    </div>
  )
}

export const CooldownPeriodTooltip = () => {

  return (
    <div className="tiers-tooltip__inner">
      <h5 className='tiers-tooltip__title'>
        <Image src={TooltipIcon} />
        <span>Cooldown period</span>
      </h5>
      <div className='tiers-tooltip__content'>
        <p>
          Cooldown period is a 7 days period after an ICO, during which wallets that have successfully invested in ICO,
          are restricted from applying to other IDOs whitelists.
        </p>
        <p>
          <b>Note</b>: It’s impossible to unstake and unlock your MWAVE tokens during the 7 days of the Cooldown period.
        </p>
        {/*TODO: uncomment when tiers article is published*/}
        {/*<RoundButton
          className="tiers-tooltip__button"
          href={tiersArticleUrl}
          color="DARK"
          size="large"
        >
          Read more
        </RoundButton>*/}
      </div>
    </div>
  )
}

export const SurpriseLotteryTooltip = () => {

  return (
    <div className="tiers-tooltip__inner">
      <h5 className='tiers-tooltip__title'>
        <Image src={TooltipIcon} />
        <span>Surprise Lottery </span>
      </h5>
      <div className='tiers-tooltip__content'>
        <p>
          We will randomly pick 10 lucky winners of the Light Tier and airdrop them an ICO launch prize.
        </p>
      </div>
    </div>
  )
}

export const FCFSLotteryTooltip = () => {
  const { settings } = usePlatformSettings()

  return (
    <div className="tiers-tooltip__inner">
      <h5 className='tiers-tooltip__title'>
        <Image src={TooltipIcon} />
        <span>FCFS Whitelist Lottery</span>
      </h5>
      <div className='tiers-tooltip__content'>
        <p>
          The more MWAVE Power you have, the higher your allocation and chance to win a spot in a whitelist.
        </p>
        <p>
          Participation in IDOs is on a FCFS basis.
        </p>
        <div className='tiers-tooltip__widget'>
          <div className='d-flex align-items-center tiers-tooltip__expression'>
            <DynamicImage path="tiers/grill.svg" />
            <b>=</b>
            <DynamicImage path="tiers/ticket-single.svg" />
          </div>
          <div className='main-yellow-text'>
            Every {numberToCurrency(lotteryTicketPrice * (settings?.flameTiersFactor ?? 1))} MWAVE power <br/>
            = 1 Lottery ticket
          </div>
        </div>
        {/*TODO: uncomment when tiers article is published*/}
        {/*<RoundButton
          className="tiers-tooltip__button"
          href={tiersArticleUrl}
          color="DARK"
          size="large"
        >
          Read more
        </RoundButton>*/}
      </div>
    </div>
  )
}

export const GuaranteedAllocationTooltip = () => {

  return (
    <div className="tiers-tooltip__inner">
      <h5 className='tiers-tooltip__title'>
        <Image src={TooltipIcon} />
        <span>Guaranteed Allocation</span>
      </h5>
      <div className='tiers-tooltip__content'>
        <p>
          Top 25 on MWAVE Leaderboard and Hiro holders are qualified for Guaranteed Allocation. The amount depends on
          their Tier.
        </p>
      </div>
    </div>
  )
}
