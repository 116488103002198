import React from 'react';
import { Spinner } from '../Spinner';

interface Props {
  loading: boolean
  fallback?: React.ReactNode
  children: React.ReactNode
}

const LoadingWrap = ({ loading, fallback, children}: Props) => {
  return (
    <>
      {loading
        ? fallback
        : children
      }
    </>
  )
}

LoadingWrap.defaultProps = {
  fallback: <Spinner />
}

export { LoadingWrap }
