import './LaunchModal.scss'
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { ReactComponent as CloseIcon } from '@assets/close.svg';
import { ReactComponent as LaunchWithUs } from '@assets/launch-with-us.svg';
import { RoundButton } from '@components/common/RoundButton';
import React, { useEffect, useMemo, useState } from 'react';
import { ApprovalSteps, BackLink } from '@components'
import { useFormik, yupToFormErrors } from 'formik'
import * as yup from 'yup'
import { LaunchFormStepOne } from '@components/formfills/LaunchModal/LaunchFormStepOne'
import { LaunchRadio } from '@components/formfills/LaunchModal/LaunchRadio'
import { LaunchFormStepTwo } from '@components/formfills/LaunchModal/LaunchFormStepTwo'
import { submitLaunchForm } from '@api/launch'

export type ProjectLaunchType = 'nft' | 'launchpad' | 'dex';

enum FormStep {
  personalInfo = 1,
  projectInfo = 2,
  result = 3
}

export interface LaunchFormValues {
  firstName: string
  lastName: string
  email: string
  telegram: string
  projectType: ProjectLaunchType
  projectName: string
  projectWebsite: string
  projectTelegram: string
  projectTwitter: string
}

const initialValues: LaunchFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  telegram: '',
  projectType: 'nft',
  projectName: '',
  projectWebsite: '',
  projectTelegram: '',
  projectTwitter: ''
}

interface Props {
  show: boolean
  setShow: (value: boolean) => void
  onSubmit?: (formData: LaunchFormValues) => Promise<unknown> | unknown
}

const validationSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email('Wrong format of email').required('Type your email to contact'),
  telegram: yup.string(),
  projectType: yup.string().oneOf(['nft', 'launchpad', 'dex']),
  projectName: yup.string().when('$step', (step, schema) => step === FormStep.projectInfo ? schema.required('Project name is required') : schema),
  projectWebsite: yup.string().when('$step', (step, schema) => step === FormStep.projectInfo ? schema.required('Project website is required') : schema),
  projectTelegram: yup.string().when('$step', (step, schema) => step === FormStep.projectInfo ? schema.required('Project Telegram is required') : schema),
  projectTwitter: yup.string(),
})

export const LaunchModal = ({ show, setShow }: Props) => {
  const [step, setStep] = useState<FormStep>(FormStep.personalInfo)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [loading, setLoading] = useState(false)

  const resetAndClose = () => {
    setShow(false);
    resetForm();
    setStep(FormStep.personalInfo);
  }

  const CloseButton = () =>
    <button
      className="close-button"
      onClick={resetAndClose}
    >
      <CloseIcon />
    </button>

  const formInProgress = useMemo(() => (step === FormStep.personalInfo || step === FormStep.projectInfo), [step])
  const formCompleted = useMemo(() => step === FormStep.result, [step])
  const formStepOne = useMemo(() => step === FormStep.personalInfo, [step])
  const formStepTwo = useMemo(() => step === FormStep.projectInfo, [step])

  const onSubmitHandler = async (values: LaunchFormValues) => {
    if (loading) return
    setLoading(true)
    let response = await submitLaunchForm(values)
    setLoading(false)

    if (response.status === 'OK') {
      resetForm();
      setStep(FormStep.result);
    }
    if (response.status === 'error') {
      setErrorMessage('Something went wrong, please try again later')
    }
  }

  const {
    errors,
    touched,
    handleSubmit,
    isValid,
    handleChange,
    handleBlur,
    getFieldProps,
    resetForm,
    validateForm
  } = useFormik({
    validateOnChange: true,
    validateOnMount: true,
    validationSchema,
    onSubmit: onSubmitHandler,
    initialValues,
    validate: (values) => validationSchema
      .validate(values, { abortEarly: false, context: { step } })
      .then(() => {})
      .catch(err => yupToFormErrors(err)),
  })

  useEffect(() => {
    validateForm()
  }, [step])

  return (
    <Modal
      show={show}
      centered
      size='lg'
      onHide={resetAndClose}
      className="launch-modal"
      contentClassName="launch-modal__content tile"
      dialogClassName="launch-modal__dialog"
    >
      <CloseButton />
      {formInProgress &&
        <Modal.Header className="launch-modal__header">
          {formStepTwo &&
            <BackLink onClick={() => setStep(FormStep.personalInfo)}>
              Back
            </BackLink>}
          <h4 className='launch-modal__project-name'>Launch with us</h4>
          <p>Launch your IDO through Microwave</p>
        </Modal.Header>}
      <Modal.Body className="launch-modal__body">
        <Form
          noValidate
          className="marketing-page-form tile"
          onSubmit={handleSubmit}
        >
          {formInProgress &&
            <Form.Row>
              {formStepOne ? 'Your Details' : 'Project Details'}
            </Form.Row>}
          <div className="marketing-page-form__inner">
            <Row>
              <Col>
                {formStepOne &&
                  <LaunchFormStepOne
                    getFieldProps={getFieldProps}
                    touched={touched}
                    errors={errors}
                  />}
                {formStepTwo &&
                  <>
                    <LaunchRadio
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      projectType={getFieldProps('projectType').value}
                    />
                    <LaunchFormStepTwo
                      getFieldProps={getFieldProps}
                      touched={touched}
                      errors={errors}
                      errorMessage={errorMessage}
                    />
                  </>
                }
                {formCompleted &&
                  <div className='thanks-for-inquiring'>
                    <LaunchWithUs />
                    <h4 className='launch-modal__project-name'>Thanks for inquiring!</h4>
                    <p>We will reach out shortly</p>
                  </div>
                }
              </Col>
            </Row>
            <Row className="text-center marketing-page-form__submit-row">
              <Col>
                {formStepOne &&
                  <RoundButton
                    type="button"
                    size="large"
                    wide
                    disabled={!isValid || loading}
                    onClick={() => setStep(FormStep.projectInfo)}
                  >
                    CONTINUE
                  </RoundButton>}
                {formStepTwo &&
                  <RoundButton
                    type="submit"
                    size="large"
                    wide
                    disabled={!isValid || loading}
                    loading={loading}
                  >
                    SUBMIT
                  </RoundButton>}
                {formCompleted &&
                  <RoundButton
                    type="button"
                    size="large"
                    wide
                    disabled={loading}
                    onClick={resetAndClose}
                  >
                    GOT IT
                  </RoundButton>}
              </Col>
            </Row>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer className="launch-modal__footer">
        {formInProgress && <ApprovalSteps fillingCondition={formStepTwo} />}
      </Modal.Footer>
    </Modal>
  )
}
